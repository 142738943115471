<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>
        <main class="main">
            <section class="section-dashboard" v-if="hasControlTowerAccess">
                <div class="container">
                    <h2 class="section-title">Control Tower</h2>

                    <div class="card-list">
                        <div class="card-item-wrapper">
                            <router-link to="dashboard" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/browser.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">DASHBOARD</span>
                            </router-link><!-- card-item -->
                        </div>

                        <div class="card-item-wrapper inactive">
                            <a href="#" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/package.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">RETURNS</span>
                            </a><!-- card-item -->
                        </div>

                        <div class="card-item-wrapper">
                            <router-link to="inventory" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/inventory.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">INVENTORY</span>
                            </router-link><!-- card-item -->
                        </div>

                        <div class="card-item-wrapper">
                            <router-link :to="{name: 'InventoryCompletedWorkOrderOverview'}" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/test.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">WORK ORDERS</span>
                            </router-link><!-- card-item -->
                        </div>

                        <div class="card-item-wrapper inactive">
                            <a href="#" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/sheet.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">SALES ORDERS</span>
                            </a><!-- card-item -->
                        </div>

                        <div class="card-item-wrapper inactive">
                            <a href="#" class="card-item sm">
                                <div class="card-item-img">
                                    <img :src="require('@/assets/images/colored-icons/lamp.svg')" class="img-fluid"
                                         alt="">
                                </div>

                                <span class="d-block pt-2">INSIGHTS</span>
                            </a><!-- card-item -->
                        </div>
                    </div><!-- cards-list -->
                </div>
            </section><!-- section-dashboard -->

            <section class="section-dashboard border-top">
                <div class="container">
                    <h2 class="section-title">Modules</h2>

                    <template v-if="isLoadingModules || isLoadingModuleAccess">
                        Loading modules...
                    </template>

                    <template v-else-if="!hasAccessToModules || (!hasInboundAccess && !hasStorageAccess && !hasOutboundAccess)">
                        No modules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <div class="card-item-wrapper" v-if="hasInboundAccess">
                                <router-link :to="{name: 'Inbound'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/inbound.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">INBOUND</span>
                                </router-link><!-- card-item -->
                            </div>

                            <div class="card-item-wrapper" v-if="hasStorageAccess">
                                <router-link :to="{name: 'Storage'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/inventory.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">STORAGE</span>
                                </router-link><!-- card-item -->
                            </div>

                            <div class="card-item-wrapper" v-if="hasOutboundAccess">
                                <router-link :to="{name: 'Outbound'}" class="card-item">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/inbound.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-1">OUTBOUND</span>
                                </router-link><!-- card-item -->
                            </div>
                        </div><!-- cards-list -->
                    </template>
                </div>
            </section><!-- section-dashboard -->
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar
        },
        data() {
            return {
                initLoaded: true,
                isLoadingModules: false,
                isLoadingModuleAccess: false,
                hasAccessToModules: false,
                hasControlTowerAccess: false,
                hasInboundAccess: false,
                hasStorageAccess: false,
                hasOutboundAccess: false,
                modules: {
                    settings: {
                        name: 'settings',
                        url: 'userOverview'
                    }
                },
            };
        },
        mounted() {
            this.$root.$on('loadedRoleModuleAccess', () => {
                this.isLoadingModuleAccess = false;
                this.moduleAccess = this.$store.state.roleAccess['role-module-access'][this.$store.state.users.currentUser.ROLE_ID];

                /* Control tower */
                this.hasControlTowerAccess = typeof this.moduleAccess['control-tower'] !== 'undefined' && this.moduleAccess['control-tower'].ACCESS;

                /* Inbound */
                let canReceiveReturn = typeof this.moduleAccess['receive-return'] !== 'undefined' && this.moduleAccess['receive-return'].ACCESS;
                let canReceiveToManifest = typeof this.moduleAccess['receive-to-manifest'] !== 'undefined' && this.moduleAccess['receive-to-manifest'].ACCESS;
                let canSortToManifest = typeof this.moduleAccess['sort-to-manifest'] !== 'undefined' && this.moduleAccess['sort-to-manifest'].ACCESS;
                let canViewIncomingUnsortedManifests = typeof this.moduleAccess['view-incoming-unsorted-manifests'] !== 'undefined' && this.moduleAccess['view-incoming-unsorted-manifests'].ACCESS;
                let canReceiveIncomingUnsortedManifests = typeof this.moduleAccess['receive-incoming-unsorted-manifests'] !== 'undefined' && this.moduleAccess['receive-incoming-unsorted-manifests'].ACCESS;
                this.hasInboundAccess = canReceiveReturn || canReceiveToManifest || canSortToManifest || canViewIncomingUnsortedManifests || canReceiveIncomingUnsortedManifests;

                /* Storage */
                let canViewManifests = typeof this.moduleAccess['view-manifests'] !== 'undefined' && this.moduleAccess['view-manifests'].ACCESS;
                let canViewContainers = typeof this.moduleAccess['view-containers'] !== 'undefined' && this.moduleAccess['view-containers'].ACCESS;
                this.hasStorageAccess = canViewManifests || canViewContainers;

                /* Outbound */
                let canShipUnsortedManifests = typeof this.moduleAccess['ship-unsorted-manifests'] !== 'undefined' && this.moduleAccess['ship-unsorted-manifests'].ACCESS;
                let canShipSortedManifests = typeof this.moduleAccess['ship-sorted-manifests'] !== 'undefined' && this.moduleAccess['ship-sorted-manifests'].ACCESS;
                let canShipShipments = typeof this.moduleAccess['ship-shipments'] !== 'undefined' && this.moduleAccess['ship-shipments'].ACCESS;
                let canShipContainers = typeof this.moduleAccess['ship-containers'] !== 'undefined' && this.moduleAccess['ship-containers'].ACCESS;
                this.hasOutboundAccess = canShipUnsortedManifests || canShipSortedManifests || canShipShipments || canShipContainers;
            });
        },
        created() {
            this.isLoadingModules = true;
            this.isLoadingModuleAccess = true;

            this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
                this.currentLocation = data;

                this.isLoadingModules = false;
                this.hasAccessToModules = this.currentLocation != null;
            });
        },
        methods: {}
    }
</script>

<style scoped>
    .card-item-wrapper.inactive {
        filter: grayscale(60%);
        opacity: 0.3;
    }
</style>
