<template>
    <div>
        <div class="loading-overlay--background"></div>

        <div class="loading-overlay--content">
            <div class="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div class="loading-overlay--message">
                <template v-if="loadingMessage">
                    {{ loadingMessage }}
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['loadingMessage'],
    }
</script>

<style scoped>
    .loading-overlay--background, .loading-overlay--content {
        position: fixed;
        border: none;
        margin: 0;
    }

    .loading-overlay--background {
        z-index: 1000;
        padding: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.6;
    }

    .loading-overlay--content {
        z-index: 1011;
        padding: 15px;
        width: 30%;
        top: 40%;
        left: 35%;
        text-align: center;
        color: #fff;
        cursor: default;
    }

    .loading-overlay--message {
        font-size: 20px;
    }
</style>
