import { render, staticRenderFns } from "./_WorkOrderShipmentBody.vue?vue&type=template&id=25e7355c&scoped=true&"
import script from "./_WorkOrderShipmentBody.vue?vue&type=script&lang=js&"
export * from "./_WorkOrderShipmentBody.vue?vue&type=script&lang=js&"
import style0 from "./_WorkOrderShipmentBody.vue?vue&type=style&index=0&id=25e7355c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e7355c",
  null
  
)

export default component.exports