<template>
    <div id="app" class="h-100" :style="cssVariables">
        <router-view/>
    </div>
</template>


<script>
    import ColorGenerator from './classes/ColorGenerator';

    export default {
        data() {
            return {
                colorPrimary: '#308FFF',
            }
        },
        created() {
            document.title = 'Return Dock';
        },
        computed: {
            /**
             * Creates an object with css variables. The result is then added to the div above.
             * This is used to implement custom portal colors.
             *
             * @returns object
             */
            cssVariables() {
                /*
                 * Add param in the future to add theme for the operations portal aswell?
                 */

                return {
                    '--color-primary': this.colorPrimary,
                    '--color-primary-lighter': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, 10),
                    '--color-primary-lightest': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, 20),
                    '--color-primary-darker': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, -10),
                    '--color-primary-darkest': ColorGenerator.instance.lightenDarkenColor(this.colorPrimary, -20),
                    '--color-primary-a50': ColorGenerator.instance.opacityColor(this.colorPrimary, 50),
                    '--color-primary-a35': ColorGenerator.instance.opacityColor(this.colorPrimary, 35),
                    '--color-primary-a25': ColorGenerator.instance.opacityColor(this.colorPrimary, 25),
                };
            }
        },
        methods: {}
    }
</script>
