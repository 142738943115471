<template>
    <div>
        <HeaderBar v-bind:title="$route.query.locationId ? 'Location update' : 'Location create'"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="Location" activeItem="Create"></SettingsMenu>

                <div class="col-10">
                    <template v-if="isLoading = (isLoadingLocation || isLoadingCountryList || isLoadingAddressFormConfig)">
                        Loading form...
                    </template>

                    <template v-else-if="isSaving">
                        Saving location...
                    </template>

                    <template v-else>
                        <div class="col-8">
                            <div class="row">
                                <div class="col-md-12 my-3">
                                    <label class="label" for="name">
                                        Name
                                    </label>

                                    <input type="text" class="form-control" v-model="location.NAME" id="name" :disabled="disabled">

                                    <InputFieldError attribute="NAME" :errors="errors"/>
                                </div>

                                <div class="col-md-12 my-3">
                                    <label class="label" for="description">
                                        Description
                                    </label>

                                    <textarea class="form-control" v-model="location.DESCRIPTION" id="description" rows="4" :disabled="disabled"></textarea>

                                    <InputFieldError attribute="DESCRIPTION" :errors="errors"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 my-3">
                                    <label class="label" for="addressee">
                                        Addressee
                                    </label>

                                    <input type="text" class="form-control" v-model="locationAddress.ADDRESSEE" id="addressee" :disabled="disabled">

                                    <InputFieldError attribute="ADDRESSEE" :errors="errors"/>
                                </div>

                                <div class="col-md-6 my-3">
                                    <label class="label" for="phone">
                                        Phone
                                    </label>

                                    <input type="text" class="form-control" v-model="locationAddress.PHONE" id="phone" :disabled="disabled">

                                    <InputFieldError attribute="PHONE" :errors="errors"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 my-3">
                                    <label class="label" for="address-line-1">
                                        Address line 1
                                    </label>

                                    <input type="text" class="form-control" v-model="locationAddress.ADDRESS_LINE_1" id="address-line-1" :disabled="disabled">

                                    <InputFieldError attribute="ADDRESS_LINE_1" :errors="errors"/>
                                </div>

                                <div class="col-md-6 my-3">
                                    <label class="label" for="address-line-2">
                                        Address line 2
                                    </label>

                                    <input type="text" class="form-control" v-model="locationAddress.ADDRESS_LINE_2" id="address-line-2" :disabled="disabled">

                                    <InputFieldError attribute="ADDRESS_LINE_2" :errors="errors"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 my-3" v-if="!locationAddress.COUNTRY || (addressFormConfig[locationAddress.COUNTRY] && addressFormConfig[locationAddress.COUNTRY].postalCode)">
                                    <label class="label" for="postal">
                                        <template v-if="addressFormConfig[locationAddress.COUNTRY] && addressFormConfig[locationAddress.COUNTRY].postalCode && addressFormConfig[locationAddress.COUNTRY].postalCode.label !== 'postal'">
                                            {{ addressFormConfig[locationAddress.COUNTRY].postalCode.label | capitalize }}
                                        </template>

                                        <template v-else>
                                            Postal code
                                        </template>
                                    </label>

                                    <input type="text" class="form-control" v-model="locationAddress.POSTAL" id="postal" :disabled="disabled">

                                    <InputFieldError attribute="POSTAL" :errors="errors"/>
                                </div>

                                <div class="col-md-6 my-3">
                                    <label class="label" for="city">
                                        City
                                    </label>

                                    <input type="text" class="form-control" v-model="locationAddress.CITY" id="city" :disabled="disabled">

                                    <InputFieldError attribute="CITY" :errors="errors"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="label">
                                            Country
                                        </label>

                                        <v-select v-model="locationAddress.COUNTRY" :filterable="true" :searchable="true"
                                                  :options="_.toPairs(countryList).map(a => { return {name: a[0], label: a[1]}; })"
                                                  :reduce="country => country.name" :clearable="false" :disabled="disabled"></v-select>

                                        <InputFieldError attribute="COUNTRY" :errors="errors"/>
                                    </div>
                                </div>

                                <div class="col-md-6" v-if="addressFormConfig[locationAddress.COUNTRY] && Object.keys(addressFormConfig[locationAddress.COUNTRY].administrativeArea.list).length > 0">
                                    <div class="form-group">
                                        <label class="label">
                                            {{ addressFormConfig[locationAddress.COUNTRY].administrativeArea.label | capitalize }}
                                        </label>

                                        <v-select v-model="locationAddress.ADMINISTRATIVE_AREA" :filterable="true" :searchable="true"
                                                  :options="_.toPairs(addressFormConfig[locationAddress.COUNTRY].administrativeArea.list).map(a => { return {name: a[0], label: a[1]}; })"
                                                  :reduce="administrativeArea => administrativeArea.name" :disabled="disabled"></v-select>

                                        <InputFieldError attribute="ADMINISTRATIVE_AREA" :errors="errors"/>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <label class="label">
                                        Tier
                                    </label>

                                    <div class="form-group">
                                        <label class="checkbox-container">
                                            Tier 1

                                            <input type="checkbox" checked="checked" v-model="locationTiers.tier1" :disabled="disabled">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div class="form-group">
                                        <label class="checkbox-container">
                                            Tier 2

                                            <input type="checkbox" checked="checked" v-model="locationTiers.tier2" :disabled="disabled">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <InputFieldError attribute="TIER" :errors="errors"/>
                                </div>
                            </div>
                        </div>
                    </template>

                    <button type="button" name="button" v-on:click="save()" class="btn md btn-primary mt-3 mb-5" v-if="!isLoading && !isSaving && !disabled">
                        Save
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';
    import vSelect from 'vue-select';
    import _ from 'lodash';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'SettingsMenu': SettingsMenu,
            'v-select': vSelect,
        },
        data() {
            return {
                isLoadingLocation: false,
                isLoadingCountryList: false,
                isLoadingAddressFormConfig: false,
                isSaving: false,
                errors: {},
                location: {
                    ID: null,
                    NAME: null,
                    DESCRIPTION: null,
                    TIER: null,
                },
                locationAddress: {
                    COUNTRY: null,
                    ADMINISTRATIVE_AREA: null,
                    CITY: null,
                    POSTAL: null,
                    ADDRESS_LINE_1: null,
                    ADDRESS_LINE_2: null,
                    PHONE: null,
                    ADDRESSEE: null,
                },
                countryList: null,
                addressFormConfig: {},
                disabled: this.$route.query.locationId != null,
                locationTiers: {
                    tier1: false,
                    tier2: false
                }
            };
        },
        created() {
            this.getCountryList();
            this.getLocation();
            this.getAddressFormConfig();
        },
        methods: {
            getLocation() {
                this.isLoadingLocation = true;

                this.$store.dispatch('locations/getAll').then(() => {
                    this.isLoadingLocation = false;

                    if (this.$route.query.locationId) {
                        let location = this.$store.state.locations.locations[this.$route.query.locationId];

                        this.location = {
                            ID: location.ID,
                            NAME: location.NAME,
                            DESCRIPTION: location.DESCRIPTION,
                            TIER: location.TIER
                        };

                        /* Pre-populate tier checkboxes */
                        if (location.TIER) {
                            _.forOwn(location.TIER, (tier) => {
                                if (tier == 1) {
                                    this.locationTiers.tier1 = true;
                                }

                                if (tier == 2) {
                                    this.locationTiers.tier2 = true;
                                }
                            });
                        }

                        this.locationAddress = {
                            COUNTRY: location.locationAddress.COUNTRY,
                            ADMINISTRATIVE_AREA: location.locationAddress.ADMINISTRATIVE_AREA,
                            CITY: location.locationAddress.CITY,
                            POSTAL: location.locationAddress.POSTAL,
                            ADDRESS_LINE_1: location.locationAddress.ADDRESS_LINE_1,
                            ADDRESS_LINE_2: location.locationAddress.ADDRESS_LINE_2,
                            PHONE: location.locationAddress.PHONE,
                            ADDRESSEE: location.locationAddress.ADDRESSEE
                        };
                    }
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
            getAddressFormConfig() {
                this.isLoadingAddressFormConfig = true;

                let url = new URL(this.$store.state.baseUrl + 'locations/get-address-form-config');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingAddressFormConfig = false;
                    this.addressFormConfig = data.data;
                });
            },
            save() {
                this.errors = {};

                let params = new URLSearchParams();
                let method;
                let url;

                _.forOwn(this.location, function (value, key) {
                    if (value !== null) {
                        params.append('Location[' + key + ']', value);
                    }
                });

                let tiers = [];
                _.forOwn(this.locationTiers, function (value, key) {
                    if (value) {
                        if (key === 'tier1') {
                            tiers.push(1);
                        }

                        if (key === 'tier2') {
                            tiers.push(2);
                        }
                    }
                });

                params.append('Location[TIER]', tiers);

                _.forOwn(this.locationAddress, function (value, key) {
                    if (value !== null) {
                        params.append('LocationAddress[' + key + ']', value);
                    }
                });

                this.isSaving = true;

                if (this.location.ID) {
                    url = new URL(this.$store.state.baseUrl + 'locations/update-location');
                    params.append('Location[ID]', this.location.ID);
                    method = this.$http.post(url.href, params);
                } else {
                    url = new URL(this.$store.state.baseUrl + 'locations/create-location');
                    method = this.$http.post(url.href, params);
                }

                method.then((data) => {
                    this.isSaving = false;
                    this.$store.commit('locations/clear');

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else {
                        this.$router.push({
                            name: 'LocationOverview'
                        });
                    }
                });
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        },
    }
</script>
