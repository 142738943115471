<template>
    <div>
        <div class="d-flex justify-content-between mt-4">
            <div class="d-block w-100">
                <label class="label">
                    Container
                </label>

                <template v-if="isLoadingContainers">
                    <p>
                        Loading containers...
                    </p>
                </template>

                <template v-else>
                    <v-select :options="filteredContainers"
                              label="LICENSE_PLATE"
                              v-model="selectedContainer"
                              :clearable="false"
                              :disabled="disabled"
                              required
                    />
                </template>
            </div>

            <div class="d-block mx-3 text-center" v-if="!isLoadingContainers">
                <label class="label">
                    Full
                </label>

                <label class="checkbox-container container-full m-0 p-0 d-flex justify-content-center align-items-center">
                    <input type="checkbox" v-model="containerIsFull" :disabled="disabled">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="d-block text-center" v-if="!isLoadingContainers">
                <label class="label">
                    New
                </label>

                <button type="button" class="btn btn-primary btn-create-container" @click.prevent="showCreateContainerModal = true" :disabled="disabled">
                    +
                </button>
            </div>
        </div>

        <InputFieldError attribute="CONTAINER_ID" :errors="errors"/>
        <InputFieldError attribute="global" :errors="componentErrors"/>

        <CreateContainerPopup v-if="showCreateContainerModal"
                              @close="showCreateContainerModal = false"
                              @createdContainer="createdContainerEvent"
        />
    </div>
</template>

<script>
    import InputFieldError from '@/components/_InputFieldError.vue';
    import CreateContainerPopup from '@/components/_CreateContainerPopup.vue';
    import vSelect from 'vue-select';

    export default {
        components: {
            'InputFieldError': InputFieldError,
            'CreateContainerPopup': CreateContainerPopup,
            'v-select': vSelect,
        },
        props: [
            'value', /* v-model support */
            'errors',
            'shouldFilterContainers',
            'disabled',
            'dispositionType',
            'merchantAccountId',
            'storageType',
        ],
        data() {
            return {
                componentErrors: {},
                isLoadingContainers: false,
                isLoadingContainerTypes: false,
                isCreatingContainer: false,
                showCreateContainerModal: false,
                newContainer: {
                    licensePlate: null,
                    type: null
                },
                containerTypes: null,
                containers: null,
                filteredContainers: null,
                containerIsFull: false,
                selectedContainer: null,
                savedContainerId: null,
            }
        },
        created() {
            this.getContainers();
            this.getContainerTypes();
        },
        methods: {
            getContainerTypes() {
                this.isLoadingContainerTypes = true;

                this.$store.dispatch('containers/getContainerTypes').then((data) => {
                    this.isLoadingContainerTypes = false;
                    this.containerTypes = data;
                });
            },
            getContainers() {
                this.isLoadingContainers = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/get-all');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainers = false;
                    this.containers = data.data;
                    this.filterContainers();

                    this.$nextTick(() => {
                        if (this.value) {
                            this.selectedContainer = this.value;
                        }
                    });
                });
            },
            filterContainers() {
                /* Retrieve containers which are not full and have the same disposition type and return bird account ID
                   as the asset, or do not have a disposition type or return bird account ID yet */
                this.filteredContainers = [];

                if (this.containers && this.containers.length > 0) {
                    this.containers.forEach((container) => {
                        if (container.IS_FULL == 0 &&
                            (container.DISPOSITION_TYPE == this.dispositionType || container.DISPOSITION_TYPE == null) &&
                            (container.RETURNBIRD_ACCOUNT_ID == this.merchantAccountId || container.RETURNBIRD_ACCOUNT_ID == null) &&
                            (container.STORAGE_TYPE == this.storageType || container.STORAGE_TYPE == null)) {
                            this.filteredContainers.push(container);
                        }
                    });
                }
            },
            createdContainerEvent(container) {
                this.containers.push(container);
                this.filterContainers();
                this.showCreateContainerModal = false;
                this.selectedContainer = container;
            }
        },
        watch: {
            disabled(value) {
                if (value) {
                    this.savedContainerId = this.selectedContainer.ID;
                }
            },
            selectedContainer(value) {
                if (this.disabled) {
                    return false;
                }

                value.IS_FULL = this.containerIsFull ? '1' : '0';
                this.$emit('input', value); /* v-model support */
            },
            containerIsFull(value) {
                if (this.selectedContainer) {
                    this.selectedContainer.IS_FULL = value ? '1' : '0';
                    this.$emit('input', this.selectedContainer); /* v-model support */
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .btn-create-container {
        padding: 10px 15px;
    }

    .checkbox-container.container-full {
        width: 40px;
        height: 40px;

        .checkmark {
            position: relative;
        }
    }
</style>
