<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <LoadingOverlay loadingMessage="Receiving return" v-if="isSubmitting"/>

        <main class="main">
            <template v-if="isLoadingReturnData">
                <div class="container mt-5">
                    Loading return data...
                </div>
            </template>

            <form v-else v-on:submit.prevent="receiveProduct()">
                <div class="scrollable-area">
                    <div class="scrollable-area--header">
                        <div class="my-5" v-if="!manifestReference"></div>

                        <div class="container" v-else>
                            <div class="col-12">
                                <div class="scrollable-area--subtitle">
                                    Receiving return from manifest
                                </div>

                                <div class="scrollable-area--title">
                                    {{ manifestReference }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="scrollable-content">
                        <div class="container mb-5">
                            <div class="form-group">
                                <label for="product-sku" class="label-title">
                                    Scan product to receive and grade
                                </label>

                                <div class="search">
                                    <input type="text" placeholder="Scan product number" id="product-sku" class="form-control" v-model="productSku" ref="scanProductInput">

                                    <div class="d-inline-block search-wrapper">
                                        <button type="button" name="button" class="btn btn-primary" v-b-toggle="'product-dropdown-menu'">
                                            <i class="icon-search"></i>

                                            <span class="d-none d-sm-inline-block">
                                                Select product
                                            </span>
                                        </button>

                                        <b-collapse id="product-dropdown-menu" class="choose-product-block">
                                            <div class="choose-product-body">
                                                <span class="choose-product-body-title">
                                                    Select product
                                                </span>

                                                <div class="row align-items-center pb-2 my-1 dropdown-product-item" v-for="expectedProduct in expectedProducts" :key="expectedProduct.PRODUCT_ID" @click.prevent="unpackProduct(expectedProduct)">
                                                    <div class="col-md-4 pr-2">
                                                        <img v-if="expectedProduct.productImage && expectedProduct.productImage.RTN_WFE_URL" :src="expectedProduct.productImage.RTN_WFE_URL" class="img-fluid" alt="">
                                                    </div>

                                                    <div class="col-md-8 pl-2">
                                                        <strong class="choose-product-body-name">
                                                            {{ expectedProduct.MODEL_NUMBER }}
                                                        </strong>

                                                        <span  class="choose-product-body-type">
                                                            {{ expectedProduct.DESCRIPTION }}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="row align-items-center pb-2 my-1 dropdown-product-item" v-for="unexpectedProduct in unexpectedProducts" :key="unexpectedProduct.PRODUCT_ID" @click.prevent="unpackProduct(unexpectedProduct)">
                                                    <div class="col-md-4 pr-2">
                                                        <img v-if="unexpectedProduct.productImage && unexpectedProduct.productImage.RTN_WFE_URL" :src="unexpectedProduct.productImage.RTN_WFE_URL" class="img-fluid" alt="">
                                                    </div>

                                                    <div class="col-md-8 pl-2">
                                                        <span class="unexpected-product-notification">
                                                            Unexpected product
                                                        </span>

                                                        <strong class="choose-product-body-name">
                                                            {{ unexpectedProduct.MODEL_NUMBER }}
                                                        </strong>

                                                        <span  class="choose-product-body-type">
                                                            {{ unexpectedProduct.DESCRIPTION }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div><!-- choose-product-body -->

                                            <div class="choose-product-footer">
                                                <button type="button" name="button" class="btn btn-primary" @click="addUnexpectedProduct()">
                                                    Receive unexpected product
                                                </button>
                                            </div><!-- choose-product-footer -->
                                        </b-collapse><!-- choose-product-block -->
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <InputFieldError attribute="global" :errors="errors"/>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <table class="table-first-level">
                                    <thead>
                                        <tr>
                                            <th>Return</th>
                                            <th>Account</th>
                                            <th>Channel</th>
                                            <th>Return Policy</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td colspan="4">
                                                <table class="table-second-level">
                                                    <thead>
                                                        <tr>
                                                            <th>{{ receivedReturn.RETURN_REFERENCE }}</th>
                                                            <th>{{ receivedReturn.MERCHANT_ACCOUNT_NAME }}</th>
                                                            <th>{{ receivedReturn.PORTAL_NAME }}</th>
                                                            <th>{{ receivedReturn.MERCHANT_SETUP_NAME }}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" :class="showUnpackRequiredMessage ? 'unpack-required-warning' : ''">
                                                                Received assets

                                                                <p class="warning-message mt-3">
                                                                    {{ unpackRequiredMessage }}
                                                                </p>

                                                                <table class="table-third-level">
                                                                    <template v-if="$store.state.assets.unpackedAssets[returnId] && Object.keys($store.state.assets.unpackedAssets[returnId]).length > 0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>SKU</th>
                                                                                <th>License plate</th>
                                                                                <th class="invisible">Container</th>
                                                                                <th class="invisible">Received grade</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="(unpackedAsset, key) in $store.state.assets.unpackedAssets[returnId]" :key="key">
                                                                                <template v-if="assetsBeingDeleted && (assetsBeingDeleted[key] || assetsBeingDeleted.indexOf(key) != -1)">
                                                                                    <td colspan="5">Deleting asset...</td>
                                                                                </template>

                                                                                <template v-else>
                                                                                    <td>
                                                                                        <template v-if="unpackedAsset.SKU">
                                                                                            {{ unpackedAsset.SKU }}
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            -
                                                                                        </template>
                                                                                    </td>

                                                                                    <td>{{ unpackedAsset.LICENSE_PLATE }}</td>

                                                                                    <td></td>
                                                                                    <td></td>

                                                                                    <td class="text-right">
                                                                                        <div class="d-inline-flex">
                                                                                            <span class="action-btn" @click.prevent="updateAsset(unpackedAsset, 'inspect')">
                                                                                              <i class="icon-resume"></i>
                                                                                              <span>Resume</span>
                                                                                            </span>

                                                                                            <span class="action-btn" @click.prevent="confirmDeleteAsset(unpackedAsset)">
                                                                                              <i class="icon-bin"></i>
                                                                                              <span>Delete</span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                </template>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>

                                                                    <template v-else>
                                                                        <tbody>
                                                                            <tr class="empty-row">
                                                                                <td class="text-center">
                                                                                    There are currently no received assets
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="5">
                                                                Graded assets

                                                                <table class="table-third-level">
                                                                    <template v-if="$store.state.assets.inspectedAssets[returnId] && Object.keys($store.state.assets.inspectedAssets[returnId]).length > 0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>SKU</th>
                                                                                <th>License plate</th>
                                                                                <th>Container</th>
                                                                                <th>Received grade</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr v-for="(inspectedAsset, key) in $store.state.assets.inspectedAssets[returnId]" :key="key">
                                                                                <template v-if="assetsBeingDeleted && (assetsBeingDeleted[key] || assetsBeingDeleted.indexOf(key) != -1)">
                                                                                    <td colspan="5">Deleting asset...</td>
                                                                                </template>

                                                                                <template v-else>
                                                                                    <td>
                                                                                        <template v-if="inspectedAsset.SKU">
                                                                                            {{ inspectedAsset.SKU }}
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            -
                                                                                        </template>
                                                                                    </td>

                                                                                    <td>{{ inspectedAsset.LICENSE_PLATE }}</td>

                                                                                    <td>
                                                                                        <span v-if="inspectedAsset.container">
                                                                                            {{ inspectedAsset.container.LICENSE_PLATE }}
                                                                                        </span>
                                                                                    </td>

                                                                                    <td>{{ inspectedAsset.GRADE }}</td>

                                                                                    <td class="text-right">
                                                                                        <div class="d-inline-flex">
                                                                                            <span class="action-btn" @click.prevent="updateAsset(inspectedAsset, 'inspect')">
                                                                                                <i class="icon-resume"></i>

                                                                                                <span>Resume</span>
                                                                                            </span>

                                                                                            <span class="action-btn" @click.prevent="confirmDeleteAsset(inspectedAsset)">
                                                                                                <i class="icon-bin"></i>

                                                                                                <span>Delete</span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                </template>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>

                                                                    <template v-else>
                                                                        <tbody>
                                                                            <tr class="empty-row">
                                                                                <td class="text-center">
                                                                                    There are currently no graded assets
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </template>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="exitReturn">
                                    Exit return
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="next()">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>

        <ConfirmationPopup v-if="showDeleteAssetConfirmationModal"
                           :message="confirmationModalText"
                           @confirm="deleteAsset()"
                           @deny="showDeleteAssetConfirmationModal = false"></ConfirmationPopup>

        <ConfirmationPopup v-if="showNoAssetsConfirmationModal"
                           :message="'Are you sure you want to complete this return without adding assets?'"
                           @confirm="next(true)"
                           @deny="showNoAssetsConfirmationModal = false"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import LoadingOverlay from '@/components/_LoadingOverlay.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import {BCollapse, VBToggle} from 'bootstrap-vue';
    import _ from 'lodash';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'LoadingOverlay': LoadingOverlay,
            'ConfirmationPopup': ConfirmationPopup,
            'b-collapse': BCollapse,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                returnId: this.$route.query.returnId,
                flow: this.$route.query.flow,
                manifestId: this.$route.query.manifestId,
                manifestReference: this.$route.query.manifestReference,
                productSku: null,
                isLoadingReturnData: false,
                receivedReturn: null,
                showProductsDropdown: false,
                errors: {},
                isSubmitting: false,
                assetsBeingDeleted: [],
                expectedProducts: {},
                unexpectedProducts: {},
                showDeleteAssetConfirmationModal: false,
                showNoAssetsConfirmationModal: false,
                confirmationModalText: null,
                assetToDelete: null,
                showUnpackRequiredMessage: false,
                unpackRequiredMessage: 'There are received assets which have not yet been graded',
            };
        },
        created() {
            this.isLoadingReturnData = true;

            let getReturnPromise = this.$store.dispatch('returns/getReceivedReturn', this.returnId);
            let getUnpackedAssetsPromise = this.$store.dispatch('assets/getAllUnpackedAssets', this.returnId);
            let getInspectedAssetsPromise = this.$store.dispatch('assets/getAllInspectedAssets', this.returnId);
            let getExpectedProducts = this.$store.dispatch('returns/getExpectedProducts', this.returnId);
            let getUnexpectedProducts = this.$store.dispatch('returns/getUnexpectedProducts', this.returnId);

            Promise.all([getReturnPromise, getUnpackedAssetsPromise, getInspectedAssetsPromise, getExpectedProducts, getUnexpectedProducts]).then((data) => {
                this.isLoadingReturnData = false;

                this.receivedReturn = data[0];
                this.expectedProducts = data[3];
                this.unexpectedProducts = data[4];

                this.$nextTick(() => {
                    /* Focus on the "scan product" input field, so the user can immediately enter a SKU on page load */
                    this.$refs.scanProductInput.focus();
                });
            });
        },
        methods: {
            exitReturn() {
                if (this.flow && this.flow === 'incomingManifest') {
                    this.$router.push({
                        name: 'ReceiveIncomingManifest',
                        query: {
                            manifestId: this.manifestId,
                            manifestReference: this.manifestReference
                        }
                    });
                } else {
                    this.$router.push({
                        name: 'ReceiveReturn'
                    });
                }
            },
            next(confirm = false) {
                this.showUnpackRequiredMessage = false;

                if (Object.keys(this.$store.state.assets.unpackedAssets[this.returnId]).length > 0) {
                    this.showUnpackRequiredMessage = true;
                    return false;
                }

                if (!confirm && Object.keys(this.$store.state.assets.unpackedAssets[this.returnId]).length === 0 && Object.keys(this.$store.state.assets.inspectedAssets[this.returnId]).length === 0) {
                    this.showNoAssetsConfirmationModal = true;
                    return false;
                }

                this.$router.push({
                    name: 'CompleteReturn',
                    query: {
                        returnId: this.$route.query.returnId,
                        flow: this.flow,
                        manifestId: this.manifestId,
                        manifestReference: this.manifestReference,
                    }
                });
            },
            receiveProduct() {
                this.errors = {};

                if (!this.productSku) {
                    this.errors = {global: 'Enter a product SKU'};
                    return false;
                }

                /* Detect if the product is expected. If not, the user has to manually add an unexpected product
                 * through the dropdown menu. */
                _.forOwn(this.expectedProducts, (expectedProduct) => {
                    if (this.productSku === expectedProduct.SKU) {
                        this.unpackProduct(expectedProduct);
                        return true;
                    }
                });

                this.errors = {global: 'No product found with SKU "' + this.productSku + '"'};
                return false;
            },
            unpackProduct(product) {
                this.errors = {};

                if (!product.PRODUCT_ID && !product.ARTICLE_ID) {
                    this.errors = {global: 'Invalid product'};
                    return false;
                }

                this.$store.commit('products/setGradingProduct', product);

                if (product.PRODUCT_ID) {
                    this.$router.push({
                        name: 'Unpack',
                        query: {
                            returnId: this.returnId,
                            productId: product.PRODUCT_ID,
                            flow: this.flow,
                            manifestId: this.manifestId,
                            manifestReference: this.manifestReference,
                        }
                    });
                } else {
                    this.$router.push({
                        name: 'Unpack',
                        query: {
                            returnId: this.returnId,
                            articleId: product.ARTICLE_ID,
                            flow: this.flow,
                            manifestId: this.manifestId,
                            manifestReference: this.manifestReference,
                        }
                    });
                }
            },
            addUnexpectedProduct() {
                this.$router.push({
                    name: 'AddUnexpectedProduct',
                    query: {
                        returnId: this.returnId,
                        flow: this.flow,
                        manifestId: this.manifestId,
                        manifestReference: this.manifestReference,
                    }
                });
            },
            updateAsset(asset, type = 'unpack') {
                this.errors = {};

                if (!asset.ID || (!asset.PRODUCT_ID && !asset.ARTICLE_ID)) {
                    this.errors = {global: 'Invalid asset'};
                    return false;
                }

                const routeName = type === 'unpack' ? 'Unpack' : 'InspectAsset';

                if (asset.PRODUCT_ID) {
                    this.$router.push({
                        name: routeName,
                        query: {
                            returnId: this.returnId,
                            productId: asset.PRODUCT_ID,
                            assetId: asset.ID,
                            flow: this.flow,
                            manifestId: this.manifestId,
                            manifestReference: this.manifestReference,
                        }
                    });
                } else {
                    this.$router.push({
                        name: routeName,
                        query: {
                            returnId: this.returnId,
                            articleId: asset.ARTICLE_ID,
                            assetId: asset.ID,
                            flow: this.flow,
                            manifestId: this.manifestId,
                            manifestReference: this.manifestReference,
                        }
                    });
                }
            },
            confirmDeleteAsset(asset) {
                this.confirmationModalText = 'Are you sure you want to delete ' + asset.LICENSE_PLATE + '? This action cannot be reversed.';
                this.showDeleteAssetConfirmationModal = true;
                this.assetToDelete = asset;
            },
            deleteAsset() {
                this.showDeleteAssetConfirmationModal = false;

                let url = new URL(this.$store.state.baseUrl + 'assets/delete-asset');
                url.searchParams.append('assetId', this.assetToDelete.ID);

                this.assetsBeingDeleted.push(this.assetToDelete.ID.toString());

                this.$http.post(url.href).then(() => {
                    let index = this.assetsBeingDeleted.indexOf(this.assetToDelete.ID);
                    this.assetsBeingDeleted.splice(index, 1);

                    if (!this.assetToDelete.PRODUCT_ID) {
                        this.$store.commit('returns/clearUnexpectedProducts');
                        this.isLoadingReturnData = true;

                        this.$store.dispatch('returns/getUnexpectedProducts', this.returnId).then((data) => {
                            this.isLoadingReturnData = false;
                            this.unexpectedProducts = data;
                        });
                    }

                    this.$store.commit('assets/deleteUnpackedAsset', this.assetToDelete);
                    this.$store.commit('assets/deleteInspectedAsset', this.assetToDelete);
                    this.assetToDelete = null;

                    /* Detect if the "Inspection of assets" warning message should still be shown. */
                    if (Object.keys(this.$store.state.assets.unpackedAssets[this.returnId]).length === 0) {
                        this.showUnpackRequiredMessage = false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .dropdown-product-item:hover {
        cursor: pointer;
        user-select: none;
    }

    .img-fluid {
        border-radius: 10px;
    }

    .warning-message {
        display: none;
    }

    .unpack-required-warning {
        table {
            border: 1px solid red;
        }

        .warning-message {
            display: block;
            color: red !important;
            font-weight: 500 !important;
        }
    }

    .unexpected-product-notification {
        color: #bdbdbd;
        font-style: italic;
    }

    .choose-product-body-name {
        word-break: break-all;
    }

    .scrollable-area--title {
        font-size: 18px;
        font-weight: 600;
    }

    .scrollable-area--subtitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(29, 38, 64, .44);
    }

    .scrollable-content {
        padding-top: 50px;
        height: calc(100vh - 180px);
    }

    .scrollable-area--header {
        padding-top: 50px;
    }
</style>
