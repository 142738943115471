import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './users';
import moduleModule from './modules';
import roleModule from './roles';
import locationModule from './locations';
import roleAccessModule from './roleAccessModule';
import locationAccessToAccounts from './locationAccessToAccounts';
import userSettingsModule from './userSettings';
import returnsModule from './returns';
import productsModule from './products';
import assetsModule from './assets';
import masterDataModule from './masterData';
import containersModule from './containers';
import articleGroupsModule from './articleGroups';
import articlesModule from './articles';
import profileModule from './profile';
import manifestsModule from './manifests';
import returnManifestModule from './returnManifests';
import locationMerchantSettingsModule from './locationMerchantSettings';
import merchantAccountModule from './merchantAccounts';
import vendorModule from './vendors';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        users: userModule,
        modules: moduleModule,
        roles: roleModule,
        locations: locationModule,
        roleAccess: roleAccessModule,
        locationAccessToAccounts: locationAccessToAccounts,
        userSettings: userSettingsModule,
        returns: returnsModule,
        products: productsModule,
        assets: assetsModule,
        masterData: masterDataModule,
        containers: containersModule,
        articleGroups: articleGroupsModule,
        articles: articlesModule,
        profiles: profileModule,
        manifests: manifestsModule,
        returnManifests: returnManifestModule,
        locationMerchantSettings: locationMerchantSettingsModule,
        merchantAccounts: merchantAccountModule,
        vendors: vendorModule,
    },
    state: {
        baseUrl: (location.hostname === "localhost" || location.hostname === "127.0.0.1") ? 'http://127.0.0.1:8000/api/' : location.protocol + '//' + location.hostname + '/api/',
    },
    mutations: {
        clear() {
            /* User module clear is done in the user module itself. */

            this.commit('modules/clear');
            this.commit('roles/clear');
            this.commit('locations/clear');
            this.commit('roleAccess/clear');
            this.commit('locationAccessToAccounts/clear');
            this.commit('userSettings/clear');
            this.commit('returns/clear');
            this.commit('products/clear');
            this.commit('assets/clear');
            this.commit('masterData/clear');
            this.commit('containers/clear');
            this.commit('articleGroups/clear');
            this.commit('articles/clear');
            this.commit('profiles/clear');
            this.commit('manifests/clear');
            this.commit('returnManifests/clear');
            this.commit('locationMerchantSettings/clear');
            this.commit('merchantAccounts/clear');
            this.commit('vendors/clear');
        }
    },
    actions: {

    },
});
