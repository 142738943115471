<template>
    <div class="image-gallery">
        <template v-if="onlyShowFirstImage && (onlyShowFirstImage === true || onlyShowFirstImage === 'true')">
            <div class="image-thumbnail--container">
                <div class="image-thumbnail" :style="'background-image: url(' + images[0][urlAttribute] + ')'"
                     @click="openModal(images[0])"></div>

                <template v-if="images.length > 1">
                    <span class="image-count user-select-none" @click="openModal(images[0])">
                        {{ images.length }}
                    </span>
                </template>
            </div>
        </template>

        <template v-else>
            <div class="image-thumbnail--container" v-for="(image, key) in images" :key="key">
                <div class="image-thumbnail" :style="'background-image: url(' + image[urlAttribute] + ')'"
                     @click="openModal(image)"></div>

                <template v-if="imagesBeingDeleted && imagesBeingDeleted.includes(image[idAttribute])">
                    <div class="image-thumbnail--overlay user-select-none d-flex justify-content-center align-items-center">
                        Deleting image...
                    </div>
                </template>

                <template v-else>
                    <span class="btn--delete-image user-select-none" @click.prevent="deleteImage(image[idAttribute])" v-if="allowDelete === true || allowDelete === 'true'">
                        &times;
                    </span>
                </template>
            </div>
        </template>

        <div class="image-modal" v-if="showModal">
            <div class="image-modal--background"></div>

            <span class="close" @click="closeModal()">&times;</span>
            <span class="previous" @click="showPreviousImage()" v-if="highlightedImageIndex !== 0">&#8249;</span>
            <span class="next" @click="showNextImage()" v-if="highlightedImageIndex !== images.length - 1">&#8250;</span>

            <div class="image-modal--image__container">
                <img class="image-modal--image" :src="highlightedImage[urlAttribute]">
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';

    export default {
        props: [
            'images',
            'onlyShowFirstImage',
            'idAttribute',
            'urlAttribute',
            'allowDelete',
            'imagesBeingDeleted'
        ],
        data() {
            return {
                showModal: false,
                highlightedImage: null,
                highlightedImageIndex: 0,
            }
        },
        mounted() {
            this.$windowEventManager.addEventListener('keydown', (event) => {
                if (event.keyCode === 27) {
                    /* Close modal when pressing the escape key */
                    this.closeModal();
                } else if (event.keyCode === 37) {
                    /* Show previous image when pressing left arrow */
                    this.showPreviousImage();
                } else if (event.keyCode === 39) {
                    /* Show next image when pressing right arrow */
                    this.showNextImage();
                }
            });
        },
        methods: {
            openModal(image) {
                this.showModal = true;
                this.highlightedImage = image;

                if (image[this.idAttribute]) {
                    this.highlightedImageIndex = _.findIndex(this.images, {[this.idAttribute]: this.highlightedImage[this.idAttribute]});
                }
            },
            closeModal() {
                this.showModal = false;
            },
            showPreviousImage() {
                if (this.showModal === true && this.highlightedImage[this.idAttribute]) {
                    let index = _.findIndex(this.images, {[this.idAttribute]: this.highlightedImage[this.idAttribute]});

                    if (index !== 0) {
                        this.highlightedImage = this.images[index - 1];
                        this.highlightedImageIndex = index - 1;
                    }
                }
            },
            showNextImage() {
                if (this.showModal === true && this.highlightedImage[this.idAttribute]) {
                    let index = _.findIndex(this.images, {[this.idAttribute]: this.highlightedImage[this.idAttribute]});

                    if (index !== this.images.length - 1) {
                        this.highlightedImage = this.images[index + 1];
                        this.highlightedImageIndex = index + 1;
                    }
                }
            },
            deleteImage(id) {
                this.$emit('deleteImage', id);
            }
        },
    }
</script>

<style scoped lang="scss">
    .image-gallery {
        .image-thumbnail {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
            border-radius: 10px;
            cursor: pointer;

            &--container {
                width: 120px;
                height: 120px;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;
                position: relative;
                text-align: center;

                .image-count {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    cursor: pointer;
                    padding: 7px 10px;
                    border-radius: 20px;
                    border: 2px solid white;
                    background: #1d2640;
                    color: white;
                    font-size: 14px;
                    line-height: 14px;
                }

                .btn--delete-image {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    cursor: pointer;
                    padding: 3px 12px;
                    border-radius: 20px;
                    border: 2px solid white;
                    background: #e24b4a;
                    color: white;
                    font-size: 20px;
                    line-height: 29px;

                    &:hover {
                        background: darken(#e24b4a, 10%);
                    }
                }
            }

            &--overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(255, 255, 255, 0.8);
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }

        &.btn--delete-image__green {
            .btn--delete-image {
                background: #03BB3F;

                &:hover {
                    background: darken(#03BB3F, 10%);
                }
            }
        }

        &.md {
            .image-thumbnail--container {
                width: 110px;
                height: 110px;
            }
        }

        &.sm {
            .image-thumbnail--container {
                width: 60px;
                height: 60px;
            }
        }
    }
</style>
