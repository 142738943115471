<template>
    <div>
        <HeaderBar v-bind:title="$route.query.roleId ? 'Role update' : 'Role create'"></HeaderBar>

        <main class="main">
            <div class="row col-12">

                <template v-if="$route.query.roleId">
                    <RoleMenu activeCategory="Role" activeItem="General" v-bind:roleId="$route.query.roleId"></RoleMenu>
                </template>
                <template v-else>
                    <SettingsMenu activeCategory="Role" activeItem="Create"></SettingsMenu>
                </template>


                <div class="col-10">
                    <div class="col-12">
                        <InputFieldError attribute="attributes" :errors="errors"/>
                    </div>

                    <div v-if="isSaving">
                        saving role...
                    </div>

                    <template v-else>
                        <div class="row">

                            <div class="col-md-6 my-3">
                                <label for="quantity-requested">
                                    Name:
                                </label>
                                <input type="text" class="form-control" v-model="role.name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 my-3">
                                <label for="quantity-requested">
                                    Description:
                                </label>
                                <input type="text" class="form-control" v-model="role.description">
                            </div>
                        </div>
                    </template>

                    <button type="button" name="button" v-if="!isSaving" v-on:click="save()" class="btn md btn-primary">
                        save
                    </button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';
    import RoleMenu from '@/components/_RoleMenu.vue';


    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'SettingsMenu': SettingsMenu,
            'RoleMenu': RoleMenu
        },
        data() {
            return {
                role: {
                    id: false,
                    name: '',
                    description: ''
                },
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {
            let rolesPromise = this.$store.dispatch('roles/getAll');
            let userPromise = this.$store.dispatch('users/getCurrentUser');


            Promise.all([userPromise, rolesPromise]).then(() => {
                this.initLoaded = true;

                if (this.$route.query.roleId) {
                    let role = this.$store.state.roles.roles[this.$route.query.roleId];
                    this.role = {
                        id: role.ID,
                        name: role.NAME,
                        description: role.DESCRIPTION,
                    };
                }
            });
        },
        methods: {
            save() {
                let params = new URLSearchParams();
                let method;
                let url;

                params.append('NAME', this.role.name);
                params.append('DESCRIPTION', this.role.description);

                this.isSaving = true;

                if (this.role.id) {
                    url = new URL(this.$store.state.baseUrl + 'roles/' + this.role.id);
                    method = this.$http.put(url.href, params);
                } else {
                    url = new URL(this.$store.state.baseUrl + 'roles');
                    method = this.$http.post(url.href, params);
                }

                method.then((data) => {
                    let role = data.data;
                    this.isSaving = false;
                    if (typeof data.response !== 'undefined' && data.response.status == 422) {
                        this.errors = {};
                        for (let obj in data.response.data) {
                            this.errors[data.response.data[obj].field] = data.response.data[obj].message;
                        }
                    } else {
                        this.$store.commit('roles/add', role);
                    }
                });
            }
        }
    }
</script>
