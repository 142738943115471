<template>
    <div>
        <HeaderBar title="Container"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <template v-if="isLoadingContainer">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="scan-manisfest-container">
                                    Loading container...
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="scrollable-content pt-0 d-flex align-items-center">
                            <div class="container">
                                <div class="table-responsive">
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>License plate</th>
                                                <th>Account</th>
                                                <th>Storage</th>
                                                <th>Disposition type</th>
                                                <th>Assets</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th class="border-bottom-none">
                                                                    {{ container.LICENSE_PLATE }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="container.RETURNBIRD_ACCOUNT_NAME">
                                                                        {{ container.RETURNBIRD_ACCOUNT_NAME | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    {{ container.STORAGE_TYPE }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="container.DISPOSITION_TYPE">
                                                                        {{ container.DISPOSITION_TYPE | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    {{ container.assets.length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="5" class="p-0 border-radius-none border-top-td">
                                                                    <table class="w-100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="w-50 bgr-grey border-radius-none vertical-top">
                                                                                    <div class="row p-lg-5">
                                                                                        <div class="col-lg-6 mb-3">
                                                                                            <span class="d-block label pb-2">
                                                                                                Container Status
                                                                                            </span>

                                                                                            <p class="text-red" v-if="container.IS_FULL == 1">
                                                                                                Full
                                                                                            </p>

                                                                                            <p class="text-green" v-else>
                                                                                                Available
                                                                                            </p>
                                                                                        </div>

                                                                                        <div class="col-lg-6">
                                                                                            <span class="d-block label pb-2">
                                                                                                Assets on container
                                                                                            </span>

                                                                                            <button type="button" name="button" class="btn btn-primary btn-md" @click.prevent="goToAssetOverview()">
                                                                                                Assets
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>

                                                                                <td class="w-50 border-radius-none vertical-top">
                                                                                    <div class="p-lg-5">
                                                                                        <template>
                                                                                            <div class="row mb-5 px-lg-4">
                                                                                                <div class="col-md-6">
                                                                                                    <span class="d-block label pb-2">Location</span>

                                                                                                    <span class="simple-text">
                                                                                                        <template v-if="container.currentLocation.NAME">
                                                                                                            {{ container.currentLocation.NAME }}
                                                                                                        </template>

                                                                                                        <template v-else>
                                                                                                            -
                                                                                                        </template>
                                                                                                    </span>
                                                                                                </div>

                                                                                                <div class="col-md-6">
                                                                                                    <span class="d-block label pb-2">
                                                                                                        Current Bin
                                                                                                    </span>

                                                                                                    <span class="simple-text">
                                                                                                        <template v-if="container.BIN_LOCATION">
                                                                                                            {{ container.BIN_LOCATION }}
                                                                                                        </template>

                                                                                                        <template v-else>
                                                                                                            -
                                                                                                        </template>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </template>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!-- scrollable-content -->
                    </template>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';

export default {
    name: 'ContainerPage',
    components: {
        'HeaderBar': HeaderBar,
    },
    data() {
        return {
            isLoadingContainer: false,
            containerId: this.$route.query.id,
            container: null,
        };
    },
    created() {
        this.getContainer();
    },
    methods: {
        previous() {
            this.$router.push({
                name: 'InventoryContainerOverview',
            });
        },
        getContainer() {
            this.isLoadingContainer = true;

            let url = new URL(this.$store.state.baseUrl + 'containers/get-by-id');
            url.searchParams.append('id', this.containerId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingContainer = false;
                this.container = data.data;
            });
        },
        goToAssetOverview() {
            this.$router.push({
                name: 'InventoryContainerAssetOverview',
                query: {id: this.containerId}
            });
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) {
                return '';
            }

            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
}
</script>

<style scoped>

</style>
