import axios from '@/classes/Axios';
import ArrayStoreFormatter from '@/classes/ArrayStoreFormatter';

const locationStore = {
	namespaced: true,
	state: {
		locations: null,
	},
	mutations: {
		set(state, locations) {
			state.locations = locations;
		},
		clear(state) {
			state.locations = null;
		}
	},
	actions: {
		getAll(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.locations) {
                    resolve(storeArgObj.state.locations);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'locations/get-all');
                    axios.get(url.href).then((data) => {
                        let locations = ArrayStoreFormatter.format(data.data, 'ID');
                        storeArgObj.commit('set', locations);
                        resolve(locations);
                    });
                }
            });
        },
	}
};
export default locationStore;
