<template>
    <div>
        <HeaderBar title="Container"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="updateBinLocation()">
                <div class="scrollable-area">
                    <template v-if="isLoadingContainer">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="scan-manisfest-container">
                                    Loading container...
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="scrollable-content pt-0 d-flex align-items-center">
                            <div class="container">
                                <div class="table-responsive">
                                    <table class="table-first-level">
                                        <thead>
                                            <tr>
                                                <th>License plate</th>
                                                <th>Account</th>
                                                <th>Storage</th>
                                                <th>
                                                    <template v-if="container.STORAGE_TYPE === 'AFS'">
                                                        Disposition resolution
                                                    </template>

                                                    <template v-else>
                                                        Disposition type
                                                    </template>
                                                </th>
                                                <th>Assets</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="5">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th class="border-bottom-none">
                                                                    {{ container.LICENSE_PLATE }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="container.RETURNBIRD_ACCOUNT_NAME">
                                                                        {{ container.RETURNBIRD_ACCOUNT_NAME | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    {{ container.STORAGE_TYPE }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="container.STORAGE_TYPE === 'AFS'">
                                                                        <template v-if="container.DISPOSITION_RESOLUTION">
                                                                            {{ container.DISPOSITION_RESOLUTION | capitalize }}
                                                                        </template>

                                                                        <template v-else>
                                                                            -
                                                                        </template>
                                                                    </template>

                                                                    <template v-else>
                                                                        <template v-if="container.DISPOSITION_TYPE">
                                                                            {{ container.DISPOSITION_TYPE | capitalize }}
                                                                        </template>

                                                                        <template v-else>
                                                                            -
                                                                        </template>
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    {{ container.assets.length }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="5" class="p-0 border-radius-none border-top-td">
                                                                    <table class="w-100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="w-50 bgr-grey border-radius-none vertical-top">
                                                                                    <div class="row p-lg-5">
                                                                                        <div class="col-lg-6 mb-3">
                                                                                            <span class="d-block label pb-2">
                                                                                                Container Status
                                                                                            </span>

                                                                                            <div class="flipswitch">
                                                                                                <input type="checkbox" name="flipswitch" class="flipswitch-cb" id="fs" :checked="container.IS_FULL == 1" @click.prevent="toggleContainerFull()" :disabled="isSavingContainerStatus">

                                                                                                <label class="flipswitch-label mb-0" for="fs">
                                                                                                    <div class="flipswitch-inner"></div>
                                                                                                    <div class="flipswitch-switch"></div>
                                                                                                </label>
                                                                                            </div>

                                                                                            <div class="row">
                                                                                                <div class="col-12">
                                                                                                    <InputFieldError attribute="containerStatus" :errors="errors"/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6">
                                                                                            <span class="d-block label pb-2">
                                                                                                Assets on container
                                                                                            </span>

                                                                                            <button type="button" name="button" class="btn btn-primary btn-md" @click.prevent="goToAssetOverview()">
                                                                                                Assets
                                                                                            </button>
                                                                                        </div>

                                                                                        <div class="col-12 mt-5">
                                                                                            <div>
                                                                                                <button type="button" class="btn btn-danger sm" @click.prevent="showDeleteContainerPopup = true" v-if="!container.assets || Object.keys(container.assets).length === 0">
                                                                                                    Delete
                                                                                                </button>

                                                                                                <InputFieldError attribute="containerDelete" :errors="errors"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>

                                                                                <td class="w-50 border-radius-none vertical-top">
                                                                                    <div class="p-lg-5">
                                                                                        <template v-if="isSavingBinLocation">
                                                                                            Saving bin location...
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            <div class="row mb-5 px-lg-4">
                                                                                                <div class="col-md-6">
                                                                                                    <span class="d-block label pb-2">Location</span>

                                                                                                    <span class="simple-text">
                                                                                                        <template v-if="container.currentLocation.NAME">
                                                                                                            {{ container.currentLocation.NAME }}
                                                                                                        </template>

                                                                                                        <template v-else>
                                                                                                            -
                                                                                                        </template>
                                                                                                    </span>
                                                                                                </div>

                                                                                                <div class="col-md-6">
                                                                                                    <span class="d-block label pb-2">
                                                                                                        Current Bin
                                                                                                    </span>

                                                                                                    <span class="simple-text">
                                                                                                        <template v-if="container.BIN_LOCATION">
                                                                                                            {{ container.BIN_LOCATION }}
                                                                                                        </template>

                                                                                                        <template v-else>
                                                                                                            -
                                                                                                        </template>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="form-group px-lg-4">
                                                                                                <label for="new-bin-location">New Bin</label>

                                                                                                <div class="row align-items-center scan-licence-plate">
                                                                                                    <div class="col-md-7 pr-0">
                                                                                                        <input id="new-bin-location" type="text" name="" value="" class="form-control" placeholder="Scan Bin" v-model="binLocation" ref="scanBinLocationInput">
                                                                                                    </div>

                                                                                                    <div class="col-md-5">
                                                                                                        <button type="button" class="btn btn-primary" @click.prevent="updateBinLocation()">
                                                                                                            UPDATE BIN
                                                                                                        </button>
                                                                                                    </div>

                                                                                                    <div class="col-12">
                                                                                                        <InputFieldError attribute="binLocation" :errors="errors"/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </template>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!-- scrollable-content -->
                    </template>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>

        <ConfirmationPopup v-if="showDeleteContainerPopup"
                           :message="'Are you sure you want to delete container ' + container.LICENSE_PLATE + '?'"
                           :loadingMessage="'Deleting container ' + container.LICENSE_PLATE + '...'"
                           :isLoading="isDeletingContainer"
                           @confirm="deleteContainer()"
                           @deny="showDeleteContainerPopup = false"></ConfirmationPopup>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

export default {
    name: 'ContainerPage',
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'ConfirmationPopup': ConfirmationPopup,
    },
    data() {
        return {
            errors: {},
            isLoadingContainer: false,
            isSavingContainerStatus: false,
            isSavingBinLocation: false,
            selectedLocationId: null,
            containerId: this.$route.query.id,
            container: null,
            binLocation: '',
            showDeleteContainerPopup: false,
            isDeletingContainer: false,
        };
    },
    created() {
        this.isLoadingContainer = true;

        this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
            if (data && data.value) {
                this.selectedLocationId = data.value;
            }

            this.getContainer();
        });
    },
    methods: {
        previous() {
            this.$router.push({
                name: 'ContainerOverview',
            });
        },
        getContainer() {
            this.isLoadingContainer = true;

            let url = new URL(this.$store.state.baseUrl + 'containers/get-by-id');
            url.searchParams.append('locationId', this.selectedLocationId);
            url.searchParams.append('id', this.containerId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingContainer = false;
                this.container = data.data;

                this.$nextTick(() => {
                    if (this.$refs.scanBinLocationInput) {
                        this.$refs.scanBinLocationInput.focus();
                    }
                });
            });
        },
        toggleContainerFull() {
            this.errors = {};
            this.isSavingContainerStatus = true;

            let newValue = this.container.IS_FULL == 1 ? 0 : 1;

            let url = new URL(this.$store.state.baseUrl + 'containers/' + this.container.ID);
            let params = new URLSearchParams();
            params.append('IS_FULL', newValue);

            this.$http.put(url.href, params).then((data) => {
                this.isSavingContainerStatus = false;

                if (data.status === 200) {
                    this.container.IS_FULL = newValue;
                } else {
                    this.errors = {containerStatus: 'Unable to update container status.'};
                }
            });
        },
        updateBinLocation() {
            this.errors = {};
            this.isSavingBinLocation = true;

            let url = new URL(this.$store.state.baseUrl + 'containers/' + this.container.ID);
            let params = new URLSearchParams();
            params.append('BIN_LOCATION', this.binLocation);

            this.$http.put(url.href, params).then((data) => {
                this.isSavingBinLocation = false;

                if (data.status === 200) {
                    this.container.BIN_LOCATION = data.data.BIN_LOCATION;
                    this.binLocation = '';
                } else {
                    this.errors = {binLocation: 'Unable to update bin location.'};
                }
            });
        },
        goToAssetOverview() {
            this.$router.push({
                name: 'ContainerAssetOverview',
                query: {id: this.containerId}
            });
        },
        deleteContainer() {
            this.errors = {};
            this.isDeletingContainer = true;

            const url = new URL(this.$store.state.baseUrl + 'containers/delete-container');
            const params = new URLSearchParams();

            params.append('Container[ID]', this.container.ID);

            this.$http.post(url.href, params).then((data) => {
                this.isDeletingContainer = false;
                this.showDeleteContainerPopup = false;

                if (data.data.code === 422) {
                    this.errors = {containerDelete: data.data.errors};
                } else {
                    this.$router.push({
                        name: 'ContainerOverview',
                    });
                }
            });
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) {
                return '';
            }

            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
}
</script>

<style scoped lang="scss">
    .scan-licence-plate {
        .form-control {
            border-radius: 0.25rem;
        }

        .btn {
            width: 100%;
            max-width: 120px;
            border-radius: 6px;
        }
    }
</style>
