<template>
    <LeftMenuBase v-bind:menu="menu"></LeftMenuBase>
</template>


<script>
    import LeftMenuBase from './_LeftMenuBase';

    export default {
        components: {
            'LeftMenuBase': LeftMenuBase
        },
        props: ['activeCategory', 'activeItem'],
        data() {
            return {
                menu: {
                    categories: {
                        Role: {
                            name: 'Role',
                            link: '/roleOverview',
                            items: {
                                Overview: { link: '/roleOverview' },
                                Create: { link: '/roleUpdate' }
                            }
                        },
                        User: {
                            name: 'User',
                            link: '/userOverview',
                            items: {
                                Overview: { link: '/userOverview' },
                                Create: { link: '/userUpdate' },
                            }
                        },
                        Location: {
                            name: 'Locations',
                            link: '/locationOverview',
                            items: {
                                Overview: { link: '/locationOverview' },
                                Create: { link: '/locationUpdate' },
                            }
                        },
                        LocationMerchantSettings: {
                            name: 'Location Merchant Settings',
                            link: '/locationMerchantSettings/update',
                            items: {
                                Update: { link: '/locationMerchantSettings/update' },
                            }
                        },
                        Profile: {
                            name: 'Profile',
                            link: '/profile/update',
                            items: {
                                Update: { link: '/profile/update' },
                            }
                        },
                        Connection: {
                            name: 'Connections',
                            link: '/connectionOverview',
                            items: {
                                Overview: { link: '/connectionOverview' },
                            }
                        }
                    }
                }
            };
        },
        created() {
            this.menu['activeCategory'] = this.activeCategory;
            this.menu['activeItem'] = this.activeItem;
        }
    }
</script>
