import axios from '@/classes/Axios';

const assetStore = {
    namespaced: true,
    state: {
        countryList: null,
    },
    mutations: {
        setCountryList(state, countryList) {
            state.countryList = countryList;
        },
        clearCountryList(state) {
            state.countryList = null;
        },
        clear(state) {
            state.countryList = null;
        }
    },
    actions: {
        getCountryList(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.countryList) {
                    resolve(storeArgObj.state.countryList);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'model-master-datas/get-country-list');

                    axios.get(url.href).then((data) => {
                        if (!data.data) {
                            return false;
                        }

                        storeArgObj.commit('setCountryList', data.data);
                        resolve(data.data);
                    });
                }
            });
        }
    }
};

export default assetStore;
