<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">

            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form v-on:submit.prevent="getReturnData()">
                            <div class="form-group">
                                <label for="rma-input" class="label-title">
                                    Scan to receive return to manifest
                                </label>

                                <input type="text" placeholder="Scan RMA number or tracking code" class="form-control lg"
                                       id="rma-input" v-model="returnReference" ref="scanRmaInput" :disabled="isLoading || isSaving || isMarkingManifestReadyToShip">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="returnReference" :errors="errors"/>
                                </div>
                            </div>
                        </form>

                        <div class="table-responsive">
                            <table class="table-first-level col-count-6">
                                <thead>
                                    <tr>
                                        <th>Return</th>
                                        <th>External reference</th>
                                        <th>Account</th>
                                        <th>Channel</th>
                                        <th>Return policy</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="6">
                                            <table class="table-second-level col-count-6">
                                                <thead>
                                                    <tr>
                                                        <template v-if="isLoading">
                                                            <th class="border-bottom-none">
                                                                Loading return data...
                                                            </th>
                                                        </template>

                                                        <template v-else-if="errors.returnReference">
                                                            <th class="border-bottom-none"></th>
                                                        </template>

                                                        <template v-else-if="!returnModel || Object.keys(returnModel).length === 0">
                                                            <th colspan="6" class="border-bottom-none"></th>
                                                        </template>

                                                        <template v-else>
                                                            <th class="border-bottom-none">
                                                                <span v-if="returnModel.RETURN_REFERENCE">
                                                                    {{ returnModel.RETURN_REFERENCE }}
                                                                </span>

                                                                <span v-else>
                                                                    {{ returnModel.SHIPMENT_TRACKING_CODE }}
                                                                </span>
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <span v-if="returnModel.EXTERNAL_REFERENCE">
                                                                    {{ returnModel.EXTERNAL_REFERENCE }}
                                                                </span>

                                                                <span v-else>
                                                                    -
                                                                </span>
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <span v-if="returnModel.MERCHANT_ACCOUNT_NAME">
                                                                    {{ returnModel.MERCHANT_ACCOUNT_NAME }}
                                                                </span>
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <span v-if="returnModel.PORTAL_NAME">
                                                                    {{ returnModel.PORTAL_NAME }}
                                                                </span>

                                                                <span v-else>
                                                                    -
                                                                </span>
                                                            </th>

                                                            <th class="border-bottom-none">
                                                                <span v-if="returnModel.MERCHANT_SETUP_NAME">
                                                                    {{ returnModel.MERCHANT_SETUP_NAME }}
                                                                </span>

                                                                <span v-else>
                                                                    -
                                                                </span>
                                                            </th>

                                                            <th class="border-bottom-none text-right">
                                                                <router-link :to="{name: 'AddReturnRemarks', query: {pk: returnModel.ID}}" class="btn btn-primary">
                                                                    Remarks
                                                                </router-link>
                                                            </th>
                                                        </template>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-group mt-5">
                            <label class="checkbox-container">
                                Mark manifest as ready to ship

                                <input type="checkbox" v-model="manifestIsReadyToShip">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                        <form v-on:submit.prevent="scanManifest()">
                            <div class="form-group">
                                <input type="text" placeholder="Scan manifest number" class="form-control lg"
                                       :disabled="!scanManifestInputActive || isSaving || isMarkingManifestReadyToShip"
                                       v-model="manifestCode" ref="scanManifestInput">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="manifestCode" :errors="errors"/>
                                </div>
                            </div>
                        </form>

                        <p class="saving-message" v-if="isSaving">
                            Adding return {{ returnReference }} to manifest {{ manifestCode }}...
                        </p>

                        <p class="saving-message" v-if="isMarkingManifestReadyToShip">
                            Marking manifest {{ manifest.MANIFEST_CODE }} as ready to ship...
                        </p>

                        <template v-if="recentlyAddedReturns">
                            <p v-for="(value, key) in recentlyAddedReturns" :class="'added-return-message-' + key" :key="key">
                                {{ value }}
                            </p>
                        </template>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" @click="previous()">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <ConfirmationPopup v-if="showConfirmationModal"
                           :message="confirmationModalText"
                           @deny="closeReceiveReturnModal()" @confirm="getReturnData(true)"></ConfirmationPopup>

        <ConfirmationPopup v-if="showCreateManifestModal"
                           :message="'Create new manifest with manifest code ' + manifestCode + '?'"
                           @deny="closeCreateManifestModal()" @confirm="scanManifest(true)"></ConfirmationPopup>

        <ConfirmationPopup v-if="showCreateUnspecifiedReturnModal"
                           :message="'The return data is not available. Do you want to register an unspecified return?'"
                           @deny="closeCreateUnspecifiedReturnModal()" @confirm="confirmCreateUnspecifiedReturn()"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                returnReference: this.$route.query.returnReference,
                preload: this.$route.query.preload,
                isLoading: false,
                isLoadingCountryList: false,
                isSaving: false,
                isMarkingManifestReadyToShip: false,
                errors: {},
                manifest: null,
                manifestCode: null,
                manifestId: null,
                manifestIsReadyToShip: false,
                returnModel: {},
                scanManifestInputActive: false,
                recentlyAddedReturns: [],
                selectedLocation: null,
                countryList: null,
                showConfirmationModal: false,
                showCreateManifestModal: false,
                showCreateUnspecifiedReturnModal: false,
                confirmationModalText: null,
            };
        },
        mounted() {
            /* Focus on the "Scan RMA" input field, so the user can immediately enter a return reference on page load */
            this.$refs.scanRmaInput.focus();
        },
        created() {
            this.getCountryList();

            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;

                    if (this.returnReference && (this.preload === true || this.preload === 'true')) {
                        this.preloadReturn();
                    }
                }
            });
        },
        methods: {
            getReturnData(confirm = false) {
                this.showConfirmationModal = false;
                this.isLoading = true;
                this.errors = {};
                this.clearData();

                /* Check if the return reference is empty, or exists of only spaces */
                if (!this.returnReference || /^\s*$/.test(this.returnReference)) {
                    this.errors = {returnReference: 'Enter a return reference!'};
                    this.isLoading = false;
                    return false;
                }

                let findReturnUrl = new URL(this.$store.state.baseUrl + 'return-manifests/get-return');
                findReturnUrl.searchParams.append('returnReference', this.returnReference);
                findReturnUrl.searchParams.append('confirm', confirm);

                this.$http.get(findReturnUrl.href).then((data) => {
                    this.isLoading = false;

                    /*
                     * Response code 422:
                     * - If the return was "Not found", give the operator the option to create an unspecified return
                     * - Otherwise show the error message
                     *
                     * Response code 300:
                     * - The operator has to confirm something before the return will be received.
                     *
                     * Response code 303:
                     * - Route 2 if the return is known in Returnbird (IS_UNSPECIFIED == 0).
                     * - Route 4 if the return is not known in Returnbird (IS_UNSPECIFIED == 1).
                     *
                     * Response code 100:
                     * - Route 1: the return can be sorted.
                     */

                    if (data.data.code === 422) {
                        if (data.data.message === 'Not found') {
                            /* If the return was not found, ask if the operator wants to receive it as an unspecified return. */
                            this.showCreateUnspecifiedReturnModal = true;
                        } else {
                            this.errors = {returnReference: data.data.errors};
                        }
                    } else if (data.data.code === 300) {
                        this.showConfirmationModal = true;
                        this.confirmationModalText = data.data.message;
                    } else if (data.data.code === 303) {
                        if (data.data.data.IS_UNSPECIFIED) {
                            /* Check & Sort the unspecified return (unknown in Returnbird) */
                            this.$router.push({
                                name: 'RegisterUnspecifiedReturnProducts',
                                query: {returnPk: data.data.data.ID, returnReference: this.returnReference}
                            });
                        } else {
                            /* Check & Sort the return (known in Returnbird) */
                            this.$router.push({
                                name: 'CheckReturnAssetOverview',
                                query: {returnId: data.data.data.RETURN_ID, returnReference: this.returnReference}
                            });
                        }
                    } else {
                        this.returnModel = data.data.data;
                        this.scanManifestInputActive = true;

                        /* Focus on the "Scan manifest" input field after activating it. */
                        this.$nextTick(() => {
                            this.$refs.scanManifestInput.focus();
                        });
                    }
                });
            },
            preloadReturn() {
                this.isLoading = true;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/get-return');
                url.searchParams.append('returnReference', this.returnReference);
                url.searchParams.append('confirm', true);

                this.$http.get(url.href).then((data) => {
                    this.isLoading = false;

                    this.returnModel = data.data.data;
                    this.scanManifestInputActive = true;

                    /* Focus on the "Scan manifest" input field after activating it. */
                    this.$nextTick(() => {
                        this.$refs.scanManifestInput.focus();
                    });
                });
            },
            scanManifest(confirmCreateManifest = false) {
                this.errors = {};
                this.isSaving = true;
                this.showCreateManifestModal = false;

                if (!this.manifestCode || /^\s*$/.test(this.manifestCode)) {
                    this.errors = {manifestCode: 'Enter a manifest code!'};
                    this.isSaving = false;
                    return false;
                }

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/sort-return-to-manifest');
                url.searchParams.append('locationId', this.selectedLocation);
                url.searchParams.append('manifestCode', this.manifestCode);
                url.searchParams.append('sortingType', 'sorted');
                url.searchParams.append('confirmCreateManifest', confirmCreateManifest);

                let params = new URLSearchParams();

                if (this.returnModel.RETURN_ID) {
                    params.append('ReturnManifest[RETURN_ID]', this.returnModel.RETURN_ID);
                } else {
                    params.append('ReturnManifest[RETURN_ID]', this.returnModel.SHIPMENT_TRACKING_CODE);
                }

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        if (data.data.errors.MANIFEST_CODE) {
                            /* Validation error */
                            this.errors = {manifestCode: data.data.errors.MANIFEST_CODE};
                        } else {
                            this.errors = {manifestCode: data.data.errors};
                        }
                    } else if (data.data.code === 300) {
                        this.showCreateManifestModal = true;
                    } else {
                        this.manifestId = data.data.data.RETURN_MANIFEST_ID;

                        /* Add the return to the list of recently added returns. Keep the list limited to 3 entries. */
                        this.recentlyAddedReturns.unshift('Return ' + this.returnReference + ' has been added to manifest ' + this.manifestCode);
                        this.recentlyAddedReturns.length = Math.min(this.recentlyAddedReturns.length, 3);

                        if (this.manifestIsReadyToShip) {
                            this.setManifestReadyToShip();
                        } else {
                            this.returnReference = null;
                            this.clearData();

                            /* Focus on the "Scan RMA" input field after activating it. */
                            this.$nextTick(() => {
                                this.$refs.scanRmaInput.focus();
                            });
                        }
                    }
                });
            },
            clearData() {
                this.returnModel = {};
                this.manifestCode = null;
                this.manifestId = null;
                this.scanManifestInputActive = false;
                this.manifestIsReadyToShip = false;
            },
            previous() {
                this.$router.push({
                    name: 'Inbound',
                });
            },
            getCountryList() {
                this.isLoadingCountryList = true;

                this.$store.dispatch('masterData/getCountryList').then((data) => {
                    this.isLoadingCountryList = false;
                    this.countryList = data;
                });
            },
            closeReceiveReturnModal() {
                this.showConfirmationModal = false;
                this.returnReference = null;
                this.manifestCode = null;
                this.$refs.scanRmaInput.focus();
            },
            closeCreateManifestModal() {
                this.showCreateManifestModal = false;
                this.manifestIsReadyToShip = false;
                this.manifestCode = null;
                this.$refs.scanManifestInput.focus();
            },
            setManifestReadyToShip() {
                this.isMarkingManifestReadyToShip = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/set-manifest-ready-to-ship');
                const params = new URLSearchParams();

                params.append('ReturnManifest[ID]', this.manifestId);
                params.append('ReturnManifest[IS_READY_TO_SHIP]', 1);

                this.$http.post(url.href, params).then((data) => {
                    this.isMarkingManifestReadyToShip = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else {
                        this.recentlyAddedReturns.unshift('Manifest ' + this.manifestCode + ' has been marked as ready to ship.');
                        this.recentlyAddedReturns.length = Math.min(this.recentlyAddedReturns.length, 3);

                        this.returnReference = null;
                        this.clearData();

                        this.$nextTick(() => {
                            this.$refs.scanManifestInput.focus();
                        });
                    }
                });
            },
            closeCreateUnspecifiedReturnModal() {
                this.showCreateUnspecifiedReturnModal = false;
                this.returnReference = null;

                this.$refs.scanRmaInput.focus();
            },
            confirmCreateUnspecifiedReturn() {
                this.$router.push({
                    name: 'CreateUnspecifiedReturn',
                    params: {flow: 'manifest'},
                    query: {returnReference: this.returnReference}
                });
            },
        }
    }
</script>

<style scoped>
    .added-return-message-0, .saving-message {
        margin-top: 20px;
        color: #1d2640;
    }

    .added-return-message-1 {
        color: #868686;
    }

    .added-return-message-2 {
        color: #bbb;
    }
</style>
