<template>
    <div>
        <HeaderBar title="Create work order"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <table class="table-first-level">
                                <thead>
                                    <tr>
                                        <th>Selected containers</th>
                                        <th>Account</th>
                                        <th>Vendor</th>
                                        <th>Vendor entity</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table-second-level">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ Object.keys(selectedContainers).length }}
                                                        </th>

                                                        <th>
                                                            <template v-if="!isLoadingMerchantAccounts">
                                                                <v-select v-model="selectedMerchantAccountId"
                                                                          :filterable="true"
                                                                          :searchable="true"
                                                                          :clearable="false"
                                                                          :options="merchantAccounts"
                                                                          label="name"
                                                                          :reduce="option => option.id"
                                                                ></v-select>
                                                            </template>
                                                        </th>

                                                        <th>
                                                            <template v-if="selectedMerchantAccountId && !isLoadingVendors">
                                                                <v-select v-model="selectedVendor"
                                                                          :filterable="true"
                                                                          :searchable="true"
                                                                          :clearable="false"
                                                                          :options="vendors"
                                                                          label="RTN_OZN_NAME"
                                                                ></v-select>
                                                            </template>
                                                        </th>

                                                        <th>
                                                            <template v-if="selectedMerchantAccountId && selectedVendor && !isLoadingVendors">
                                                                <v-select v-model="selectedVendorEntity"
                                                                          :filterable="true"
                                                                          :searchable="true"
                                                                          :clearable="false"
                                                                          :options="selectedVendor.entities"
                                                                          label="RTN_OZE_NAME"
                                                                ></v-select>
                                                            </template>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <template v-if="!selectedMerchantAccountId">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                Select a merchant and a vendor
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else-if="isLoadingContainers">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                Loading containers to ship...
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else-if="containerArray.length === 0">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                No containers ready to ship.
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else>
                                                        <tr>
                                                            <td colspan="4">
                                                                <input type="text" placeholder="Scan container license plate" class="form-control lg" ref="scanContainerInput" v-model="containerLicensePlate">
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <div class="d-flex justify-content-between">
                                                                    <span>
                                                                        <template v-if="containerLicensePlate || showSelectedContainersOnly">
                                                                            Showing {{ filteredContainersReadyToShip.length }} of {{ containerArray.length }} containers
                                                                        </template>

                                                                        <template v-else>
                                                                            Showing all containers
                                                                        </template>
                                                                    </span>

                                                                    <label class="checkbox-container mr-3">
                                                                        <input type="checkbox" class="form-control" v-model="showSelectedContainersOnly">
                                                                        <span class="checkmark"></span>

                                                                        Only show selected containers
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="py-0">
                                                                <InputFieldError attribute="shipment" :errors="errors"/>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="6" class="pt-5 pb-3">
                                                                <label class="checkbox-container mb-0 ml-4" @click.prevent="toggleAllCheckboxes()">
                                                                    Select all

                                                                    <input type="checkbox" v-model="selectedAll" :disabled="isSaving">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <table class="table-third-level">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="w-20 pr-0"></th>

                                                                            <th class="w-auto pl-0">
                                                                                License plate
                                                                            </th>

                                                                            <th class="w-auto">
                                                                                Account
                                                                            </th>

                                                                            <th class="w-auto">
                                                                                Assets
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="container in filteredContainersReadyToShip" :key="container.ID">
                                                                            <td class="w-20 pr-0">
                                                                                <label class="checkbox-container mb-20 mr-1">
                                                                                    <input type="checkbox" v-model="selectedContainersToShip[container.ID]" :disabled="isSaving">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </td>

                                                                            <td class="w-auto pl-0">
                                                                                {{ container.LICENSE_PLATE }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                <template v-if="container.RETURNBIRD_ACCOUNT_NAME">
                                                                                    {{ container.RETURNBIRD_ACCOUNT_NAME }}
                                                                                </template>

                                                                                <template v-else>
                                                                                    -
                                                                                </template>
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ container.assets.length }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between align-items-center">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <div v-if="isSaving">
                                    Creating work order...
                                </div>

                                <button type="button" class="btn btn-primary" @click="createWorkOrder()" :disabled="!Object.keys(selectedContainers).length || isSaving || !selectedMerchantAccountId || !selectedVendor || !selectedVendorEntity">
                                    Create work order
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
        },
        data() {
            return {
                errors: {},
                isLoadingContainers: false,
                isLoadingMerchantAccounts: false,
                isLoadingVendors: false,
                isSaving: false,
                externalReference: null,
                merchantAccounts: [],
                vendors: [],
                selectedMerchantAccountId: null,
                selectedVendor: null,
                selectedVendorEntity: null,
                containerLicensePlate: null,
                showSelectedContainersOnly: false,
                containersReadyToShip: null,
                selectedContainersToShip: {},
            };
        },
        created() {
            this.getContainers();
            this.getMerchantAccounts();
        },
        methods: {
            getContainers() {
                this.containersReadyToShip = null;
                this.isLoadingContainers = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/get-all');
                url.searchParams.set('includeOnShipment', false);
                url.searchParams.set('includeOnWorkOrder', false);
                url.searchParams.set('closedOnly', true);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainers = false;
                    this.containersReadyToShip = data.data;
                });
            },
            getMerchantAccounts() {
                this.isLoadingMerchantAccounts = true;

                this.$store.dispatch('merchantAccounts/getMerchantAccounts').then((data) => {
                    this.isLoadingMerchantAccounts = false;

                    if (data) {
                        for (let i in data) {
                            this.merchantAccounts.push({
                                id: data[i].RTN_ACT_ID,
                                origin: 1,
                                name: data[i].RTN_ACT_NAME
                            });
                        }
                    }
                });
            },
            getVendors(merchantId) {
                this.isLoadingVendors = true;

                this.$store.dispatch('vendors/getVendors', merchantId).then((data) => {
                    this.isLoadingVendors = false;
                    this.vendors = data;

                    if (this.vendors?.length === 1) {
                        this.selectedVendor = this.vendors[0];
                    }
                });
            },
            toggleAllCheckboxes() {
                if (this.isCreatingShipment) {
                    return;
                }

                if (this.filteredContainersReadyToShip) {
                    const isSelected = this.selectedAll;

                    for (let i = 0; i < Object.keys(this.filteredContainersReadyToShip).length; i++) {
                        const key = this.filteredContainersReadyToShip[i].ID;
                        this.$set(this.selectedContainersToShip, key, !isSelected);
                    }
                }
            },
            createWorkOrder() {
                if (Object.keys(this.selectedContainers).length === 0) {
                    return false;
                }

                if (!this.selectedMerchantAccountId || !this.selectedVendor || !this.selectedVendorEntity) {
                    return false;
                }

                this.isSaving = true;

                const url = new URL(this.$store.state.baseUrl + 'work-order/create-work-order');
                const params = new URLSearchParams();
                params.append('WorkOrder[MERCHANT_ACCOUNT_ID]', this.selectedMerchantAccountId);
                params.append('WorkOrder[VENDOR_ENTITY_ID]', this.selectedVendorEntity.RTN_OZE_ID);
                params.append('WorkOrder[VENDOR_ENTITY_NAME]', this.selectedVendorEntity.RTN_OZE_NAME);

                this.selectedContainers.map(container =>  { params.append('WorkOrder[CONTAINER_IDS][]', container.ID)});

                if (this.externalReference && /^\s*$/.test(this.externalReference)) {
                    params.append('WorkOrder[EXTERNAL_REFERENCE]', this.externalReference);
                }

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;
                    if (data.data.code === 422) {
                        this.errors = {containerId: data.data.message};
                    } else {
                        this.$router.push({
                            name: 'WorkOrderOverview',
                        });
                    }
                });
            },
            previous() {
                this.$router.push({
                    name: 'WorkOrderOverview',
                });
            },
        },
        computed: {
            containerArray() {
                if (!this.containersReadyToShip) {
                    return [];
                }

                let result = [];

                for (let i in this.containersReadyToShip) {
                    const container = this.containersReadyToShip[i];

                    if (container.RETURNBIRD_ACCOUNT_ID !== this.selectedMerchantAccountId) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            filteredContainersReadyToShip() {
                if (!this.containerArray || this.containerArray.length === 0) {
                    return [];
                }

                let result = [];

                for (let i in this.containerArray) {
                    const container = this.containerArray[i];

                    if (this.showSelectedContainersOnly && !this.selectedContainers.includes(container)) {
                        continue;
                    }

                    if (this.containerLicensePlate && !container.LICENSE_PLATE.toLowerCase().includes(this.containerLicensePlate.toLowerCase()) && !container.ID.includes(this.containerLicensePlate)) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            selectedContainers() {
                if (Object.keys(this.selectedContainersToShip).length === 0) {
                    return [];
                }

                let result = [];

                for (let i = 0; i < Object.keys(this.selectedContainersToShip).length; i++) {
                    const key = Object.keys(this.selectedContainersToShip)[i];
                    const value = Object.values(this.selectedContainersToShip)[i];

                    if (value) {
                        const container = this.containersReadyToShip.find(container => container.ID == key);

                        if (container) {
                            result.push(container);
                        }
                    }
                }

                return result;
            },
            selectedAll() {
                return this.filteredContainersReadyToShip.length > 0 && this.filteredContainersReadyToShip.length === this.selectedContainers.length;
            },
        },
        watch: {
            selectedMerchantAccountId(id) {
                this.selectedVendor = null;

                if (id) {
                    this.getVendors(id);
                }
            },
            selectedVendor(selectedVendor) {
                this.selectedVendorEntity = null;

                if (selectedVendor?.entities?.length === 1) {
                    this.selectedVendorEntity = selectedVendor.entities[0];
                }
            }
        }
    }
</script>

<style scoped>

</style>
