<template>
    <div>
        <div class="form-group" v-for="(ca, key) in customAttributes" :key="key">
            <label class="label">
                {{ca.label}}
            </label>

            <p>
                <template v-if="ca.type === 'date'">
                    {{ ca.value | datepickerFormatter }}
                </template>

                <template v-else-if="ca.type === 'file' && ca.value.url && ca.value.label">
                    <a :href="ca.value.url" target="_blank">
                        {{ ca.value.label }}
                    </a>
                </template>

                <template v-else>
                    {{ ca.value }}
                </template>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: [
            'customAttributes'
        ],
        data() {
            return {}
        },
        methods: {},
        created() {

        },
        filters: {
            datepickerFormatter(date) {
                let d = new Date(date);
                return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
            },
        },
    }
</script>

<style scoped>

</style>
