<template>
    <div>
        <HeaderBar></HeaderBar>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form v-on:submit.prevent="scanWorkOrder()">
                            <div class="form-group mt-5">
                                <label for="wo-input" class="label-title">
                                    Scan work order
                                </label>

                                <input type="text" placeholder="Scan work order reference" class="form-control lg"
                                       :disabled="isLoading || isReceiving"
                                       v-model="workOrderId" ref="scanWorkOrderInput">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="WORK_ORDER_ID" :errors="errors"/>
                                </div>

                                <div class="col-12" v-if="isLoading">
                                    <p>Loading work order {{ workOrderId }}...</p>
                                </div>
                            </div>
                        </form>

                        <template v-if="shouldChooseWorkOrder">
                            <label for="wo-input" class="label-title pb-0">
                                Work orders
                            </label>

                            <p class="ml-3 mb-4">
                                Multiple work orders were found with reference "{{ workOrderId }}". Please select the one you want to receive.
                            </p>

                            <div class="row">
                                <div class="col-sm-6 col-lg-3" role="button" v-for="workOrder in workOrderOptions" :key="workOrder.ID">
                                    <div class="scan-manisfest-card">
                                        <div class="text-decoration-none" @click.prevent="selectWorkOrder(workOrder)">
                                            <div class="scan-manisfest-card-header orange">
                                                <h4>
                                                    {{ workOrder.REFERENCE }}
                                                </h4>

                                                <span class="badge">
                                                    {{ workOrder.assets.length }}
                                                </span>
                                            </div>

                                            <div class="scan-manisfest-card-body" ref="cardBody">
                                                <div class="py-2 border-bottom">
                                                    <span class="label">Status</span>

                                                    <span class="content">
                                                        {{ workOrder.STATUS | capitalize }}
                                                    </span>
                                                </div>

                                                <div class="py-2 border-bottom">
                                                    <span class="label">External reference</span>

                                                    <span class="content">
                                                        <template v-if="workOrder.EXTERNAL_REFERENCE">
                                                            {{ workOrder.EXTERNAL_REFERENCE }}
                                                        </template>

                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </span>
                                                </div>

                                                <div v-if="workOrder.MERCHANT_ACCOUNT_NAME" class="py-2 border-bottom">
                                                    <span class="label">Account</span>

                                                    <span class="content">
                                                        {{ workOrder.MERCHANT_ACCOUNT_NAME }}
                                                    </span>
                                                </div>

                                                <div v-if="workOrder.VENDOR_ENTITY_NAME" class="py-2 border-bottom">
                                                    <span class="label">Vendor entity</span>

                                                    <span class="content">
                                                        {{ workOrder.VENDOR_ENTITY_NAME }}
                                                    </span>
                                                </div>

                                                <div v-if="workOrder.SHIPMENT_REFERENCE" class="py-2 border-bottom">
                                                    <span class="label">Shipment</span>

                                                    <span class="content">
                                                        {{ workOrder.SHIPMENT_REFERENCE }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-else>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <form @submit.prevent="setAssetToReceive()">
                                        <div class="form-group">
                                            <label for="woa-input" class="label-title">
                                                Scan to receive an asset from the work order
                                            </label>

                                            <input type="text"
                                                   placeholder="Scan asset license plate"
                                                   class="form-control lg"
                                                   id="woa-input"
                                                   v-model="licensePlate"
                                                   ref="scanAssetInput"
                                                   :disabled="isLoading || isReceiving || !workOrder"
                                            >
                                        </div>

                                        <div class="row">
                                            <div class="col-12">
                                                <InputFieldError :errors="errors"/>
                                            </div>

                                            <div class="col-12" v-if="isReceiving">
                                                <p>
                                                    Receiving asset {{ licensePlate }}...
                                                </p>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="col-12 col-sm-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <template v-if="workOrder && assetToReceive">
                                                    <ContainerSelection v-model="selectedContainer"
                                                                        :errors="errors"
                                                                        :disabled="isLoading || isReceiving"
                                                                        :dispositionType="assetToReceiveDispositionType"
                                                                        :storageType="assetToReceiveStorageType"
                                                                        :merchantAccountId="workOrder.MERCHANT_ACCOUNT_ID"
                                                    />
                                                </template>

                                                <template v-else>
                                                    <select class="form-control md mt-5" :class="assetToReceive ? 'd-none' : 'd-block'" disabled>
                                                        <option value="">Scan an asset before you can select a container</option>
                                                    </select>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="row" v-if="workOrder">
                            <div class="col-12">
                                <table class="table-first-level mb-4">
                                    <thead>
                                        <tr>
                                            <th>Reference</th>
                                            <th>External reference</th>
                                            <th>Account</th>
                                            <th>Vendor entity</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td colspan="5">
                                                <table class="table-second-level">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {{ workOrder.REFERENCE }}
                                                            </th>

                                                            <th>
                                                                {{ workOrder.EXTERNAL_REFERENCE ?? '-' }}
                                                            </th>

                                                            <th>
                                                                {{ workOrder.MERCHANT_ACCOUNT_NAME }}
                                                            </th>

                                                            <th>
                                                                {{ workOrder.VENDOR_ENTITY_NAME }}
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" class="card-container">
                                                                <div class="dashboard-card-horizontal receive-returns">
                                                                    <div class="dashboard-card-horizontal-body">
                                                                        <div class="row">
                                                                            <div class="col-12 col-sm-6">
                                                                                <div class="list-left">
                                                                                    <h5>
                                                                                        <span>Expected</span>
                                                                                    </h5>

                                                                                    <ul>
                                                                                        <template v-if="!expectedAssets || Object.keys(expectedAssets).length === 0">
                                                                                            <li>
                                                                                                There are no expected assets.
                                                                                            </li>
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            <li v-for="asset in expectedAssets" v-bind:key="asset.ID">
                                                                                                <div class="dashboard-card-horizontal-left">
                                                                                                    <span>
                                                                                                        {{ asset.LICENSE_PLATE }}
                                                                                                    </span>

                                                                                                    <span v-if="asset.SKU" class="external-reference ml-3">
                                                                                                        {{ asset.SKU }}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </li>
                                                                                        </template>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-12 col-sm-6">
                                                                                <div class="list-right">
                                                                                    <h5>
                                                                                        <span>On hand</span>
                                                                                    </h5>

                                                                                    <ul>
                                                                                        <template v-if="!onHandAssets || Object.keys(onHandAssets).length === 0">
                                                                                            <li>
                                                                                                There are no assets on hand.
                                                                                            </li>
                                                                                        </template>

                                                                                        <template v-else>
                                                                                            <li v-for="asset in onHandAssets" v-bind:key="asset.ID">
                                                                                                <div class="dashboard-card-horizontal-left">
                                                                                                    <span>
                                                                                                        {{ asset.LICENSE_PLATE }}
                                                                                                    </span>

                                                                                                    <span v-if="asset.SKU" class="external-reference ml-3">
                                                                                                        {{ asset.SKU }}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </li>
                                                                                        </template>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" @click="previous()">
                                Back
                            </button>

                            <InputFieldError attribute="close" :errors="errors"/>

                            <button type="button" class="btn btn-primary" :disabled="isClosing" @click.prevent="showConfirmationModal = true" v-if="workOrderIsReceived">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <ConfirmationPopup v-if="showConfirmationModal"
                           :message="'Are you sure you want to close work order ' + workOrder.REFERENCE + '?'"
                           @deny="showConfirmationModal = false"
                           @confirm="closeWorkOrder()"
        />
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ContainerSelection from '@/components/_ContainerSelection.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ContainerSelection': ContainerSelection,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                isLoading: false,
                isReceiving: false,
                isClosing: false,
                shouldChooseWorkOrder: false,
                workOrderOptions: [],
                errors: {},
                workOrder: null,
                workOrderId: null,
                licensePlate: null,
                assetToReceive: null,
                selectedContainer: null,
                showConfirmationModal: false,
                workOrderIsReceived: false,
            };
        },
        mounted() {
            this.$refs.scanWorkOrderInput.focus();
        },
        methods: {
            scanWorkOrder() {
                this.errors = {};
                this.workOrder = null;
                this.licensePlate = null;
                this.assetToReceive = null;
                this.selectedContainer = null;

                if (!this.workOrderId || /^\s*$/.test(this.workOrderId)) {
                    this.errors = {WORK_ORDER_ID: 'Enter a work order ID.'};
                    return false;
                }

                this.isLoading = true;

                let url = new URL(this.$store.state.baseUrl + 'work-order/get-shipped-work-order');
                url.searchParams.append('id', this.workOrderId);

                this.$http.get(url).then((data) => {
                    this.isLoading = false;
                    this.errors = {};

                    if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    } else if (data?.data?.code === 300) {
                        this.shouldChooseWorkOrder = true;
                        this.workOrderOptions = data.data.data;
                    } else if (data?.data?.code === 100) {
                        this.workOrder = data?.data?.data;

                        this.$nextTick(() => {
                            this.$refs.scanAssetInput.focus();
                        });
                    }
                });
            },
            selectWorkOrder(workOrder) {
                this.shouldChooseWorkOrder = false;
                this.workOrderOptions = [];
                this.workOrder = workOrder;

                this.$nextTick(() => {
                    this.$refs.scanAssetInput.focus();
                });
            },
            setAssetToReceive() {
                this.errors = {};

                this.assetToReceive = this.workOrder.assets.find(e => e.LICENSE_PLATE === this.licensePlate);
                this.assetToReceive.CONTAINER_ID = null;
                this.selectedContainer = null;

                if (!this.assetToReceive) {
                    this.errors = 'Asset not found.';
                    return false;
                }

                if (this.assetToReceive?.IS_EXPECTED === 0 || this.assetToReceive?.IS_EXPECTED === '0') {
                    this.errors = 'Only expected assets can be received.';
                    this.assetToReceive = null;
                }
            },
            receiveAsset() {
                this.errors = {};

                if (!this.licensePlate || /^\s*$/.test(this.licensePlate)) {
                    this.errors = {workOrderId: 'Enter an asset ID!'};
                    return false;
                }

                const asset = this.workOrder.assets.find(e => e.LICENSE_PLATE === this.licensePlate);

                if (!asset) {
                    this.errors = {LICENSE_PLATE: 'Unable to scan asset with license plate "' + this.licensePlate + '"'};
                    return false;
                }

                this.isReceiving = true;

                const url = new URL(this.$store.state.baseUrl + 'work-order/receive-work-order');
                let params = new URLSearchParams();
                params.append('WorkOrderReceive[ID]', this.workOrder.ID);
                params.append('WorkOrderReceive[LICENSE_PLATE]', this.licensePlate);
                params.append('WorkOrderReceive[CONTAINER_ID]', this.selectedContainer?.ID);

                this.$http.post(url, params).then((data) => {
                    this.isReceiving = false;

                    if (data?.data?.code === 100) {
                        if (this.selectedContainer?.IS_FULL === '1') {
                            this.setContainerFull(this.selectedContainer.ID);
                        }

                        asset.IS_EXPECTED = 0;
                        this.licensePlate = null;
                        this.assetToReceive = null;
                        this.selectedContainer = null;

                        if (data?.data?.status === 'received') {
                            this.workOrderIsReceived = true;
                        }
                    } else if (data?.data?.code === 422 && data.data.errors) {
                        this.errors = data.data.errors;
                    } else {
                        this.errors = {ASSET_ID: 'Something went wrong while receiving asset.'};
                    }
                });
            },
            setContainerFull(containerId) {
                this.isSavingContainer = true;
                let url = new URL(this.$store.state.baseUrl + 'containers/' + containerId);
                let params = new URLSearchParams();
                params.append('IS_FULL', 1);

                this.$http.put(url.href, params).then((data) => {
                    this.isSavingContainer = false;

                    if (data.status !== 200) {
                        this.componentErrors = {global: 'Unable to mark container as full.'};
                    }
                });
            },
            previous() {
                this.$router.push({
                    name: 'Inbound',
                });
            },
            closeWorkOrder() {
                this.errors = {};
                this.isClosing = true;
                this.showConfirmationModal = false;

                const url = new URL(this.$store.state.baseUrl + 'work-order/close');
                url.searchParams.append('id', this.workOrder?.ID);

                this.$http.post(url.href).then((data) => {
                    this.isClosing = false;

                    if (data?.data?.code === 100) {
                        this.getWorkOrderPdf(this.workOrder?.ID);
                        this.workOrderId = null;
                    } else if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    }
                });
            },
            getWorkOrderPdf(id) {
                const url = new URL(this.$store.state.baseUrl + 'pdf/work-order');
                url.searchParams.append('id', id);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    const documentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(documentBlob));
                });
            }
        },
        computed: {
            expectedAssets() {
                return !this.workOrder || !this.workOrder.assets ?
                    [] :
                    this.workOrder.assets.filter(
                        (asset) => asset.IS_EXPECTED == 1 && asset.IS_ON_HAND == 1 && asset.IS_ARCHIVED == 0
                    );
            },
            onHandAssets() {
                return !this.workOrder || !this.workOrder.assets ?
                    [] :
                    this.workOrder.assets.filter(
                        (asset) => asset.IS_EXPECTED == 0 && asset.IS_ON_HAND == 1 && asset.IS_ARCHIVED == 0
                    );
            },
            assetToReceiveDispositionType() {
                return this.assetToReceive?.DISPOSITION_TYPE;
            },
            assetToReceiveStorageType() {
                return this.assetToReceive?.STORAGE_TYPE;
            }
        },
        watch: {
            workOrderId() {
                /* Unset this.workOrder to force the operator to load the new work order after changing the work order reference. */
                if (this.workOrder) {
                    this.workOrder = null;
                    this.assetToReceive = null;
                    this.selectedContainer = null;
                    this.licensePlate = null;
                    this.errors = {};
                    this.workOrderIsReceived = false;
                }

                this.shouldChooseWorkOrder = false;
                this.workOrderOptions = [];
            },
            selectedContainer(selectedContainer) {
                if (selectedContainer && !this.isReceiving) {
                    this.receiveAsset();
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped>
    .scrollable-content {
        padding: 30px 0;
    }
</style>
