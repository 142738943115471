<template>
    <div>
        <HeaderBar></HeaderBar>

        <main class="main">
            <template v-if="isLoading">
                <div class="container mt-5">
                    Loading data...
                </div>
            </template>

            <form v-else>
                <div class="scrollable-area">
                    <div class="scrollable-area--header">
                        <div class="my-5" v-if="!manifestId"></div>

                        <div class="container" v-else>
                            <div class="col-12">
                                <div class="scrollable-area--subtitle">
                                    You're working on manifest
                                </div>

                                <div class="scrollable-area--title">
                                    {{ manifestCode }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="scrollable-content">
                        <div class="container">
                            <h3 class="section-title-sm mb-4">
                                Register unspecified return
                            </h3>

                            <template v-if="isSaving">
                                <div class="container mt-5">
                                    Registering return...
                                </div>
                            </template>

                            <template v-else>
                                <div class="vertical-sets">
                                    <div class="vertical-sets-left"></div>

                                    <div class="vertical-sets-right">
                                        <div class="form-group">
                                            <label class="label">
                                                Tracking code
                                            </label>

                                            <input type="text" class="form-control" v-model="returnReference" placeholder="Enter tracking code">

                                            <InputFieldError attribute="returnReference" :errors="errors"/>
                                        </div>

                                        <div class="form-group">
                                            <label class="label">
                                                Account
                                            </label>

                                            <div v-if="isLoadingMerchantAccounts">
                                                Loading merchant accounts...
                                            </div>

                                            <div v-else-if="merchantAccounts.length === 0">
                                                No merchant accounts found.
                                            </div>

                                            <model-list-select
                                                    :list="merchantAccounts"
                                                    option-value="id"
                                                    option-text="name"
                                                    v-model="selectedAccountId"
                                                    placeholder="Select account"
                                                    required
                                                    v-else
                                            >
                                            </model-list-select>

                                            <InputFieldError attribute="selectedAccountId" :errors="errors"/>
                                        </div>

                                        <div class="form-group">
                                            <InputFieldError attribute="global" :errors="errors"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div><!-- container -->
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back to overview
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="next()" v-if="!isLoading" :disabled="isSaving">
                                    Register
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->
            </form>

        </main>

        <ConfirmationPopup v-if="showConfirmationModal"
                           :message="confirmationModalText"
                           @deny="showConfirmationModal = false" @confirm="next(true)"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                flow: this.$route.params.flow,
                returnReference: this.$route.query.returnReference,
                manifestId: this.$route.query.manifestId,
                manifestCode: this.$route.query.manifestCode,
                manifestIsReadyToShip: this.$route.query.readyToShip,
                errors: {},
                isLoading: false,
                isLoadingMerchantAccounts: false,
                isLoadingSelectedLocation: false,
                isSaving: false,
                selectedAccountId: null,
                merchantAccounts: [],
                showConfirmationModal: false,
                confirmationModalText: null,
            };
        },
        created() {
            this.getSelectedLocation();
            this.getMerchantAccounts();
        },
        methods: {
            previous() {
                if (this.flow === 'receiveAndGrade') {
                    this.$router.push({
                        name: 'ReceiveReturn'
                    });
                } else if (this.flow === 'manifest') {
                    if (this.manifestId) {
                        this.$router.push({
                            name: 'ReceiveToManifest',
                            query: {manifestId: this.manifestId}
                        });
                    } else {
                        this.$router.push({
                            name: 'SortToManifest'
                        });
                    }
                }
            },
            next() {
                this.errors = {};
                this.showConfirmationModal = false;
                this.isSaving = true;

                if (!this.returnReference) {
                    this.errors.returnReference = 'Return reference is required';
                }

                if (!this.selectedAccountId) {
                    this.errors.selectedAccountId = 'Account is required';
                }

                if (Object.keys(this.errors).length > 0) {
                    this.isSaving = false;
                    return false;
                }

                let selectedAccountObject = this.merchantAccounts.filter(account => {
                    return account.id === this.selectedAccountId
                });

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/register-unspecified-return');
                let params = new URLSearchParams();
                params.append('ReturnManifest[flow]', this.flow);
                params.append('ReturnManifest[RETURN_REFERENCE]', this.returnReference);
                params.append('ReturnManifest[MERCHANT_ACCOUNT_ID]', this.selectedAccountId);
                params.append('ReturnManifest[MERCHANT_ACCOUNT_NAME]', selectedAccountObject[0].name);
                params.append('ReturnManifest[MERCHANT_ACCOUNT_ORIGIN]', selectedAccountObject[0].origin);

                if (this.manifestId) {
                    params.append('ReturnManifest[MANIFEST_ID]', this.manifestId);
                    params.append('ReturnManifest[MANIFEST_IS_READY_TO_SHIP]', this.manifestIsReadyToShip);
                }

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    /*
                     * Response code 422:
                     * - Show the error message.
                     *
                     * Response code 303:
                     * - Route 4: the unspecified return has been registered, redirect the operator to the RegisterUnspecifiedReturnProducts page.
                     *
                     * Response code 100:
                     * - Route 3: the unspecified return has been registered.
                     */

                    if (data.data.code === 422) {
                        this.errors = {global: data.data.errors};
                    } else if (data.data.code === 303) {
                        this.$router.push({
                            name: 'RegisterUnspecifiedReturnProducts',
                            query: {returnPk: data.data.returnPk, returnReference: this.returnReference, manifestId: this.manifestId, manifestCode: this.manifestCode, readyToShip: this.manifestIsReadyToShip}
                        });
                    } else if (data.data.code === 100) {
                        if (this.flow === 'receiveAndGrade') {
                            return this.$router.push({
                                name: 'GradeUnspecifiedReturnProducts',
                                query: {returnPk: data.data.returnPk, returnReference: this.returnReference, merchantId: this.selectedAccountId}
                            });
                        }

                        /* Success
                         * If a manifest ID is known (Unsorted Manifest), add the return to the manifest. Redirect the operator to the ReceiveToManifest page.
                         * If there is no manifest ID (Sorted Manifest), redirect the operator to the SortToManifest page. */
                        if (this.manifestId) {
                            this.$store.commit('manifests/addReturnToManifest', [this.manifestCode, this.returnReference]);

                            if (this.manifestIsReadyToShip && (this.manifestIsReadyToShip === true || this.manifestIsReadyToShip === 'true')) {
                                this.$store.commit('manifests/setManifestReadyToShip', this.manifestCode);

                                this.$router.push({
                                    name: 'ReceiveToManifest'
                                });
                            } else {
                                this.$router.push({
                                    name: 'ReceiveToManifest',
                                    query: {manifestId: this.manifestId}
                                });
                            }
                        } else {
                            this.$router.push({
                                name: 'SortToManifest',
                                query: {returnReference: this.returnReference, preload: true}
                            });
                        }
                    }
                });
            },
            getSelectedLocation() {
                this.isLoadingSelectedLocation = true;

                this.$store.dispatch('userSettings/getSelectedLocation', module).then(() => {
                    this.isLoadingSelectedLocation = false;
                });
            },
            getMerchantAccounts() {
                this.isLoadingMerchantAccounts = true;

                this.$store.dispatch('merchantAccounts/getMerchantAccounts').then((data) => {
                    this.isLoadingMerchantAccounts = false;

                    if (data) {
                        for (let i in data) {
                            this.merchantAccounts.push({
                                id: data[i].RTN_ACT_ID,
                                origin: 1,
                                name: data[i].RTN_ACT_NAME
                            });
                        }
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .section-title-sm {
        padding-left: 14px;
    }

    .scrollable-area--title {
        font-size: 18px;
        font-weight: 600;
    }

    .scrollable-area--subtitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(29, 38, 64, .44);
    }

    .scrollable-content {
        padding-top: 50px;
        height: calc(100vh - 180px);
    }

    .scrollable-area--header {
        padding-top: 50px;
    }
</style>
