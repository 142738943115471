<template>
    <tbody>
        <tr v-if="hasErrors">
            <td colspan="5" class="pb-0">
                <InputFieldError attribute="shipment" :errors="allErrors"></InputFieldError>
            </td>
        </tr>

        <tr>
            <td colspan="5" class="py-0">
                <template v-if="Object.keys(workOrders).length === 0">
                    <button type="button" class="btn btn-danger sm mt-4" @click.prevent="isShowingDeleteShipmentModal = true">
                        Delete
                    </button>

                    <p class="pt-4 mb-0">
                        There are no work orders on this shipment.
                    </p>
                </template>

                <template v-else>
                    <table class="table-third-level">
                        <thead>
                            <tr>
                                <th class="w-auto">
                                    Reference
                                </th>

                                <th class="w-auto">
                                    External reference
                                </th>

                                <th class="w-auto">
                                    Merchant
                                </th>

                                <th class="w-auto">
                                    Vendor
                                </th>

                                <th class="w-auto">
                                    Assets
                                </th>

                                <th class="w-auto"></th>

                                <th class="w-auto"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="workOrder in workOrders" :key="workOrder.ID">
                                <template v-if="workOrdersBeingRemoved.includes(workOrder.ID)">
                                    <td colspan="7" class="w-auto">
                                        Removing work order {{ workOrder.REFERENCE }} from this shipment...
                                    </td>
                                </template>

                                <template v-else>
                                    <td class="w-auto">
                                        {{ workOrder.REFERENCE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ workOrder.EXTERNAL_REFERENCE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ workOrder.MERCHANT_ACCOUNT_NAME }}
                                    </td>

                                    <td class="w-auto">
                                        {{ workOrder.VENDOR_ENTITY_NAME }}
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="workOrder.assets">
                                            {{ workOrder.assets.length }}
                                        </template>

                                        <template v-else>
                                            -
                                        </template>
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="documentsBeingGenerated && documentsBeingGenerated.includes(workOrder.ID)">
                                            Generating work order document...
                                        </template>

                                        <template v-else>
                                            <a href="#" @click.prevent="getWorkOrderPdf(workOrder.ID)">
                                                Work order document
                                            </a>
                                        </template>
                                    </td>

                                    <td class="w-auto text-right">
                                        <a href="#" @click.prevent="showRemoveWorkOrderFromShipmentModal(workOrder)" class="action-btn">
                                            &times;
                                        </a>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </td>
        </tr>

        <tr>
            <td colspan="5" class="pb-0">
                <p class="m-0 action-link" v-b-toggle="'available-models-to-add-dropdown'">
                    <span class="when-open">
                        Hide available work orders
                    </span>

                    <span class="when-closed">
                        Add work order
                    </span>
                </p>
            </td>
        </tr>

        <tr>
            <td colspan="5">
                <b-collapse id="available-models-to-add-dropdown">
                    <template v-if="isLoadingWorkOrdersReadyToShip">
                        Loading available work orders...
                    </template>

                    <template v-else-if="filteredWorkOrdersReadyToShip.length === 0">
                        There are no available work orders to add to this shipment.
                    </template>

                    <template v-else-if="isAddingWorkOrders">
                        Adding work orders to this shipment...
                    </template>

                    <template v-else>
                        <table class="table-third-level">
                            <thead>
                                <tr>
                                    <th class="w-20 pr-0"></th>

                                    <th class="w-auto">
                                        Reference
                                    </th>

                                    <th class="w-auto">
                                        External reference
                                    </th>

                                    <th class="w-auto">
                                        Merchant
                                    </th>

                                    <th class="w-auto">
                                        Vendor
                                    </th>

                                    <th class="w-auto">
                                        Assets
                                    </th>

                                    <th class="w-auto"></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="workOrder in filteredWorkOrdersReadyToShip" :key="workOrder.ID">
                                    <td class="w-20 pr-0">
                                        <label class="checkbox-container mb-20 mr-1">
                                            <input type="checkbox" v-model="selectedWorkOrdersToShip[workOrder.ID]" :disabled="isAddingWorkOrders">
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>

                                    <td class="w-auto">
                                        {{ workOrder.REFERENCE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ workOrder.EXTERNAL_REFERENCE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ workOrder.MERCHANT_ACCOUNT_NAME }}
                                    </td>

                                    <td class="w-auto">
                                        {{ workOrder.VENDOR_ENTITY_NAME }}
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="workOrder.assets">
                                            {{ workOrder.assets.length }}
                                        </template>

                                        <template v-else>
                                            -
                                        </template>
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="documentsBeingGenerated && documentsBeingGenerated.includes(workOrder.ID)">
                                            Generating work order document...
                                        </template>

                                        <template v-else>
                                            <a href="#" @click.prevent="getWorkOrderPdf(workOrder.ID)">
                                                Work order document
                                            </a>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button class="btn btn-primary btn-md mt-3" :disabled="selectedWorkOrders.length === 0" @click.prevent="addWorkOrdersToShipment()">
                            Add work orders
                        </button>
                    </template>
                </b-collapse>
            </td>
        </tr>

        <ConfirmationPopup v-if="isShowingRemoveWorkOrderFromShipmentModal"
                           :message="'Are you sure you want to remove work order ' + workOrderToRemove.REFERENCE + ' from this shipment?'"
                           @confirm="removeWorkOrderFromShipment()"
                           @deny="isShowingRemoveWorkOrderFromShipmentModal = false">
        </ConfirmationPopup>

        <ConfirmationPopup v-if="isShowingDeleteShipmentModal"
                           :message="'Are you sure you want to delete shipment ' + shipment.REFERENCE + '?'"
                           :loadingMessage="'Deleting shipment ' + shipment.REFERENCE + '...'"
                           :isLoading="isDeletingShipment"
                           @confirm="deleteShipment()"
                           @deny="isShowingDeleteShipmentModal = false">
        </ConfirmationPopup>
    </tbody>
</template>

<script>
    import {BCollapse, VBToggle} from 'bootstrap-vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        props: [
            'shipment',
            'errors'
        ],
        components: {
            'b-collapse': BCollapse,
            'ConfirmationPopup': ConfirmationPopup,
            'InputFieldError': InputFieldError,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                localErrors: {},
                isShowingDeleteShipmentModal: false,
                isDeletingShipment: false,
                isShowingRemoveWorkOrderFromShipmentModal: false,
                workOrderToRemove: null,
                workOrdersBeingRemoved: [],
                isAddingWorkOrders: false,
                workOrdersReadyToShip: [],
                isLoadingWorkOrdersReadyToShip: false,
                documentsBeingGenerated: [],
                workOrders: [],
                selectedWorkOrdersToShip: {},
            };
        },
        created() {
            this.workOrders = this.shipment.workOrders;
            this.getWorkOrdersReadyToShip();
        },
        methods: {
            previous() {
                if (this.$route.query.origin && this.$route.query.origin === 'workOrder') {
                    this.$router.push({
                        name: 'ShipWorkOrder',
                        query: {
                            merchant: this.$route.query.merchant
                        }
                    });
                    return;
                }

                this.$router.push({
                    name: 'ShipmentOverview',
                });
            },
            getWorkOrderPdf(workOrderId) {
                this.localErrors = {};
                this.documentsBeingGenerated.push(workOrderId);

                const url = new URL(this.$store.state.baseUrl + 'pdf/work-order');
                url.searchParams.append('id', workOrderId);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    let index = this.documentsBeingGenerated.indexOf(workOrderId);
                    this.documentsBeingGenerated.splice(index, 1);

                    let documentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(documentBlob));
                });
            },
            removeWorkOrderFromShipment() {
                this.localErrors = {};
                this.isShowingRemoveWorkOrderFromShipmentModal = false;
                this.workOrdersBeingRemoved.push(this.workOrderToRemove.ID.toString());

                const url = new URL(this.$store.state.baseUrl + 'shipments/remove-work-order-from-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[WorkOrderID]', this.workOrderToRemove.ID);
                params.append('Shipment[ShipmentID]', this.shipment.ID);

                this.$http.post(url.href, params).then((data) => {
                    if (data?.data?.code === 100) {
                        const index = this.workOrders.findIndex((el) => el.ID === this.workOrderToRemove.ID.toString());

                        if (index > -1) {
                            this.workOrders.splice(index, 1);
                        }

                        this.getWorkOrdersReadyToShip();
                    } else if (data?.data?.code === 422) {
                        this.localErrors = {shipment: data.data.errors};
                    }

                    let index = this.workOrdersBeingRemoved.indexOf(this.workOrderToRemove.ID);
                    this.workOrdersBeingRemoved.splice(index, 1);
                    this.workOrderToRemove = null;
                });
            },
            showRemoveWorkOrderFromShipmentModal(workOrder) {
                this.workOrderToRemove = workOrder;
                this.isShowingRemoveWorkOrderFromShipmentModal = true;
            },
            getWorkOrdersReadyToShip() {
                this.isLoadingWorkOrdersReadyToShip = true;
                const url = new URL(this.$store.state.baseUrl + 'work-order/get-all-by-vendor-entity');
                url.searchParams.set('vendorEntityId', this.shipment.DESTINATION_VENDOR_ENTITY_ID);

                this.workOrdersReadyToShip = null;

                this.$http.get(url.href).then((data) => {
                    this.isLoadingWorkOrdersReadyToShip = false;
                    this.workOrdersReadyToShip = data.data;
                });
            },
            addWorkOrdersToShipment() {
                this.isAddingWorkOrders = true;

                let selectedWorkOrderIds = [];

                for (let i in this.selectedWorkOrders) {
                    selectedWorkOrderIds.push(this.selectedWorkOrders[i].ID);
                }

                const url = new URL(this.$store.state.baseUrl + 'shipments/add-work-orders-to-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[ID]', this.shipment.ID);
                params.append('Shipment[WorkOrderIds]', selectedWorkOrderIds);

                this.$http.post(url.href, params).then((data) => {
                    this.isAddingWorkOrders = false;

                    if (data.data.code === 100) {
                        for (let i in this.selectedWorkOrders) {
                            this.workOrders.push(this.selectedWorkOrders[i]);
                        }

                        this.selectedWorkOrdersToShip = [];
                        this.getWorkOrdersReadyToShip();
                    } else if (data.data.code === 422) {
                        this.localErrors = {shipment: data.data.errors};
                    }
                });
            },
            deleteShipment() {
                this.localErrors = {};
                this.isDeletingShipment = true;

                const url = new URL(this.$store.state.baseUrl + 'shipments/delete-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[ID]', this.shipment.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeletingShipment = false;
                    this.isShowingDeleteShipmentModal = false;

                    if (data.data.code === 100) {
                        this.previous();
                    } else if (data.data.code === 422) {
                        this.localErrors = {shipment: data.data.errors};
                    }
                });
            },
        },
        computed: {
            hasErrors() {
                return this.errors && Object.keys(this.errors).length > 0;
            },
            workOrderArray() {
                if (!this.workOrdersReadyToShip) {
                    return [];
                }

                let result = [];

                for (let i in this.workOrdersReadyToShip) {
                    const workOrder = this.workOrdersReadyToShip[i];

                    if (workOrder.MERCHANT_ACCOUNT_ID !== this.shipment.MERCHANT_ACCOUNT_ID) {
                        continue;
                    }

                    result.push(workOrder);
                }

                return result;
            },
            filteredWorkOrdersReadyToShip() {
                if (!this.workOrderArray || this.workOrderArray.length === 0) {
                    return [];
                }

                let result = [];

                for (let i in this.workOrderArray) {
                    const workOrder = this.workOrderArray[i];

                    if (this.showSelectedWorkOrdersOnly && !this.selectedWorkOrders.includes(workOrder)) {
                        continue;
                    }

                    result.push(workOrder);
                }

                return result;
            },
            selectedWorkOrders() {
                if (!this.selectedWorkOrdersToShip || Object.keys(this.selectedWorkOrdersToShip).length === 0) {
                    return [];
                }

                let result = [];

                for (let i = 0; i < Object.keys(this.selectedWorkOrdersToShip).length; i++) {
                    const key = Object.keys(this.selectedWorkOrdersToShip)[i];
                    const value = Object.values(this.selectedWorkOrdersToShip)[i];

                    if (value) {
                        const workOrder = this.workOrdersReadyToShip.find(workOrder => workOrder.ID == key);

                        if (workOrder) {
                            result.push(workOrder);
                        }
                    }
                }

                return result;
            },
            allErrors() {
                return {...this.errors, ...this.localErrors};
            }
        }
    }
</script>

<style scoped>
    .action-btn {
        padding: 3px 9px;
        border: 1px solid #4a90e2;
        text-decoration: none;
        border-radius: 5px;
    }

    .action-btn:hover {
        color: #fff;
        background-color: #4a90e2;
    }

    .collapsed > .when-open, .not-collapsed > .when-closed {
        display: none;
    }

    .action-link {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }

    .action-link:hover {
        color: #0056b3;
        text-decoration: underline;
    }
</style>
