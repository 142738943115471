<template>
    <div>
        <HeaderBar title="User overview"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="Connection" activeItem="Overview"></SettingsMenu>

                <div class="col-10">
                    <div v-if="isLoadingConnection">
                        Loading connection...
                    </div>

                    <div v-else-if="isRejectingConnectionRequest">
                        Rejecting connection request...
                    </div>

                    <div v-else-if="isApprovingConnectionRequest">
                        Approving connection request...
                    </div>

                    <div v-else-if="!connectionRequest">
                        No connection data available.
                    </div>

                    <template v-else>
                        <div class="form-group my-4">
                            <label class="label">
                                Return Bird account name
                            </label>

                            <p>
                                {{ connectionRequest.RETURNBIRD_ACCOUNT_NAME}}
                            </p>
                        </div>

                        <div class="form-group my-4">
                            <label class="label">
                                Connection status
                            </label>

                            <p class="status" :class="connectionRequest.STATUS">
                                {{ connectionRequest.STATUS | capitalize }}
                            </p>
                        </div>

                        <template v-if="connectionRequest.STATUS === 'requested'">
                            <table class="w-20">
                                <tr>
                                    <td class="pr-3">
                                        <button class="btn btn-success" @click.prevent="showApproveConnectionRequestModal(true)">
                                            Approve
                                        </button>
                                    </td>

                                    <td>
                                        <button class="btn btn-danger" @click.prevent="showRejectConnectionRequestModal(true)">
                                            Reject
                                        </button>
                                    </td>
                                </tr>
                            </table>

                        </template>

                        <template v-else-if="connectionRequest.STATUS === 'approved'">
                            <div class="form-group my-4">
                                <label class="label">
                                    Location access
                                </label>

                                <p v-if="isLoadingAccessToLocations">
                                    Loading locations...
                                </p>

                                <p v-else-if="!accessToLocations">
                                    This Return Bird account does not have access to any locations.
                                </p>

                                <ul v-else>
                                    <li v-for="(accessToLocation, key) in accessToLocations" :key="key">
                                        {{ accessToLocation.location.NAME }}
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </template>

                    <InputFieldError attribute="global" :errors="errors"/>
                </div>
            </div>
        </main>

        <ConfirmationPopup v-if="showRejectConnectionPopup"
                           message="Are you sure you want to reject this connection request?"
                           @confirm="rejectConnectionRequest()"
                           @deny="showRejectConnectionRequestModal(false)"></ConfirmationPopup>

        <div class="confirmation-overlay" v-if="showApproveConnectionPopup">
            <div class="overlay-background"></div>

            <div class="modal-wrapper">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <h5 class="py-4 my-3">Select locations</h5>

                        <p>
                            Choose the locations to which {{ connectionRequest.RETURNBIRD_ACCOUNT_NAME }} will receive access.
                        </p>

                        <p v-if="isLoadingLocations">
                            Loading locations...
                        </p>

                        <template v-else>
                            <div class="row" v-for="(location, key) in $store.state.locations.locations" v-bind:key="key">
                                <div class="col-md-6 my-3">
                                    <label class="checkbox-container mr-3">
                                        <input type="checkbox" v-bind:id="location.ID" class="form-control" v-model="selectedLocations[key]">

                                        <span class="checkmark"></span>

                                        {{ location.NAME }}
                                    </label>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click.prevent="showApproveConnectionRequestModal(false)">
                            Cancel
                        </button>

                        <button type="button" class="btn btn-success" @click.prevent="approveConnectionRequest()">
                            Approve connection request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import _ from 'lodash';

    export default {
        name: 'ConnectionPage',
        components: {
            'HeaderBar': HeaderBar,
            'SettingsMenu': SettingsMenu,
            'ConfirmationPopup': ConfirmationPopup,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                isLoadingConnection: false,
                isLoadingAccessToLocations: false,
                isLoadingLocations: false,
                isRejectingConnectionRequest: false,
                isApprovingConnectionRequest: false,
                connectionRequest: null,
                connectionRequestId: this.$route.query.id,
                accessToLocations: null,
                showRejectConnectionPopup: false,
                showApproveConnectionPopup: false,
                errors: {},
                selectedLocations: {},
            };
        },
        created() {
            this.getConnection();
        },
        methods: {
            getConnection() {
                this.isLoadingConnection = true;

                let url = new URL(this.$store.state.baseUrl + 'connection-requests/get-by-id');
                url.searchParams.set('id', this.connectionRequestId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingConnection = false;

                    if (data.status === 200) {
                        this.connectionRequest = data.data;

                        if (this.connectionRequest.STATUS && this.connectionRequest.STATUS === 'requested') {
                            /* Retrieve the list of locations to which the Return Bird account can be granted access. */
                            this.isLoadingLocations = true;

                            this.$store.dispatch('locations/getAll').then(() => {
                                this.isLoadingLocations = false;
                            });
                        } else if (this.connectionRequest.STATUS && this.connectionRequest.STATUS === 'approved') {
                            /* Retrieve the locations to which the Return Bird account has access. */
                            this.isLoadingAccessToLocations = true;

                            let locationAccessUrl = new URL(this.$store.state.baseUrl + 'locationaccesstoaccount/get-by-return-bird-account-id');
                            locationAccessUrl.searchParams.set('returnBirdAccountId', this.connectionRequest.RETURNBIRD_ACCOUNT_ID);

                            this.$http.get(locationAccessUrl.href).then((locationAccessData) => {
                                this.isLoadingAccessToLocations = false;

                                if (locationAccessData.status === 200) {
                                    this.accessToLocations = locationAccessData.data;
                                }
                            });
                        }
                    }
                });
            },
            approveConnectionRequest() {
                this.showApproveConnectionPopup = false;
                this.isApprovingConnectionRequest = true;

                let url = new URL(this.$store.state.baseUrl + 'connection-requests/approve-request');
                let params = new URLSearchParams();
                params.append('ConnectionRequest[ID]', this.connectionRequest.ID);

                if (this.selectedLocations) {
                    _.forOwn(this.selectedLocations, function (value, key) {
                        if (value !== null) {
                            params.append('ConnectionRequestLocations[' + key + ']', value);
                        }
                    });
                }

                this.$http.post(url.href, params).then((data) => {
                    this.isApprovingConnectionRequest = false;

                    if (data.data.code === 100) {
                        this.$router.push({
                            name: 'ConnectionOverview'
                        });
                    } else {
                        this.errors = {global: data.data.errors};
                    }
                });
            },
            rejectConnectionRequest() {
                this.showRejectConnectionPopup = false;
                this.isRejectingConnectionRequest = true;

                let url = new URL(this.$store.state.baseUrl + 'connection-requests/reject-request');
                let params = new URLSearchParams();
                params.append('ConnectionRequest[ID]', this.connectionRequest.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isRejectingConnectionRequest = false;

                    if (data.data.code === 100) {
                        this.$router.push({
                            name: 'ConnectionOverview'
                        });
                    } else {
                        this.errors = {global: data.data.errors};
                    }
                });
            },
            showRejectConnectionRequestModal(value) {
                this.errors = {};
                this.showRejectConnectionPopup = value;
            },
            showApproveConnectionRequestModal(value) {
                this.errors = {};
                this.showApproveConnectionPopup = value;
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style>
    .status.approved {
        color: #63e6b8;
    }

    .status.rejected {
        color: #e24b4a;
    }
</style>
