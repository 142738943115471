const moduleStore = {
    namespaced: true,
    state: {
        activityLog: []
    },
    mutations: {
        addReturnToManifest(state, [manifestCode, returnReference]) {
            const message = 'Return ' + returnReference + ' has been added to manifest ' + manifestCode;
            state.activityLog.push(message);
        },
        setManifestReadyToShip(state, manifestCode) {
            const message = 'Manifest ' + manifestCode + ' has been marked as ready to ship.';
            state.activityLog.push(message);
        },
        clear(state) {
            state.activityLog = [];
        }
    },
    actions: {

    }
};

export default moduleStore;
