<template>
    <div>
        <table class="table-third-level" v-if="workOrder.assets && Object.keys(workOrder.assets).length > 0">
            <thead>
                <tr>
                    <th class="w-auto">SKU</th>
                    <th class="w-auto">License plate</th>
                    <th class="w-auto">Storage type</th>
                    <th class="w-auto">Grade</th>
                    <th class="w-auto">Resolution</th>
                    <th class="w-auto">Revalued grade</th>
                    <th class="w-auto">Status</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(asset, key) in workOrder.assets" :key="key">
                    <td class="w-auto">
                        {{ asset.SKU }}
                    </td>

                    <td class="w-auto">
                        {{ asset.LICENSE_PLATE }}
                    </td>

                    <td class="w-auto">
                        {{ asset.STORAGE_TYPE }}
                    </td>

                    <td class="w-auto">
                        {{ asset.GRADE }}
                    </td>

                    <td class="w-auto">
                        {{ asset.VENDOR_RESOLUTION | capitalize }}
                    </td>

                    <td class="w-auto">
                        {{ asset.REWORKED_GRADE }}
                    </td>

                    <td class="wd-auto">
                        {{ getAssetStatus(asset) }}
                    </td>
                </tr>
            </tbody>
        </table>

        <p v-else>
            No assets added to this work order.
        </p>
    </div>
</template>

<script>
    export default {
        props: [
            'workOrder'
        ],
        components: {

        },
        data() {
            return {

            }
        },
        created() {

        },
        methods: {
            getAssetStatus(asset) {
                if (asset) {
                    const assetFlags = {
                        IS_ARCHIVED: 'Archived',
                        IS_EXPECTED: 'Expected',
                        IS_IN_PROGRESS: 'In progress',
                        IS_RESERVED: 'Reserved',
                        IS_ON_HAND: 'Available',
                    };

                    for (const [attribute, label] of Object.entries(assetFlags)) {
                        if (asset?.[attribute] === 1 || asset?.[attribute] === '1') {
                            return label;
                        }
                    }
                }

                return '-';
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        }
    }
</script>


<style>

</style>
