<template>
    <div>
        <HeaderBar></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="">
                <div class="scrollable-area">
                    <div class="scrollable-content no-footer">
                        <div class="container pb-5">
                            <h3 class="section-title-sm mb-4">
                                Receive and grade products for return {{ returnReference }}
                            </h3>

                            <template v-if="isSaving">
                                <div class="container mt-5">
                                    Completing return...
                                </div>
                            </template>

                            <template v-else>
                                <div class="vertical-sets">
                                    <div class="vertical-sets-left">
                                        <div class="form-group">
                                            <div class="label mb-3">
                                                Products received and graded
                                            </div>

                                            <template v-if="isLoadingProducts">
                                                Loading products...
                                            </template>

                                            <template v-else-if="productsOnReturn.length > 0">
                                                <table class="w-100">
                                                    <template v-for="(product, key) in productsOnReturn">
                                                        <tbody :key="key">
                                                            <template v-if="productsBeingDeleted && (productsBeingDeleted[product.ID] || productsBeingDeleted.indexOf(product.ID) != -1)">
                                                                <tr>
                                                                    <td colspan="4" class="py-3">
                                                                        <p class="py-3">
                                                                            Deleting {{ product.MODEL_NUMBER }}...
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                            </template>

                                                            <template v-else>
                                                                <tr>
                                                                    <td class="py-3">
                                                                        <label class="label">
                                                                            SKU
                                                                        </label>

                                                                        <p>
                                                                            {{ product.MODEL_NUMBER }}
                                                                        </p>
                                                                    </td>

                                                                    <td class="py-3">
                                                                        <label class="label">
                                                                            Received grade
                                                                        </label>

                                                                        <p>
                                                                            <template v-if="product.unspecifiedProductAsset && product.unspecifiedProductAsset.GRADE">
                                                                                {{ product.unspecifiedProductAsset.GRADE }}
                                                                            </template>

                                                                            <template v-else>
                                                                                -
                                                                            </template>
                                                                        </p>
                                                                    </td>

                                                                    <td class="py-3" v-if="receiveAndGradeFlow == 1">
                                                                        <label class="label">
                                                                            Container
                                                                        </label>

                                                                        <p>
                                                                            <template v-if="product.unspecifiedProductAsset && product.unspecifiedProductAsset.container && product.unspecifiedProductAsset.container.LICENSE_PLATE">
                                                                                {{ product.unspecifiedProductAsset.container.LICENSE_PLATE }}
                                                                            </template>

                                                                            <template v-else>
                                                                                -
                                                                            </template>
                                                                        </p>
                                                                    </td>

                                                                    <td class="text-right py-3">
                                                                        <span class="action-btn" @click.prevent="confirmDeleteProduct(product)">
                                                                            <i class="icon-bin"></i>

                                                                            <span>
                                                                                Delete
                                                                            </span>
                                                                        </span>
                                                                    </td>
                                                                </tr>

                                                                <tr class="py-3" v-if="product.unspecifiedProductAsset && product.unspecifiedProductAsset.fileModels">
                                                                    <td class="py-3" colspan="4">
                                                                        <ImageGallery :images="product.unspecifiedProductAsset.fileModels"
                                                                                      idAttribute="ID"
                                                                                      urlAttribute="URL"
                                                                                      class="sm">
                                                                        </ImageGallery>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </template>

                                            <template v-else>
                                                There are currently no products on this return.
                                            </template>
                                        </div>
                                    </div>

                                    <div class="vertical-sets-right">
                                        <template v-if="isSavingAsset">
                                            <p>
                                                Receiving product {{ productNumber }}...
                                            </p>
                                        </template>

                                        <template v-else>
                                            <div class="form-group">
                                                <template v-if="isGrading">
                                                    <label class="label mb-3">
                                                        Scan product to receive and grade
                                                    </label>

                                                    <input type="text" class="form-control" v-model="productNumber" placeholder="Scan product number" :disabled="isCreatingAsset || isSavingAsset" ref="productNumberInput">
                                                </template>

                                                <template v-else>
                                                    <label class="label mb-2">
                                                        Received product
                                                    </label>

                                                    <p>
                                                        {{ productNumber }}
                                                    </p>
                                                </template>

                                                <InputFieldError attribute="productNumber" :errors="errors"/>
                                            </div>

                                            <template v-if="isGrading && productNumber">
                                                <template v-if="isLoadingGradingSet">
                                                    <p>
                                                        Loading grading set...
                                                    </p>
                                                </template>

                                                <template v-else-if="grades">
                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <label class="label">
                                                                    Received grade
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class="row my-3 align-items-center light-grading--grades" v-for="(gradeInfo, grade) in grades" :key="grade">
                                                            <div class="col-12">
                                                                <button type="button" class="btn btn--grade" :class="'grade--' + grade" @click.prevent="setGrade(grade)">
                                                                    {{ grade }}
                                                                </button>

                                                                <span class="ml-4">
                                                                {{ gradeInfo.label }}
                                                            </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-else>
                                                    <p>
                                                        There is no grading set for unspecified products.
                                                    </p>
                                                </template>
                                            </template>

                                            <template v-else-if="isCreatingAsset">
                                                <p>
                                                    Saving grade...
                                                </p>
                                            </template>

                                            <template v-else-if="!isGrading">
                                                <div class="form-group" v-if="!isAddingPictures">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-8">
                                                            <span class="grading-result-title mb-4">
                                                                Received grade
                                                            </span>

                                                            <div class="row mt-5 pt-2">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label class="label">
                                                                            Disposition type
                                                                        </label>

                                                                        <p>
                                                                            {{ asset.dispositionType | capitalize }}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label class="label">
                                                                            Storage
                                                                        </label>

                                                                        <p>
                                                                            {{ asset.storageType }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 col-lg-4 text-center text-lg-right">
                                                            <span class="result m-3" :class="'grade--' + asset.grade">
                                                                <strong>
                                                                    {{ asset.grade }}
                                                                </strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Webcam model="Asset"
                                                        :modelPk="asset.id"
                                                        imageCountLimit="5"
                                                        fileUploadCategory="assetGrade"
                                                        @startedAddingPictures="isAddingPictures = true"
                                                        @stoppedAddingPictures="isAddingPictures = false"
                                                        :shouldStopAddingPictures="shouldStopAddingPictures"
                                                        ref="webcamComponent"
                                                >
                                                </Webcam>

                                                <template v-if="receiveAndGradeFlow == 1">
                                                    <ContainerSelection :class="isAddingPictures ? 'd-none' : 'd-block'"
                                                                        v-model="selectedContainer"
                                                                        :errors="errors"
                                                                        :disabled="isSaving || isSavingAsset"
                                                                        :dispositionType="asset.dispositionType"
                                                                        :storageType="asset.storageType"
                                                                        :merchantAccountId="merchantAccountId"
                                                    />
                                                </template>

                                                <InputFieldError attribute="global" :errors="errors"/>

                                                <div class="d-flex justify-content-end" v-if="!isAddingPictures">
                                                    <button type="button" class="btn btn-primary btn-md mt-5" @click.prevent="saveAsset()" :disabled="isSavingAsset">
                                                        Done
                                                    </button>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div><!-- container -->
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Exit return
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="showReceiveReturnConfirmationPopup = true" :disabled="isSaving || isSavingAsset">
                                    Complete
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->
            </form>

            <ConfirmationPopup v-if="showDeleteProductPopup"
                               :message="'Are you sure you want to delete product ' + productToDelete.MODEL_NUMBER + '?'"
                               @confirm="deleteProduct()"
                               @deny="showDeleteProductPopup = false"></ConfirmationPopup>

            <ConfirmationPopup v-if="showReceiveReturnConfirmationPopup"
                               :message="'Are you sure you want to finish receiving return ' + returnReference + '? This action cannot be reversed.'"
                               @confirm="processReturn()"
                               @deny="closeReceiveReturnConfirmationPopup()"></ConfirmationPopup>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import _ from 'lodash';
    import ContainerSelection from '@/components/_ContainerSelection.vue';
    import Webcam from '@/components/_Webcam.vue';
    import ImageGallery from '@/components/_ImageGallery.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
            'ContainerSelection': ContainerSelection,
            'Webcam': Webcam,
            'ImageGallery': ImageGallery,
        },
        data() {
            return {
                returnPk: this.$route.query.returnPk,
                returnReference: this.$route.query.returnReference,
                merchantAccountId: this.$route.query.merchantId,
                errors: {},
                isLoadingProducts: false,
                isLoadingGradingSet: false,
                isSaving: false,
                isCreatingAsset: false,
                isSavingAsset: false,
                isGrading: true, /* Default true to always start with the grading process. */
                isAddingPictures: false,
                grades: null,
                productNumber: null,
                asset: {
                    id: null,
                    dispositionType: null,
                    grade: null,
                    storageType: null,
                    productId: null,
                    productName: null,
                },
                selectedContainer: null,
                productsOnReturn: [],
                showDeleteProductPopup: false,
                showReceiveReturnConfirmationPopup: false,
                productToDelete: null,
                productsBeingDeleted: [],
                shouldStopAddingPictures: false,
                receiveAndGradeFlow: 1,
            };
        },
        created() {
            this.getAddedProducts();
            this.getGradingSet();
            this.getReceiveAndGradeFlow();
        },
        methods: {
            previous() {
                this.$router.push({
                    name: 'ReceiveReturn'
                });
            },
            next() {
                this.$router.push({
                    name: 'ReceiveReturn'
                });
            },
            createAsset() {
                if (!this.productNumber) {
                    this.errors.productNumber = 'Product number is required.';
                    return false;
                }

                this.isGrading = false;
                this.isCreatingAsset = true;

                let url = new URL(this.$store.state.baseUrl + 'products/register-product');
                let params = new URLSearchParams();
                params.append('Product[RETURN_PK]', this.returnPk);
                params.append('Product[PRODUCT_NUMBER]', this.productNumber);
                params.append('Asset[GRADE]', this.asset.grade);
                params.append('Asset[DISPOSITION_TYPE]', this.asset.dispositionType);

                this.$http.post(url.href, params).then((data) => {
                    this.isCreatingAsset = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else if (data.data.code === 100 && data.data.data.ASSET_ID) {
                        this.asset.id = data.data.data.ASSET_ID;
                        this.asset.productId = data.data.data.ID;
                        this.asset.productName = data.data.data.MODEL_NUMBER;
                    }
                });
            },
            saveAsset() {
                if (this.isAddingPictures) {
                    /* Close the Webcam component without continuing.
                     * This is done to prevent the operator from accidentally ending the Grading process too soon. */
                    this.shouldStopAddingPictures = true;

                    this.$nextTick(() => {
                        this.shouldStopAddingPictures = false;
                    });

                    return false;
                }

                if (this.receiveAndGradeFlow == 1 && !this.selectedContainer?.ID) {
                    this.errors.CONTAINER_ID = 'Container is required.';
                    return false;
                }

                let uploadedImages = [];

                if (this.$refs?.webcamComponent?.uploadedImages?.length > 0) {
                    uploadedImages = this.$refs.webcamComponent.uploadedImages;
                }

                this.isSavingAsset = true;

                const url = new URL(this.$store.state.baseUrl + 'assets/save');
                url.searchParams.set('assetId', this.asset.id);

                const params = new URLSearchParams();

                if (this.selectedContainer.ID) {
                    params.append('Asset[CONTAINER_ID]', this.selectedContainer.ID);
                }

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingAsset = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else if (data.data.code === 100) {
                        if (this.selectedContainer?.IS_FULL === '1') {
                            this.setContainerFull(this.selectedContainer.ID)
                        }

                        this.$nextTick(() => {
                            let unspecifiedProductAsset = data.data.data;

                            if (uploadedImages?.length > 0) {
                                unspecifiedProductAsset.fileModels = uploadedImages;
                            }

                            this.productsOnReturn.push({
                                ID: this.asset.productId,
                                MODEL_NUMBER: this.asset.productName,
                                unspecifiedProductAsset: data.data.data
                            });

                            this.asset = {
                                id: null,
                                dispositionType: null,
                                grade: null,
                                storageType: null,
                                productId: null,
                                productName: null,
                            };

                            this.selectedContainer = null;
                            this.productNumber = null;
                            this.isGrading = true;
                        });
                    }
                });
            },
            setContainerFull(containerId) {
                this.isSavingContainer = true;
                let url = new URL(this.$store.state.baseUrl + 'containers/' + containerId);
                let params = new URLSearchParams();
                params.append('IS_FULL', 1);

                this.$http.put(url.href, params).then((data) => {
                    this.isSavingContainer = false;

                    if (data.status !== 200) {
                        this.componentErrors = {global: 'Unable to mark container as full.'};
                    }
                });
            },
            validate() {
                this.errors = {};

                if (!this.productNumber) {
                    this.errors.productNumber = 'Product number is required.';
                }

                if (!this.selectedContainer.ID) {
                    this.errors.CONTAINER_ID = 'Container is required.';
                }

                return Object.keys(this.errors).length === 0;
            },
            confirmDeleteProduct(productToDelete) {
                this.productToDelete = productToDelete;
                this.showDeleteProductPopup = true;
            },
            deleteProduct() {
                this.showDeleteProductPopup = false;

                let url = new URL(this.$store.state.baseUrl + 'products/delete-product');
                let params = new URLSearchParams();
                params.append('Product[ID]', this.productToDelete.ID);

                this.productsBeingDeleted.push(this.productToDelete.ID.toString());

                this.$http.post(url.href, params).then((data) => {
                    if (data.data.code === 100) {
                        const key = _.findIndex(this.productsOnReturn, (product) => {
                            return product.ID === data.data.productId;
                        });

                        if (key !== -1) {
                            this.$delete(this.productsOnReturn, key);
                        }
                    }

                    let index = this.productsBeingDeleted.indexOf(this.productToDelete.ID);
                    this.productsBeingDeleted.splice(index, 1);
                    this.productToDelete = null;
                });

                this.$nextTick(() => {
                    this.$refs.productNumberInput?.focus();
                });
            },
            closeReceiveReturnConfirmationPopup() {
                this.showReceiveReturnConfirmationPopup = false;

                this.$nextTick(() => {
                    this.$refs.productNumberInput?.focus();
                });
            },
            getAddedProducts() {
                this.isLoadingProducts = true;

                let url = new URL(this.$store.state.baseUrl + 'products/get-products-on-unspecified-return');
                url.searchParams.append('returnPk', this.returnPk);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingProducts = false;

                    this.productsOnReturn = data.data.data;
                });

                this.$nextTick(() => {
                    this.$refs.productNumberInput?.focus();
                });
            },
            getGradingSet() {
                this.isLoadingGradingSet = true;

                const url = new URL(this.$store.state.baseUrl + 'assets/get-unspecified-return-grading-labels');
                url.searchParams.set('merchantAccountId', this.merchantAccountId);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingGradingSet = false;
                    this.grades = data.data;
                });
            },
            setGrade(grade) {
                this.asset.grade = grade;
                this.asset.dispositionType = this.grades[grade].dispositionType;
                this.asset.storageType = this.grades[grade].dispositionType === 'restock' ? 'AFS' : 'AFD';

                this.createAsset();
            },
            processReturn() {
                this.isSaving = true;
                this.showReceiveReturnConfirmationPopup = false;

                const url = new URL(this.$store.state.baseUrl + 'returns/process-unspecified-return');
                const params = new URLSearchParams();
                params.append('ReturnModel[ID]', this.returnPk);

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else if (data.data.code === 100) {
                        this.next();
                    }
                });
            },
            getReceiveAndGradeFlow() {
                const url = new URL(this.$store.state.baseUrl + 'location-merchant-settings/get-selected-receive-and-grade-flow');
                url.searchParams.append('accountId', this.merchantAccountId);

                this.$http.get(url.href).then((data) => {
                    if (data.data.code === 100) {
                        this.receiveAndGradeFlow = data.data.data;
                    }
                });
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .section-title-sm {
        padding-left: 14px;
    }

    .scrollable-area--title {
        font-size: 18px;
        font-weight: 600;
    }

    .scrollable-area--subtitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(29, 38, 64, .44);
    }

    .scrollable-content {
        padding-top: 50px;
        height: calc(100vh - 230px);
    }

    .scrollable-area--header {
        padding-top: 50px;
    }
</style>
