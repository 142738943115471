<template>
    <div>
        <HeaderBar title="Work order overview"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="scanWorkOrder()">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="text" placeholder="Scan work order" class="form-control lg"
                                               ref="scanWorkOrderInput" v-model="workOrderId">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="workOrderId" :errors="errors"/>
                                </div>

                                <div class="col-12" v-if="isSearchingWorkOrder">
                                    Loading work order {{ workOrderId }}...
                                </div>
                            </div>

                            <div class="scan-manisfest-container">
                                <template v-if="isLoadingWorkOrders">
                                    Loading work orders...
                                </template>

                                <template v-else-if="!workOrders || workOrders.length === 0">
                                    No work orders available.
                                </template>

                                <template v-else>
                                    <div class="row">
                                        <div class="col-md-4 pb-3">
                                            <div class="form-group pl-3">
                                                <label class="label">
                                                    Work order status
                                                </label>

                                                <v-select v-model="selectedWorkOrderStatusFilter"
                                                          :searchable="false"
                                                          :clearable="false"
                                                          :options="workOrderStatusFilterOptions"
                                                          :reduce="e => e.key"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-4 pb-3">
                                            <div class="form-group pl-3">
                                                <label class="label">
                                                    Vendor entity
                                                </label>

                                                <v-select v-model="selectedVendorEntityFilter"
                                                          :searchable="false"
                                                          :clearable="false"
                                                          :options="vendorEntityFilterOptions"
                                                          :reduce="e => e.key"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6 col-lg-3" v-for="workOrder in filteredWorkOrders" :key="workOrder.ID">
                                            <div class="scan-manisfest-card">
                                                <router-link class="text-decoration-none" :to="{name: 'WorkOrder', query: {id: workOrder.ID}}">
                                                    <div class="scan-manisfest-card-header" :class="[workOrder.STATUS === 'expected' ? 'orange' : '', workOrder.STATUS === 'shipped' ? 'red' : '', workOrder.STATUS === 'received' ? 'green' : '']">
                                                        <h4>
                                                            {{ workOrder.REFERENCE }}
                                                        </h4>

                                                        <span class="badge">
                                                            {{ workOrder.assets.length }}
                                                        </span>
                                                    </div>

                                                    <div class="scan-manisfest-card-body" ref="cardBody" :style="{'height': cardBodyHeight}">
                                                        <div class="py-2 border-bottom">
                                                            <span class="label">Status</span>

                                                            <span class="content">
                                                                {{ workOrder.STATUS | capitalize }}
                                                            </span>
                                                        </div>

                                                        <div class="py-2 border-bottom">
                                                            <span class="label">External reference</span>

                                                            <span class="content">
                                                                <template v-if="workOrder.EXTERNAL_REFERENCE">
                                                                    {{ workOrder.EXTERNAL_REFERENCE }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </span>
                                                        </div>

                                                        <div v-if="workOrder.MERCHANT_ACCOUNT_NAME" class="py-2 border-bottom">
                                                            <span class="label">Account</span>

                                                            <span class="content">
                                                                {{ workOrder.MERCHANT_ACCOUNT_NAME }}
                                                            </span>
                                                        </div>

                                                        <div v-if="workOrder.VENDOR_ENTITY_NAME" class="py-2 border-bottom">
                                                            <span class="label">Vendor entity</span>

                                                            <span class="content">
                                                                {{ workOrder.VENDOR_ENTITY_NAME }}
                                                            </span>
                                                        </div>

                                                        <div v-if="workOrder.SHIPMENT_REFERENCE" class="py-2 border-bottom">
                                                            <span class="label">Shipment</span>

                                                            <span class="content">
                                                                {{ workOrder.SHIPMENT_REFERENCE }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>
                                <button type="button" class="btn btn-primary" @click="createWorkOrder()">
                                    Create work order
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
        },
        data() {
            return {
                errors: {},
                isLoadingWorkOrders: false,
                isSearchingWorkOrder: false,
                workOrderId: null,
                workOrders: {},
                workOrderStatusFilterOptions: [
                    {key: 'all', label: 'All'},
                    {key: 'created', label: 'Created'},
                    {key: 'shipped', label: 'Shipped'},
                    {key: 'expected', label: 'Expected'},
                    {key: 'received', label: 'Received'},
                ],
                selectedWorkOrderStatusFilter: 'all',
                selectedVendorEntityFilter: 'all',
                cardBodyHeight: null,
            };
        },
        mounted() {
            this.$refs.scanWorkOrderInput.focus();
        },
        created() {
            this.getWorkOrders();
        },
        methods: {
            getWorkOrders() {
                this.isLoadingWorkOrders = true;

                let url = new URL(this.$store.state.baseUrl + 'work-orders/get-all');

                this.$http.get(url.href).then((data) => {
                    this.workOrders = data.data;
                    this.isLoadingWorkOrders = false;
                });
            },
            scanWorkOrder() {
                this.$router.push({
                    name: 'WorkOrder',
                    query: {id: this.workOrderId}
                });
            },
            previous() {
                this.$router.push({
                    name: 'Storage',
                });
            },
            createWorkOrder() {
                this.$router.push({
                    name: 'WorkOrderCreate',
                });
            },
            fixCardBodyHeight() {
                if (this.$refs.cardBody) {
                    let scrollHeight = 0;

                    for (let i = 0; i < this.$refs.cardBody.length; i++) {
                        if (this.$refs.cardBody[i].scrollHeight > scrollHeight) {
                            scrollHeight = this.$refs.cardBody[i].scrollHeight;
                        }
                    }

                    this.cardBodyHeight = scrollHeight + 'px';
                }
            }
        },
        computed: {
            filteredWorkOrders() {
                if (!this.workOrders?.length) {
                    return [];
                }

                let result = [];

                for (const workOrder of this.workOrders) {
                    /* If the status filter is set to a specific status, check if the work order has that status. */
                    if (this.selectedWorkOrderStatusFilter !== 'all' && workOrder.STATUS !== this.selectedWorkOrderStatusFilter) {
                        continue;
                    }

                    /* If the vendor entity filter is set to a specific vendor entity, check if the work order belongs to that vendor entity. */
                    if (this.selectedVendorEntityFilter !== 'all' && workOrder.VENDOR_ENTITY_ID !== this.selectedVendorEntityFilter) {
                        continue;
                    }

                    result.push(workOrder);
                }

                return result;
            },
            vendorEntityFilterOptions() {
                let result = [{key: 'all', label: 'All'}];
                let addedVendorEntityIds = [];

                if (this.workOrders) {
                    for (const workOrder of this.workOrders) {
                        if (!addedVendorEntityIds.includes(workOrder.VENDOR_ENTITY_ID)) {
                            addedVendorEntityIds.push(workOrder.VENDOR_ENTITY_ID);

                            result.push({
                                key: workOrder.VENDOR_ENTITY_ID,
                                label: workOrder.VENDOR_ENTITY_NAME
                            });
                        }
                    }
                }

                return result;
            }
        },
        watch: {
            filteredWorkOrders() {
                this.$nextTick(() => {
                    this.fixCardBodyHeight();
                });
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        },
    }
</script>

<style scoped>
    .scan-manisfest-card {
        cursor: pointer;
        user-select: none;
    }

    .scan-manisfest-card-body .content {
        color: #1d2640;
    }
</style>
