import axios from '@/classes/Axios';

const merchantAccountStore = {
    namespaced: true,
    state: {
        merchantAccounts: [],
    },
    mutations: {
        setMerchantAccounts(state, merchantAccounts) {
            state.merchantAccounts = merchantAccounts;
        },
        clear(state) {
            state.merchantAccounts = [];
        }
    },
    actions: {
        getMerchantAccounts(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.merchantAccounts && storeArgObj.state.merchantAccounts.length > 0) {
                    resolve(storeArgObj.state.merchantAccounts);
                } else {
                    const url = new URL(storeArgObj.rootState.baseUrl + 'return-manifests/get-available-return-bird-accounts');

                    axios.get(url.href).then((data) => {
                        const merchantAccounts = data.data.data;

                        storeArgObj.commit('setMerchantAccounts', merchantAccounts);
                        resolve(merchantAccounts);
                    });
                }
            });
        }
    }
};

export default merchantAccountStore;
