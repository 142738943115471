<template>
    <tbody>
        <tr v-if="hasErrors">
            <td colspan="5" class="pb-0">
                <InputFieldError attribute="shipment" :errors="allErrors"></InputFieldError>
            </td>
        </tr>

        <tr>
            <td colspan="5" class="py-0">
                <template v-if="Object.keys(containers).length === 0">
                    <button type="button" class="btn btn-danger sm mt-4" @click.prevent="isShowingDeleteShipmentModal = true">
                        Delete
                    </button>

                    <p class="pt-4 mb-0">
                        There are no containers on this shipment.
                    </p>
                </template>

                <template v-else>
                    <table class="table-third-level">
                        <thead>
                            <tr>
                                <th class="w-auto">
                                    License plate
                                </th>

                                <th class="w-auto">
                                    Account
                                </th>

                                <th class="w-auto">
                                    Assets
                                </th>

                                <th class="w-auto"></th>

                                <th class="w-auto"></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="container in containers" :key="container.ID">
                                <template v-if="containersBeingRemoved.includes(container.ID)">
                                    <td colspan="5" class="w-auto">
                                        Removing container {{ container.LICENSE_PLATE }} from this shipment...
                                    </td>
                                </template>

                                <template v-else>
                                    <td class="w-auto">
                                        {{ container.LICENSE_PLATE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ container.RETURNBIRD_ACCOUNT_NAME }}
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="container.assets">
                                            {{ container.assets.length }}
                                        </template>

                                        <template v-else>
                                            -
                                        </template>
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="documentsBeingGenerated && documentsBeingGenerated.includes(container.ID)">
                                            Generating container document...
                                        </template>

                                        <template v-else>
                                            <a href="#" @click.prevent="getContainerPdf(container.ID)">
                                                Container document
                                            </a>
                                        </template>
                                    </td>

                                    <td class="w-auto text-right">
                                        <a href="#" @click.prevent="showRemoveContainerFromShipmentModal(container)" class="action-btn">
                                            &times;
                                        </a>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </td>
        </tr>

        <tr>
            <td colspan="5" class="pb-0">
                <p class="m-0 action-link" v-b-toggle="'available-models-to-add-dropdown'">
                    <span class="when-open">
                        Hide available containers
                    </span>

                    <span class="when-closed">
                        Add container
                    </span>
                </p>
            </td>
        </tr>

        <tr>
            <td colspan="5">
                <b-collapse id="available-models-to-add-dropdown">
                    <template v-if="isLoadingContainersReadyToShip">
                        Loading available containers...
                    </template>

                    <template v-else-if="filteredContainersReadyToShip.length === 0">
                        There are no available containers to add to this shipment.
                    </template>

                    <template v-else-if="isAddingContainers">
                        Adding containers to this shipment...
                    </template>

                    <template v-else>
                        <table class="table-third-level">
                            <thead>
                                <tr>
                                    <th class="w-20 pr-0"></th>

                                    <th class="w-auto pl-0">
                                        License plate
                                    </th>

                                    <th class="w-auto">
                                        Account
                                    </th>

                                    <th class="w-auto">
                                        Assets
                                    </th>

                                    <th class="w-auto"></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="container in filteredContainersReadyToShip" :key="container.ID">
                                    <td class="w-20 pr-0">
                                        <label class="checkbox-container mb-20 mr-1">
                                            <input type="checkbox" v-model="selectedContainersToShip[container.ID]" :disabled="isAddingContainers">
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>

                                    <td class="w-auto pl-0">
                                        {{ container.LICENSE_PLATE }}
                                    </td>

                                    <td class="w-auto">
                                        {{ container.RETURNBIRD_ACCOUNT_NAME }}
                                    </td>

                                    <td class="w-auto">
                                        {{ container.assets.length }}
                                    </td>

                                    <td class="w-auto">
                                        <template v-if="documentsBeingGenerated && documentsBeingGenerated.includes(container.ID)">
                                            Generating container document...
                                        </template>

                                        <template v-else>
                                            <a href="#" @click.prevent="getContainerPdf(container.ID)">
                                                Container document
                                            </a>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button class="btn btn-primary btn-md mt-3" :disabled="selectedContainers.length === 0" @click.prevent="addContainersToShipment()">
                            Add containers
                        </button>
                    </template>
                </b-collapse>
            </td>
        </tr>

        <ConfirmationPopup v-if="isShowingRemoveContainerFromShipmentModal"
                           :message="'Are you sure you want to remove container ' + containerToRemove.LICENSE_PLATE + ' from this shipment?'"
                           @confirm="removeContainerFromShipment()"
                           @deny="isShowingRemoveContainerFromShipmentModal = false">
        </ConfirmationPopup>

        <ConfirmationPopup v-if="isShowingDeleteShipmentModal"
                           :message="'Are you sure you want to delete shipment ' + shipment.REFERENCE + '?'"
                           :loadingMessage="'Deleting shipment ' + shipment.REFERENCE + '...'"
                           :isLoading="isDeletingShipment"
                           @confirm="deleteShipment()"
                           @deny="isShowingDeleteShipmentModal = false">
        </ConfirmationPopup>
    </tbody>
</template>

<script>
    import {BCollapse, VBToggle} from 'bootstrap-vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        props: [
            'shipment',
            'errors'
        ],
        components: {
            'b-collapse': BCollapse,
            'ConfirmationPopup': ConfirmationPopup,
            'InputFieldError': InputFieldError,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                localErrors: {},
                isShowingDeleteShipmentModal: false,
                isDeletingShipment: false,
                isShowingRemoveContainerFromShipmentModal: false,
                containerToRemove: null,
                containersBeingRemoved: [],
                isAddingContainers: false,
                containersReadyToShip: [],
                isLoadingContainersReadyToShip: false,
                documentsBeingGenerated: [],
                containers: [],
                selectedContainersToShip: {},
            };
        },
        created() {
            this.containers = this.shipment.containers;
            this.getContainersReadyToShip();
        },
        methods: {
            previous() {
                if (this.$route.query.origin && this.$route.query.origin === 'container') {
                    this.$router.push({
                        name: 'ShipContainer',
                        query: {
                            merchant: this.$route.query.merchant
                        }
                    });
                    return;
                }

                this.$router.push({
                    name: 'ShipmentOverview',
                });
            },
            getContainerPdf(containerId) {
                this.localErrors = {};
                this.documentsBeingGenerated.push(containerId);

                const url = new URL(this.$store.state.baseUrl + 'pdf/container');
                url.searchParams.append('id', containerId);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    let index = this.documentsBeingGenerated.indexOf(containerId);
                    this.documentsBeingGenerated.splice(index, 1);

                    let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(containerDocumentBlob));
                });
            },
            removeContainerFromShipment() {
                this.localErrors = {};
                this.isShowingRemoveContainerFromShipmentModal = false;
                this.containersBeingRemoved.push(this.containerToRemove.ID.toString());

                const url = new URL(this.$store.state.baseUrl + 'shipments/remove-container-from-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[ContainerID]', this.containerToRemove.ID);
                params.append('Shipment[ShipmentID]', this.shipment.ID);

                this.$http.post(url.href, params).then((data) => {
                    if (data.data.code === 100) {
                        const index = this.containers.findIndex((container) => container.ID === this.containerToRemove.ID.toString());

                        if (index > -1) {
                            this.containers.splice(index, 1);
                        }

                        this.getContainersReadyToShip();
                    } else if (data.data.code === 422) {
                        this.localErrors = {shipment: data.data.errors};
                    }

                    let index = this.containersBeingRemoved.indexOf(this.containerToRemove.ID);
                    this.containersBeingRemoved.splice(index, 1);
                    this.containerToRemove = null;
                });
            },
            showRemoveContainerFromShipmentModal(container) {
                this.containerToRemove = container;
                this.isShowingRemoveContainerFromShipmentModal = true;
            },
            getContainersReadyToShip() {
                this.isLoadingContainersReadyToShip = true;
                const url = new URL(this.$store.state.baseUrl + 'containers/get-all');
                url.searchParams.set('includeOnShipment', false);

                this.containersReadyToShip = null;

                this.$http.get(url.href).then((data) => {
                    this.isLoadingContainersReadyToShip = false;
                    this.containersReadyToShip = data.data;
                });
            },
            addContainersToShipment() {
                this.isAddingContainers = true;

                let selectedContainerIds = [];

                for (let i in this.selectedContainers) {
                    selectedContainerIds.push(this.selectedContainers[i].ID);
                }

                const url = new URL(this.$store.state.baseUrl + 'shipments/add-containers-to-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[ID]', this.shipment.ID);
                params.append('Shipment[containerIds]', selectedContainerIds);

                this.$http.post(url.href, params).then((data) => {
                    this.isAddingContainers = false;

                    if (data.data.code === 100) {
                        for (let i in this.selectedContainers) {
                            this.containers.push(this.selectedContainers[i]);
                        }

                        this.selectedContainersToShip = [];
                        this.getContainersReadyToShip();
                    } else if (data.data.code === 422) {
                        this.localErrors = {shipment: data.data.errors};
                    }
                });
            },
            deleteShipment() {
                this.localErrors = {};
                this.isDeletingShipment = true;

                const url = new URL(this.$store.state.baseUrl + 'shipments/delete-shipment');
                const params = new URLSearchParams();
                params.append('Shipment[ID]', this.shipment.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeletingShipment = false;
                    this.isShowingDeleteShipmentModal = false;

                    if (data.data.code === 100) {
                        this.previous();
                    } else if (data.data.code === 422) {
                        this.localErrors = {shipment: data.data.errors};
                    }
                });
            },
        },
        computed: {
            hasErrors() {
                return this.errors && Object.keys(this.errors).length > 0;
            },
            containerArray() {
                if (!this.containersReadyToShip) {
                    return [];
                }

                let result = [];

                for (let i in this.containersReadyToShip) {
                    const container = this.containersReadyToShip[i];

                    if (container.RETURNBIRD_ACCOUNT_ID !== this.shipment.MERCHANT_ACCOUNT_ID) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            filteredContainersReadyToShip() {
                if (!this.containerArray || this.containerArray.length === 0) {
                    return [];
                }

                let result = [];

                for (let i in this.containerArray) {
                    const container = this.containerArray[i];

                    if (this.showSelectedContainersOnly && !this.selectedContainers.includes(container)) {
                        continue;
                    }

                    if (this.containerLicensePlate && !container.LICENSE_PLATE.toLowerCase().includes(this.containerLicensePlate.toLowerCase()) && !container.ID.includes(this.containerLicensePlate)) {
                        continue;
                    }

                    result.push(container);
                }

                return result;
            },
            selectedContainers() {
                if (!this.selectedContainersToShip || Object.keys(this.selectedContainersToShip).length === 0) {
                    return [];
                }

                let result = [];

                for (let i = 0; i < Object.keys(this.selectedContainersToShip).length; i++) {
                    const key = Object.keys(this.selectedContainersToShip)[i];
                    const value = Object.values(this.selectedContainersToShip)[i];

                    if (value) {
                        const container = this.containersReadyToShip.find(container => container.ID == key);

                        if (container) {
                            result.push(container);
                        }
                    }
                }

                return result;
            },
            allErrors() {
                return {...this.errors, ...this.localErrors};
            }
        }
    }
</script>

<style scoped>
    .action-btn {
        padding: 3px 9px;
        border: 1px solid #4a90e2;
        text-decoration: none;
        border-radius: 5px;
    }

    .action-btn:hover {
        color: #fff;
        background-color: #4a90e2;
    }

    .collapsed > .when-open, .not-collapsed > .when-closed {
        display: none;
    }

    .action-link {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }

    .action-link:hover {
        color: #0056b3;
        text-decoration: underline;
    }
</style>
