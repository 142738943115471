import axios from 'axios';
import router from '../router';
import store from '../store';

axios.defaults.withCredentials = true;


/*
 * Middleware to log the user out / redirect (in the frontend) when the session is expired
*/
axios.interceptors.response.use((config) => {
    return config;
}, (error) => {
    if(typeof error.response !== 'undefined' &&  ( error.response.status === 403 || error.response.status === 401 )) {
        store.commit('users/logout');
        router.push({
            path: '/login'
        });
    }
    return error;
});

export default axios;
