<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <LoadingOverlay loadingMessage="Receiving return" v-if="isSubmitting"/>

        <main class="main">
            <form v-on:submit.prevent="receiveReturn()">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="form-group">
                                <label for="return-reference" class="label-title">
                                    Scan to receive and grade a return
                                </label>

                                <input id="return-reference" type="text" placeholder="Scan RMA number or tracking code"
                                       class="form-control lg" v-model="returnReference" ref="receiveReturnInput">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="global" :errors="errors"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <button type="button" class="btn btn-primary" @click="receiveReturn()">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>

        <div class="confirmation-overlay" v-if="showConfirmationModal">
            <div class="overlay-background"></div>

            <div class="modal-wrapper">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <h5 class="py-4 my-3" v-html="confirmationModalText"></h5>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click="showConfirmationModal = false">
                            No
                        </button>

                        <button type="button" class="btn btn-success" @click="receiveReturn(true)">
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmationPopup v-if="showCreateUnspecifiedReturnModal"
                           :message="'The return data is not available. Do you want to register an unspecified return?'"
                           @deny="closeCreateUnspecifiedReturnModal()" @confirm="confirmCreateUnspecifiedReturn()"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import LoadingOverlay from '@/components/_LoadingOverlay.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'LoadingOverlay': LoadingOverlay,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                initLoaded: true,
                returnReference: null,
                isSubmitting: false,
                errors: {},
                showConfirmationModal: false,
                showCreateUnspecifiedReturnModal: false,
                confirmationModalText: null,
            };
        },
        mounted() {
            this.$windowEventManager.addEventListener('keydown', (event) => {
                if (event.keyCode === 13) {
                    this.receiveReturn();
                }
            });

            /* Focus on the "scan return" input field, so the user can immediately enter a return reference on page load */
            this.$refs.receiveReturnInput.focus();
        },
        created() {

        },
        methods: {
            receiveReturn(confirm = false) {
                if (this.isSubmitting) {
                    return false;
                }

                this.errors = {};
                this.showConfirmationModal = false;

                /* Check if the return reference is empty, or exists of only spaces */
                if (!this.returnReference || /^\s*$/.test(this.returnReference)) {
                    this.errors = {global: 'Enter a return reference!'};
                    return false;
                }

                this.isSubmitting = true;

                let url = new URL(this.$store.state.baseUrl + 'return/receive');
                let params = new URLSearchParams();
                params.append('return[reference]', this.returnReference);

                if (confirm) {
                    params.append('return[confirm]', true);
                }

                this.$http.post(url.href, params).then((data) => {
                    this.isSubmitting = false;

                    if (data.data.code === 422) {
                        if (data.data.message === 'Not found') {
                            /* If the return was not found, ask if the operator wants to receive it as an unspecified return. */
                            this.showCreateUnspecifiedReturnModal = true;
                        } else {
                            this.errors = {global: data.data.errors};
                        }
                    } else if (data.data.code === 300) {
                        this.showConfirmationModal = true;
                        this.confirmationModalText = data.data.message;
                    } else if (data.data.code === 100 && data.data.data.RETURN_ID) {
                        let returnModel = data.data.data;
                        this.$store.commit('returns/setReceivedReturn', returnModel);

                        this.$router.push({
                            name: 'AssetOverview',
                            query: {returnId: returnModel.RETURN_ID}
                        });
                    }
                });
            },
            closeCreateUnspecifiedReturnModal() {
                this.showCreateUnspecifiedReturnModal = false;
                this.returnReference = null;

                this.$refs.receiveReturnInput?.focus();
            },
            confirmCreateUnspecifiedReturn() {
                this.$router.push({
                    name: 'CreateUnspecifiedReturn',
                    params: {flow: 'receiveAndGrade'},
                    query: {returnReference: this.returnReference}
                });
            },
            previous() {
                this.$router.push({
                    name: 'Inbound',
                });
            }
        }
    }
</script>

<style scoped>
    
</style>
