<template>
    <div>
        <HeaderBar title="Location"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <RoleMenu activeCategory="Access" activeItem="Locations" v-bind:roleId="$route.query.roleId"></RoleMenu>

                <div class="col-10">
                    <template v-if="!initLoaded">
                        loading locations / role accesses ...
                    </template>
                    <template v-else>
                        <div class="col-12">
                            <InputFieldError attribute="attributes" :errors="errors"/>
                        </div>

                        <div v-if="isSaving">
                            saving access...
                        </div>

                        <template v-else>
                            <div class="row" v-for="(location, key) in  $store.state.locations.locations"
                                 v-bind:key="key">
                                <div class="col-md-6 my-3">
                                    <label class="checkbox-container mr-3">
                                        <input type="checkbox" v-bind:id="location.ID" class="form-control"
                                               v-bind:checked="typeof locationIds[location.ID] !== 'undefined'"
                                               v-on:click="toggle(location.ID)"
                                        >
                                        <span class="checkmark"></span>
                                        {{ location.NAME }}
                                    </label>
                                </div>
                            </div>
                        </template>

                        <button type="button" name="button" v-if="!isSaving" v-on:click="save()"
                                class="btn md btn-primary">
                            save
                        </button>
                    </template>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import Vue from 'vue';
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import RoleMenu from '@/components/_RoleMenu.vue';


    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'RoleMenu': RoleMenu
        },
        data() {
            return {
                locationIds: {},
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {
            let rolesPromise = this.$store.dispatch('roles/getAll');
            let locationPromise = this.$store.dispatch('locations/getAll');
            let locationAccessPromise = this.$store.dispatch('roleAccess/getByType', {
                roleId: this.$route.query.roleId,
                type: 'rolelocationaccess'
            });

            Promise.all([rolesPromise, locationPromise, locationAccessPromise]).then(() => {
                this.initLoaded = true;
                this.locationIds = this.$store.state.roleAccess.rolelocationaccess[this.$route.query.roleId];
            });
        },
        methods: {
            toggle(id) {
                if (typeof this.locationIds[id] !== 'undefined')
                    Vue.delete(this.locationIds, id);
                else
                    this.locationIds[id] = {ID: id};
            },
            save() {
                let params = new URLSearchParams();
                let url = new URL(this.$store.state.baseUrl + 'rolelocationaccess/savebyids');

                let ids = Object.keys(this.locationIds);
                ids = ids.join(',');

                params.append('rolelocationaccess[locationIds]', ids);
                params.append('rolelocationaccess[roleId]', this.$route.query.roleId);
                this.isSaving = true;


                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;
                    if (typeof data.response !== 'undefined' && data.response.status == 422) {
                        this.errors = {};
                        for (let obj in data.response.data) {
                            this.errors[data.response.data[obj].field] = data.response.data[obj].message;
                        }
                    } else {
                        this.$store.commit('locationAccessToAccounts/clear');
                        this.$store.commit('roleAccess/clear');
                    }
                });
            }
        }
    }
</script>
