import axios from '@/classes/Axios';
import ArrayStoreFormatter from '@/classes/ArrayStoreFormatter';

const locationAccessToAccountStore = {
	namespaced: true,
	state: {
		accessIds: null,
	},
	mutations: {
		set(state, accessIds) {
			state.accessIds = accessIds;
		},
		clear(state) {
			state.accessIds = null;
		}
	},
	actions: {
		getAll(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.accessIds) {
                    resolve(storeArgObj.state.accessIds);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'locationaccesstoaccount');

                    axios.get(url.href).then((data) => {
                        let accessIds = ArrayStoreFormatter.format(data.data, 'ID');
                        storeArgObj.commit('set', accessIds);
                        resolve(accessIds);
                    });
                }
            });
        },
	}
};
export default locationAccessToAccountStore;
