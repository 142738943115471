<template>
    <div>
        <HeaderBar title="Location"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <RoleMenu activeCategory="Access" activeItem="Modules"
                          v-bind:roleId="$route.query.roleId"></RoleMenu>

                <div class="col-10">
                    <template v-if="!initLoaded">
                        loading modules / role accesses ...
                    </template>

                    <template v-else>
                        <div class="col-12">
                            <InputFieldError attribute="attributes" :errors="errors"/>
                        </div>

                        <div v-if="isSaving">
                            saving access...
                        </div>

                        <template v-else>
                            <div v-for="module in orderedModules" :key="module.name">
                                <template v-if="module.name === 'settings'">
                                    <div class="row">
                                        <div class="col-12">
                                            <h4>{{ module.name | capitalize }}</h4>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 my-3">
                                            <label class="checkbox-container mr-3">
                                                <input type="checkbox" class="form-control"
                                                       :checked="typeof moduleAccess['settings'] !== 'undefined' && moduleAccess['settings'].ACCESS"
                                                       @click="toggle(module.name, 'settings')"
                                                       :disabled="$store.state.users.currentUser.ROLE_ID == $route.query.roleId"
                                                >

                                                <span class="checkmark" :class="{'checkmark-disabled': $store.state.users.currentUser.ROLE_ID == $route.query.roleId}"></span>

                                                Settings
                                            </label>
                                        </div>
                                    </div>
                                </template>

                                <template v-if="module.name === 'control tower'">
                                    <div class="row">
                                        <div class="col-12">
                                            <h4>{{ module.name | capitalize }}</h4>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 my-3">
                                            <label class="checkbox-container mr-3">
                                                <input type="checkbox" class="form-control"
                                                       :checked="typeof moduleAccess['control-tower'] !== 'undefined' && moduleAccess['control-tower'].ACCESS"
                                                       @click="toggle(module.name, 'control-tower')"
                                                >

                                                <span class="checkmark"></span>

                                                Control tower
                                            </label>
                                        </div>
                                    </div>
                                </template>

                                <template v-if="module.submodules && Object.keys(module.submodules).length > 0">
                                    <!--
                                    Only show modules with submodules for now. This is temporary, because only toggling
                                    access to submodules is implemented at the moment. Remove this <template> once toggling
                                    access to modules has been implemented as well.
                                    -->

                                    <div class="row">
                                        <div class="col-12">
                                            <h4>{{ module.name | capitalize }}</h4>
                                        </div>
                                    </div>

                                    <div class="row" v-for="(label, key) in module.submodules" :key="key">
                                        <div class="col-md-6 my-3">
                                            <label class="checkbox-container mr-3">
                                                <input type="checkbox" :id="key" class="form-control"
                                                       :checked="typeof moduleAccess[key] !== 'undefined' && moduleAccess[key].ACCESS"
                                                       @click="toggle(module.name, key)"
                                                >

                                                <span class="checkmark"></span>

                                                {{ label }}
                                            </label>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>

                        <button type="button" name="button" v-if="!isSaving" v-on:click="save()"
                                class="btn md btn-primary">
                            save
                        </button>
                    </template>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import Vue from 'vue';
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import RoleMenu from '@/components/_RoleMenu.vue';
    import _ from 'lodash';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'RoleMenu': RoleMenu
        },
        data() {
            return {
                modules: {},
                moduleAccess: {},
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {
            let rolesPromise = this.$store.dispatch('roles/getAll');
            let modulesPromise = this.$store.dispatch('modules/getAvailableModules');
            let moduleAccessPromise = this.$store.dispatch('roleAccess/getByType', {
                roleId: this.$route.query.roleId,
                type: 'role-module-access'
            });
            let userPromise = this.$store.dispatch('users/getCurrentUser');

            Promise.all([rolesPromise, modulesPromise, moduleAccessPromise, userPromise]).then(() => {
                this.initLoaded = true;
                this.modules = this.$store.state.modules.availableModules;
                this.moduleAccess = this.$store.state.roleAccess['role-module-access'][this.$route.query.roleId];
            });
        },
        computed: {
            orderedModules: function () {
                return _.orderBy(this.modules, 'displayOrder')
            }
        },
        methods: {
            toggle(module, submodule) {
                if (typeof this.moduleAccess[submodule] !== 'undefined') {
                    let model = this.moduleAccess[submodule];
                    model.ACCESS = model.ACCESS === 1 ? 0 : 1;

                    Vue.set(this.moduleAccess, submodule, model);
                } else {
                    Vue.set(this.moduleAccess, submodule, {
                        'ACCESS': 1,
                        'MODULE': module,
                        'SUBMODULE': submodule,
                    });
                }
            },
            save() {
                let moduleAccessLength = Object.keys(this.moduleAccess).length;
                let current = 0;

                if (moduleAccessLength > 0) {
                    this.isSaving = true;

                    _.forOwn(this.moduleAccess, (model) => {
                        let url;
                        let method;
                        let params = new URLSearchParams();

                        params.append('ID', model.ID);
                        params.append('ACCESS', model.ACCESS);
                        params.append('MODULE', model.MODULE);
                        params.append('SUBMODULE', model.SUBMODULE);

                        if (model.ID) {
                            url = new URL(this.$store.state.baseUrl + 'role-module-accesses/' + model.ID);
                            method = this.$http.put(url.href, params);
                        } else {
                            url = new URL(this.$store.state.baseUrl + 'role-module-accesses');
                            params.append('ROLE_ID', this.$route.query.roleId);
                            method = this.$http.post(url.href, params);
                        }

                        method.then((data) => {
                            /* Detect if this is the last response. */
                            if (++current === moduleAccessLength) {
                                this.isSaving = false;
                            }

                            if (typeof data.response !== 'undefined' && data.response.status === 422) {
                                this.errors = {};

                                for (let obj in data.response.data) {
                                    this.errors[data.response.data[obj].field] = data.response.data[obj].message;
                                }
                            } else {
                                if (!this.moduleAccess[model.SUBMODULE].ID) {
                                    /* Retrieve the ID if this was a new record. */
                                    this.moduleAccess[model.SUBMODULE].ID = data.data.ID;
                                }

                                this.$store.commit('roleAccess/setByType', {
                                    roleId: this.$route.query.roleId,
                                    type: 'role-module-access',
                                    data: this.moduleAccess
                                });
                            }
                        });
                    });
                }
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>
