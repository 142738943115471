<template>
    <div class="confirmation-overlay">
        <div class="overlay-background"></div>

        <div class="modal-wrapper">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="mb-0">
                        Create container
                    </h5>
                </div>

                <div class="modal-body">
                    <template v-if="isCreatingContainer">
                        Creating container {{ newContainer.licensePlate }}...
                    </template>

                    <template v-else>
                        <div class="form-group">
                            <label class="label">
                                License plate
                            </label>

                            <input type="text" class="form-control" v-model="newContainer.licensePlate">
                            <InputFieldError attribute="LICENSE_PLATE" :errors="errors"/>
                        </div>

                        <div class="form-group">
                            <label class="label">
                                Container type
                            </label>

                            <template v-if="isLoadingContainerTypes">
                                <p>
                                    Loading container types...
                                </p>
                            </template>

                            <template v-else>
                                <v-select v-model="newContainer.type"
                                          :filterable="true"
                                          :searchable="true"
                                          :clearable="false"
                                          :options="containerTypes">
                                </v-select>

                                <InputFieldError attribute="TYPE" :errors="errors"/>
                            </template>
                        </div>
                    </template>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="close()" :disabled="isCreatingContainer">
                        Cancel
                    </button>

                    <button type="button" class="btn btn-success" @click="createContainer()" :disabled="isCreatingContainer">
                        Create container
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputFieldError from '@/components/_InputFieldError.vue';
    import vSelect from 'vue-select';
    
    export default {
        components: {
            'InputFieldError': InputFieldError,
            'v-select': vSelect,
        },
        props: [
            'message',
            'loadingMessage',
            'isLoading',
            'storageType',
        ],
        data() {
            return {
                errors: {},
                isLoadingContainerTypes: false,
                isCreatingContainer: false,
                newContainer: {
                    licensePlate: null,
                    type: null
                },
                containerTypes: null,
            }
        },
        created() {
            this.getContainerTypes();
        },
        methods: {
            getContainerTypes() {
                this.isLoadingContainerTypes = true;

                this.$store.dispatch('containers/getContainerTypes').then((data) => {
                    this.isLoadingContainerTypes = false;
                    this.containerTypes = data;
                });
            },
            createContainer() {
                if (!this.newContainer.licensePlate) {
                    this.errors = {LICENSE_PLATE: 'License plate is required'};
                    return false;
                }

                if (!this.newContainer.type) {
                    this.errors = {TYPE: 'Container type is required'};
                    return false;
                }

                this.isCreatingContainer = true;

                let url = new URL(this.$store.state.baseUrl + 'containers/create-container');
                url.searchParams.set('returnRelationalData', true);

                let params = new URLSearchParams();
                params.append('Container[LICENSE_PLATE]', this.newContainer.licensePlate);
                params.append('Container[TYPE]', this.newContainer.type);

                if (this.storageType) {
                    params.append('Container[STORAGE_TYPE]', this.storageType);
                }

                this.$http.post(url, params).then((data) => {
                    this.isCreatingContainer = false;

                    if (data.data.code === 100) {
                        this.newContainer = {
                            licensePlate: null,
                            type: null
                        };

                        this.$emit('createdContainer', data.data.data);
                    } else {
                        this.errors = data.data.errors;
                    }
                });
            },
            close() {
                this.$emit('close');
            }
        },
    }
</script>


<style>

</style>
