<template>
    <div>
        <HeaderBar title="Manifest"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="row" v-if="isLoadingShipment">
                                <div class="col-12">
                                    Loading shipment...
                                </div>
                            </div>

                            <div class="row" v-else-if="!shipment || Object.keys(shipment).length === 0">
                                <div class="col-12">
                                    Shipment not found.
                                </div>
                            </div>

                            <table class="table-first-level" v-else>
                                <thead>
                                    <tr>
                                        <th>
                                            Shipment
                                        </th>

                                        <th>
                                            Type
                                        </th>

                                        <th v-if="shouldShowMerchant">
                                            Merchant account
                                        </th>

                                        <th>
                                            <template v-if="modelString === 'manifest'">
                                                Manifests
                                            </template>

                                            <template v-else-if="modelString === 'container'">
                                                Containers
                                            </template>

                                            <template v-else-if="modelString === 'work-order'">
                                                Work orders
                                            </template>
                                        </th>

                                        <th>
                                            Shipping destination
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="5">
                                            <table class="table-second-level">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ shipment.REFERENCE }}
                                                        </th>

                                                        <th>
                                                            {{ getTypeLabel(shipment.TYPE) }}
                                                        </th>

                                                        <th v-if="shouldShowMerchant">
                                                            {{ shipment.MERCHANT_ACCOUNT_NAME }}
                                                        </th>

                                                        <th>
                                                            <template v-if="modelString === 'manifest'">
                                                                {{ Object.keys(shipment.returnManifests).length }}
                                                            </template>

                                                            <template v-else-if="modelString === 'container'">
                                                                {{ Object.keys(shipment.containers).length }}
                                                            </template>

                                                            <template v-else-if="modelString === 'work-order'">
                                                                {{ Object.keys(shipment.workOrders).length }}
                                                            </template>
                                                        </th>

                                                        <th>
                                                            {{ shipment.DESTINATION_NAME }}
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <ManifestShipmentBody :shipment="shipment" :errors="errors" v-if="modelString === 'manifest'" />
                                                <ContainerShipmentBody :shipment="shipment" :errors="errors" v-if="modelString === 'container'" />
                                                <WorkOrderShipmentBody :shipment="shipment" :errors="errors" v-if="modelString === 'work-order'" />
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between align-items-center">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <div v-if="isShippingShipment">
                                    Sending shipment...
                                </div>

                                <button type="button" class="btn btn-primary" @click="sendShipment()" :disabled="isShippingShipment" v-if="shipment && Object.keys(shipment).length > 0 && (Object.keys(shipment.returnManifests).length > 0 || Object.keys(shipment.containers).length > 0 || Object.keys(shipment.workOrders).length > 0)">
                                    Send shipment
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import ManifestShipmentBody from '@/components/_ManifestShipmentBody.vue';
    import ContainerShipmentBody from '@/components/_ContainerShipmentBody.vue';
    import WorkOrderShipmentBody from '@/components/_WorkOrderShipmentBody.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'ManifestShipmentBody': ManifestShipmentBody,
            'ContainerShipmentBody': ContainerShipmentBody,
            'WorkOrderShipmentBody': WorkOrderShipmentBody,
        },
        data() {
            return {
                errors: {},
                shipmentReference: this.$route.query.reference,
                isLoadingShipment: false,
                isShippingShipment: false,
                shipment: null,
            };
        },
        created() {
            this.getShipment();

        },
        methods: {
            previous() {
                if (this.$route.query.origin) {
                    if (this.$route.query.origin === 'shipSorted') {
                        this.$router.push({
                            name: 'ShipManifest',
                            query: {
                                type: 'sorted',
                                merchant: this.$route.query.merchant
                            }
                        });
                        return;
                    } else if (this.$route.query.origin === 'shipUnsorted') {
                        this.$router.push({
                            name: 'ShipManifest',
                            query: {
                                type: 'unsorted'
                            }
                        });
                        return;
                    }
                }

                this.$router.push({
                    name: 'ShipmentOverview',
                });
            },
            getShipment() {
                this.isLoadingShipment = true;

                const url = new URL(this.$store.state.baseUrl + 'shipments/get-shipment');
                url.searchParams.set('reference', this.shipmentReference);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingShipment = false;

                    if (data.data) {
                        this.shipment = data.data;
                    }
                });
            },
            sendShipment() {
                this.errors = {};
                this.isShippingShipment = true;

                let url;

                if (this.modelString === 'manifest') {
                    url = new URL(this.$store.state.baseUrl + 'shipments/ship-manifest-shipment');
                } else if (this.modelString === 'container') {
                    url = new URL(this.$store.state.baseUrl + 'shipments/ship-container-shipment');
                } else if (this.modelString === 'work-order') {
                    url = new URL(this.$store.state.baseUrl + 'shipments/ship-work-order-shipment');
                }

                const params = new URLSearchParams();
                params.append('Shipment[ID]', this.shipment.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isShippingShipment = false;

                    if (data.data.code === 100) {
                        this.getShipmentPdf();
                        this.previous();
                    } else if (data.data.code === 422) {
                        this.errors = {shipment: data.data.errors};
                    }
                });
            },
            getShipmentPdf() {
                this.errors = {};
                this.isGeneratingDocument = true;

                const url = new URL(this.$store.state.baseUrl + 'pdf/outbound-shipment');
                url.searchParams.append('id', this.shipment.ID);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    this.isGeneratingDocument = false;

                    const documentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(documentBlob));
                });
            },
            getTypeLabel(type) {
                switch (type) {
                    case 'sorted-manifest':
                        return 'Sorted manifest';

                    case 'unsorted-manifest':
                        return 'Unsorted manifest';

                    case 'container':
                        return 'Container';

                    case 'work-order':
                        return 'Work order';

                    default:
                        return '';
                }
            }
        },
        computed: {
            shouldShowMerchant() {
                return this.shipment?.TYPE === 'sorted-manifest' || this.shipment?.TYPE === 'container' || this.shipment?.TYPE === 'work-order';
            },
            modelString() {
                if (this.shipment?.TYPE === 'sorted-manifest' || this.shipment?.TYPE === 'unsorted-manifest') {
                    return 'manifest';
                }

                if (this.shipment?.TYPE === 'container') {
                    return 'container';
                }

                if (this.shipment?.TYPE === 'work-order') {
                    return 'work-order';
                }

                return '';
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        }
    }
</script>

<style scoped>
    .action-btn {
        padding: 3px 9px;
        border: 1px solid #4a90e2;
        text-decoration: none;
        border-radius: 5px;
    }

    .action-btn:hover {
        color: #fff;
        background-color: #4a90e2;
    }

    .collapsed > .when-open, .not-collapsed > .when-closed {
        display: none;
    }

    .action-link {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }

    .action-link:hover {
        color: #0056b3;
        text-decoration: underline;
    }
</style>
