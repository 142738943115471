<template>
    <div>
        <HeaderBar title="Role overview"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="Role" activeItem="Overview"></SettingsMenu>

                <template v-if="initLoaded">
                    <div class="col-10">
                        <div class="row col-12">
                            <div class="col-md-2">
                                Role Id
                            </div>
                            <div class="col-md-2">
                                Role name
                            </div>
                            <div class="col-md-2">
                                Description
                            </div>
                            <div class="col-md-2">
                                Created at
                            </div>
                            <div class="col-md-2">
                                Updated at
                            </div>
                            <div class="col-md-1">
                                Update
                            </div>
                            <div class="col-md-1">
                                Delete
                            </div>
                        </div>

                        <div class="col-12 row" v-for="(role, key) in $store.state.roles.roles" v-bind:key="key">
                            <template v-if="rolesBeingDeleted.indexOf(role.ID) != -1">
                                deleting role...
                            </template>
                            <template v-else>
                                <div class="col-md-2">
                                    {{ role.ID }}
                                </div>
                                <div class="col-md-2">
                                    {{ role.NAME }}
                                </div>
                                <div class="col-md-2">
                                    {{ role.DESCRIPTION }}
                                </div>
                                <div class="col-md-2">
                                    {{ role.CREATION }}
                                </div>
                                <div class="col-md-2">
                                    {{ role.UPDATED }}
                                </div>
                                <div class="col-md-1 text-primary pointer" v-on:click="updateRole(role.ID)">
                                    Update
                                </div>
                                <div class="col-md-1 text-danger pointer" v-on:click="deleteRole(role.ID)">
                                    Delete
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <div v-else>
                    loading roles...
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'SettingsMenu': SettingsMenu
        },
        data() {
            return {
                initLoaded: false,
                rolesBeingDeleted: []
            };
        },
        created() {
            let userPromise = this.$store.dispatch('users/getCurrentUser');
            let rolesPromise = this.$store.dispatch('roles/getAll');

            Promise.all([userPromise, rolesPromise]).then(() => {
                this.initLoaded = true;
            });

        },
        methods: {
            updateRole(id) {
                this.$router.push({
                    name: 'RoleUpdate',
                    query: {roleId: id}
                });
            },
            deleteRole(id) {
                let url = new URL(this.$store.state.baseUrl + 'roles/' + id);
                this.rolesBeingDeleted.push(id);

                this.$http.delete(url.href).then(() => {
                    var index = this.rolesBeingDeleted.indexOf(id);
                    this.rolesBeingDeleted.splice(index, 1);
                    this.$store.commit('roles/delete', id);
                });
            }
        }
    }
</script>

<style>

</style>
