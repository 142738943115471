<template>
    <div>
        <HeaderBar title="Work order details"></HeaderBar>

        <main class="main">
            <form action="">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="table-responsive overflow-x-inherit">
                                <template v-if="isLoading">
                                    Loading work order...
                                </template>

                                <template v-else-if="!workOrder || Object.keys(workOrder).length === 0">
                                    No work order found with ID {{ id }}
                                </template>

                                <template v-else>
                                    <table class="table-first-level col-count-6">
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th>External reference</th>
                                                <th>Status</th>
                                                <th>Account</th>
                                                <th>Vendor entity</th>
                                                <th>Assets</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="6">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    {{ workOrder.REFERENCE }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="workOrder.EXTERNAL_REFERENCE">
                                                                        {{ workOrder.EXTERNAL_REFERENCE }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    {{ workOrder.STATUS | capitalize }}
                                                                </th>

                                                                <th>
                                                                    {{ workOrder.MERCHANT_ACCOUNT_NAME }}
                                                                </th>

                                                                <th>
                                                                    <template v-if="workOrder.VENDOR_ENTITY_NAME">
                                                                        {{ workOrder.VENDOR_ENTITY_NAME }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th>
                                                                    {{ assetCount }}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <template v-if="workOrder.STATUS === 'created'">
                                                                        <WorkOrderContainerList :workOrder="workOrder" @delete-work-order="deleteWorkOrder" :disabled="isClosing || isDeleting" />
                                                                    </template>

                                                                    <template v-else>
                                                                        <WorkOrderAssetList :workOrder="workOrder" @delete-work-order="deleteWorkOrder" :disabled="isClosing || isDeleting" />
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="7">
                                                                    <div class="row">
                                                                        <div class="col-12 col-md-6">
                                                                            <div class="form-group mb-4 mb-lg-0">
                                                                                <label class="label">
                                                                                    External shipping reference
                                                                                </label>

                                                                                <template v-if="workOrderIsShipped">
                                                                                    <div class="d-flex">
                                                                                        <input type="text" class="form-control" v-model="workOrder.EXTERNAL_SHIPPING_REFERENCE">

                                                                                        <button class="btn btn-primary" :disabled="isClosing || isDeleting || isSavingWorkOrder || !workOrder.EXTERNAL_SHIPPING_REFERENCE" @click.prevent="saveExternalShippingReference()">
                                                                                            Save
                                                                                        </button>
                                                                                    </div>

                                                                                    <p class="text-success mt-3 mb-0" v-if="hasSavedExternalShippingReference">
                                                                                        The external shipping reference has been saved!
                                                                                    </p>
                                                                                </template>

                                                                                <template v-else>
                                                                                    <p class="mb-0">
                                                                                        {{ workOrder.EXTERNAL_SHIPPING_REFERENCE ?? '-' }}
                                                                                    </p>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </div>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <InputFieldError :errors="errors"/>

                                <div>
                                    <button type="button" class="btn btn-primary" :disabled="isClosing || isDeleting || isSavingWorkOrder" @click.prevent="showCloseWorkOrderConfirmationModal = true" v-if="workOrderIsReceived">
                                        Close
                                    </button>

                                    <button type="button" class="btn btn-primary" :disabled="isClosing || isDeleting || isSavingWorkOrder || !allAssetsHaveBeenUpdated" @click.prevent="showExpectWorkOrderConfirmationModal = true" v-if="workOrderIsShipped">
                                        Mark as expected
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>

        <ConfirmationPopup v-if="showCloseWorkOrderConfirmationModal"
                           :message="'Are you sure you want to close work order ' + workOrder.REFERENCE + '?'"
                           @deny="showCloseWorkOrderConfirmationModal = false"
                           @confirm="closeWorkOrder()"
        />

        <ConfirmationPopup v-if="showExpectWorkOrderConfirmationModal"
                           :message="'Are you sure you want to mark work order ' + workOrder.REFERENCE + ' as expected?'"
                           @deny="showExpectWorkOrderConfirmationModal = false"
                           @confirm="expectWorkOrder()"
        />
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import WorkOrderAssetList from '@/components/WorkOrder/_WorkOrderAssetList.vue';
    import WorkOrderContainerList from '@/components/WorkOrder/_WorkOrderContainerList.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'ConfirmationPopup': ConfirmationPopup,
            'WorkOrderAssetList': WorkOrderAssetList,
            'WorkOrderContainerList': WorkOrderContainerList,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                errors: {},
                isLoading: false,
                isClosing: false,
                isDeleting: false,
                isSavingWorkOrder: false,
                hasSavedExternalShippingReference: false,
                id: this.$route.query.id,
                workOrder: null,
                showCloseWorkOrderConfirmationModal: false,
                showExpectWorkOrderConfirmationModal: false,
            };
        },
        created() {
            this.getWorkOrder();
        },
        methods: {
            getWorkOrder() {
                this.isLoading = true;

                const url = new URL(this.$store.state.baseUrl + 'work-order/get-by-id');
                url.searchParams.append('id', this.id);

                this.$http.get(url.href).then((data) => {
                    this.isLoading = false;
                    this.workOrder = data.data;
                });
            },
            previous() {
                this.$router.push({
                    name: 'WorkOrderOverview',
                });
            },
            closeWorkOrder() {
                this.errors = {};
                this.isClosing = true;
                this.showCloseWorkOrderConfirmationModal = false;

                const url = new URL(this.$store.state.baseUrl + 'work-order/close');
                url.searchParams.append('id', this.id);

                this.$http.post(url.href).then((data) => {
                    this.isClosing = false;

                    if (data?.data?.code === 100) {
                        this.getWorkOrderPdf();
                        this.previous();
                    } else if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    }
                });
            },
            expectWorkOrder() {
                this.errors = {};
                this.isSavingWorkOrder = true;
                this.showExpectWorkOrderConfirmationModal = false;

                const url = new URL(this.$store.state.baseUrl + 'work-order/mark-as-expected');
                const params = new URLSearchParams();
                params.append('WorkOrder[ID]', this.id);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingWorkOrder = false;

                    if (data?.data?.code === 100) {
                        this.workOrder.STATUS = 'expected';
                    } else if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    }
                });
            },
            getWorkOrderPdf() {
                const url = new URL(this.$store.state.baseUrl + 'pdf/work-order');
                url.searchParams.append('id', this.id);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    const documentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(documentBlob));
                });
            },
            deleteWorkOrder() {
                this.errors = {};
                this.isDeleting = true;

                const url = new URL(this.$store.state.baseUrl + 'work-order/delete-work-order');
                const params = new URLSearchParams();
                params.append('WorkOrder[ID]', this.workOrder.ID);

                this.$http.post(url.href, params).then((data) => {
                    this.isDeleting = false;

                    if (data?.data?.code === 100) {
                        this.previous();
                    } else if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    }
                });
            },
            saveExternalShippingReference() {
                this.errors = {};
                this.isSavingWorkOrder = true;
                this.hasSavedExternalShippingReference = false;

                const url = new URL(this.$store.state.baseUrl + 'work-order/save-external-shipping-reference');
                const params = new URLSearchParams();
                params.append('WorkOrder[ID]', this.workOrder.ID);
                params.append('WorkOrder[EXTERNAL_SHIPPING_REFERENCE]', this.workOrder.EXTERNAL_SHIPPING_REFERENCE);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingWorkOrder = false;

                    if (data?.data?.code === 422) {
                        this.errors = data.data.errors;
                    } else if (data?.data?.code === 100) {
                        this.hasSavedExternalShippingReference = true;
                    }
                });
            }
        },
        computed: {
            assetCount() {
                if (!this.workOrder) {
                    return 0;
                }

                let count = 0;

                if (this.workOrder.STATUS === 'created') {
                    if (this.workOrder.containers?.length) {
                        for (const container of this.workOrder.containers) {
                            count += container.assets?.length;
                        }
                    }
                } else {
                    count = this.workOrder.assets.length;
                }

                return count;
            },
            workOrderIsReceived() {
                return this.workOrder?.STATUS === 'received';
            },
            workOrderIsShipped() {
                return this.workOrder?.STATUS === 'shipped';
            },
            allAssetsHaveBeenUpdated() {
                let result = true;

                if (this.workOrder?.assets) {
                    for (const asset of this.workOrder.assets) {
                        if (!asset.VENDOR_RESOLUTION) {
                            result = false;
                        }
                    }
                }

                return result;
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        }
    }
</script>

<style scoped>
    .overflow-x-inherit {
        overflow-x: inherit;
    }
</style>
