<template>
    <tr>
        <td class="w-auto">
            {{ asset.SKU }}
        </td>

        <td class="w-auto">
            {{ asset.LICENSE_PLATE }}
        </td>

        <td class="w-auto">
            {{ asset.STORAGE_TYPE }}
        </td>

        <td class="w-auto">
            {{ asset.GRADE }}
        </td>

        <td class="w-auto">
            <template v-if="asset.IS_IN_PROGRESS == 1 || isUpdatingAsset">
                <template v-if="isLoadingVendorResolutionOptions">
                    Loading...
                </template>

                <template v-else>
                    <v-select v-model="selectedVendorResolution"
                              :filterable="true"
                              :searchable="true"
                              :clearable="false"
                              :options="vendorResolutionOptions"
                              :reduce="e => e.id"
                              @open="getGradingSet()"
                              label="name"
                              :disabled="isSavingAsset"
                    >
                    </v-select>

                    <InputFieldError attribute="VENDOR_RESOLUTION" :errors="errors"/>
                </template>
            </template>

            <template v-else>
                {{ asset.VENDOR_RESOLUTION|capitalize }}
            </template>
        </td>

        <td class="w-auto">
            <template v-if="(asset.IS_IN_PROGRESS == 1 || isUpdatingAsset) && selectedVendorResolution === 'repaired'">
                <template v-if="isLoadingGradingSet">
                    Loading...
                </template>

                <template v-else>
                    <v-select v-model="selectedReworkedGrade"
                              :filterable="true"
                              :searchable="true"
                              :clearable="false"
                              :options="gradingSet"
                              :reduce="e => e.RTN_GRL_GRADE"
                              label="RTN_GRL_GRADE"
                              :disabled="isSavingAsset"
                    >
                    </v-select>

                    <InputFieldError attribute="REWORKED_GRADE" :errors="errors"/>
                </template>
            </template>

            <template v-else-if="asset.IS_IN_PROGRESS == 1 && selectedVendorResolution === 'replaced'">
                {{ selectedReworkedGrade }}
            </template>

            <template v-else-if="!isUpdatingAsset">
                {{ asset.REWORKED_GRADE }}
            </template>
        </td>

        <td class="wd-auto">
            {{ getAssetStatus(asset) }}
        </td>

        <td class="w-auto">
            <template v-if="asset.IS_IN_PROGRESS == 1">
                <template v-if="isSavingAsset">
                    Saving...
                </template>
            </template>

            <template v-else-if="!isUpdatingAsset && workOrder.STATUS === 'shipped'">
                <button type="button" class="btn btn-primary" @click="updateAsset()">
                    Update
                </button>
            </template>
        </td>
    </tr>
</template>

<script>
    import vSelect from 'vue-select';
    import InputFieldError from "@/components/_InputFieldError.vue";

    export default {
        props: [
            'rowItem',
            'workOrder',
            'isLoadingVendorResolutionOptions',
            'vendorResolutionOptions'
        ],
        components: {
            'v-select': vSelect,
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                errors: {},
                isLoadingGradingSet: false,
                isSavingAsset: false,
                isUpdatingAsset: false,
                gradingSet: [],
                selectedVendorResolution: null,
                selectedReworkedGrade: null,
            }
        },
        created() {
            this.asset = this.rowItem;
        },
        methods: {
            getAssetStatus(asset) {
                if (asset) {
                    const assetFlags = {
                        IS_ARCHIVED: 'Archived',
                        IS_EXPECTED: 'Expected',
                        IS_IN_PROGRESS: 'In progress',
                        IS_RESERVED: 'Reserved',
                        IS_ON_HAND: 'Available',
                    };

                    for (const [attribute, label] of Object.entries(assetFlags)) {
                        if (asset?.[attribute] === 1 || asset?.[attribute] === '1') {
                            return label;
                        }
                    }
                }

                return '-';
            },
            validateFields() {
                this.errors = {};

                if (!this.selectedVendorResolution) {
                    this.errors['VENDOR_RESOLUTION'] = 'Please select a vendor resolution.';
                }

                if (this.selectedVendorResolution === 'repaired' && !this.selectedReworkedGrade) {
                    this.errors['REWORKED_GRADE'] = 'Please select the new grade of the revalued asset.';
                }

                return !Object.keys(this.errors).length > 0;
            },
            saveAsset() {
                if (!this.validateFields()) {
                    return false;
                }

                this.isSavingAsset = true;

                const url = new URL(this.$store.state.baseUrl + 'work-order/update-asset');
                url.searchParams.set('id', this.workOrder.ID);
                url.searchParams.set('assetId', this.asset.ID);

                let params = new URLSearchParams();
                params.append('Asset[REWORKED_GRADE]', this.selectedReworkedGrade);
                params.append('Asset[VENDOR_RESOLUTION]', this.selectedVendorResolution);

                this.$http.post(url.href, params).then((data) => {
                    this.isUpdatingAsset = false;
                    this.isSavingAsset = false;

                    if (data.data.code === 100) {
                        this.asset.VENDOR_RESOLUTION = this.selectedVendorResolution;
                        this.asset.REWORKED_GRADE = this.selectedReworkedGrade;
                        this.asset.IS_IN_PROGRESS = 0;
                        this.asset.IS_EXPECTED = this.asset.VENDOR_RESOLUTION === 'repaired' || this.asset.VENDOR_RESOLUTION === 'replaced' ? 1 : 0;
                        this.asset.IS_ARCHIVED = this.asset.VENDOR_RESOLUTION === 'rejected' || this.asset.VENDOR_RESOLUTION === 'reimbursed' ? 1 : 0;
                    } else if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    }
                });
            },
            updateAsset() {
                this.selectedVendorResolution = null;
                this.selectedReworkedGrade = null;
                this.isUpdatingAsset = true;
                this.asset.IS_EXPECTED = 0;
                this.asset.IS_ARCHIVED = 0;
                this.asset.IS_IN_PROGRESS = 1;
            },
            getGradingSet() {
                if (this.isLoadingGradingSet || this.gradingSet?.length) {
                    return;
                }

                this.isLoadingGradingSet = true;

                this.$store.dispatch('assets/getGradingLabels', this.asset.ID).then((data) => {
                    this.isLoadingGradingSet = false;
                    this.gradingSet = data;
                });
            }
        },
        watch: {
            selectedVendorResolution(selectedVendorResolution) {
                if (selectedVendorResolution && selectedVendorResolution === 'replaced') {
                    this.selectedReworkedGrade = 'A';
                } else {
                    this.selectedReworkedGrade = null;
                }

                if (selectedVendorResolution && (selectedVendorResolution === 'replaced' || selectedVendorResolution === 'rejected' || selectedVendorResolution === 'reimbursed')) {
                    this.saveAsset();
                }
            },
            selectedReworkedGrade(selectedReworkedGrade) {
                if (selectedReworkedGrade && this.selectedVendorResolution) {
                    this.saveAsset();
                }
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) {
                    return '';
                }

                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
        }
    }
</script>


<style>

</style>
