<template>
    <div>
        <HeaderBar title="Location"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <RoleMenu activeCategory="Access" activeItem="LocationAccounts"
                          v-bind:roleId="$route.query.roleId"></RoleMenu>

                <div class="col-10">
                    <template v-if="!initLoaded">
                        loading accounts / role accesses ...
                    </template>
                    <template v-else>
                        <div class="col-12">
                            <InputFieldError attribute="attributes" :errors="errors"/>
                        </div>

                        <div v-if="isSaving">
                            saving access...
                        </div>

                        <template v-else-if="Object.keys(filteredLocationAccessToAccounts).length > 0">
                            <div class="my-3" v-for="(location, locationKey) in filteredLocationAccessToAccounts" v-bind:key="locationKey">
                                <a v-b-toggle="'location-account-access--' + locationKey" class="foldout-link" href="#">
                                    <h1 class="foldout-title">{{ location.locationName }}</h1>
                                </a>

                                <b-collapse :id="'location-account-access--' + locationKey">
                                    <div v-for="(access, accessKey) in location.access" :key="accessKey">
                                        <div class="row" v-if="access.accessToAccount">
                                            <div class="col-md-6 my-3">
                                                <label class="checkbox-container mr-3">
                                                    <input type="checkbox" :id="access.ID" class="form-control"
                                                           :checked="typeof accessIds[access.ID] !== 'undefined'"
                                                           @click="toggle(access.ID)"
                                                    >
                                                    <span class="checkmark"></span>

                                                    {{ access.accessToAccount.RTN_ACT_NAME }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </template>

                        <template v-else>
                            This role does not have access to any locations. Access to at least 1 location is required in order to set up the Location to Account access.
                        </template>

                        <button type="button" name="button" v-if="!isSaving && Object.keys(filteredLocationAccessToAccounts).length > 0" v-on:click="save()"
                                class="btn md btn-primary mt-3">
                            save
                        </button>
                    </template>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import Vue from 'vue';
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import RoleMenu from '@/components/_RoleMenu.vue';
    import _ from 'lodash';
    import {BCollapse, VBToggle} from 'bootstrap-vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'RoleMenu': RoleMenu,
            'b-collapse': BCollapse,
        },
        directives: {
            'b-toggle': VBToggle,
        },
        data() {
            return {
                accessIds: {},
                isSaving: false,
                errors: {},
                initLoaded: false,
            };
        },
        created() {
            let rolesPromise = this.$store.dispatch('roles/getAll');
            let accountPromise = this.$store.dispatch('locationAccessToAccounts/getAll');
            let locationAccountAccessPromise = this.$store.dispatch('roleAccess/getByType', {
                roleId: this.$route.query.roleId,
                type: 'rolelocationaccountaccess'
            });
            let locationAccessPromise = this.$store.dispatch('roleAccess/getByType', {
                roleId: this.$route.query.roleId,
                type: 'rolelocationaccess'
            });

            Promise.all([rolesPromise, accountPromise, locationAccountAccessPromise, locationAccessPromise]).then(() => {
                this.initLoaded = true;
                this.accessIds = this.$store.state.roleAccess.rolelocationaccountaccess[this.$route.query.roleId];
            });
        },
        methods: {
            toggle(id) {
                if (typeof this.accessIds[id] !== 'undefined')
                    Vue.delete(this.accessIds, id);
                else
                    this.accessIds[id] = {ID: id};
            },
            save() {
                let params = new URLSearchParams();
                let url = new URL(this.$store.state.baseUrl + 'rolelocationaccountaccess/savebyids');

                let ids = Object.keys(this.accessIds);
                ids = ids.join(',');

                params.append('rolelocationaccountaccess[accessIds]', ids);
                params.append('rolelocationaccountaccess[roleId]', this.$route.query.roleId);
                this.isSaving = true;


                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;
                    if (typeof data.response !== 'undefined' && data.response.status == 422) {
                        this.errors = {};
                        for (let obj in data.response.data) {
                            this.errors[data.response.data[obj].field] = data.response.data[obj].message;
                        }
                    }
                });
            }
        },
        computed: {
            roleLocationAccessIds() {
                let locationIds = [];

                _.forOwn(this.$store.state.roleAccess.rolelocationaccess[this.$route.query.roleId], (location, key) => {
                    locationIds.push(key);
                });

                return locationIds;
            },
            filteredLocationAccessToAccounts() {
                let result = {};

                _.forOwn(this.$store.state.locationAccessToAccounts.accessIds, (access) => {
                    /* Only return LocationAccessToAccounts of the locations to which the role has access. */
                    if (this.roleLocationAccessIds.includes(access.location.ID.toString())) {
                        if (result[access.location.ID]) {
                            result[access.location.ID].access.push(access);
                        } else {
                            result[access.location.ID] = {
                                locationId: access.location.ID,
                                locationName: access.location.NAME,
                                access: []
                            };

                            result[access.location.ID].access.push(access);
                        }
                    }
                });

                return result;
            }
        }
    }
</script>

<style scoped>
    .foldout-link {
        text-decoration: none;
    }

    .foldout-title {
        font-size: 22px;
    }
</style>
