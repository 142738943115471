<template>
    <div>
        <HeaderBar title="Asset"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <template v-if="isLoadingAsset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="scan-manisfest-container">
                                    Loading asset...
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="!asset">
                        <div class="scrollable-content no-footer">
                            <div class="container">
                                <div class="scan-manisfest-container">
                                    Unable to retrieve asset data.
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="scrollable-content">
                            <div class="container">
                                <h3 class="section-title-sm">
                                    Asset details
                                </h3>

                                <div class="table-responsive">
                                    <table class="table-first-level">
                                        <thead>
                                            <tr ref="header-titles">
                                                <th>SKU</th>
                                                <th>Description</th>
                                                <th>Origin country</th>
                                                <th>License plate</th>
                                                <th>Storage</th>
                                                <th>Container</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td colspan="6">
                                                    <table class="table-second-level">
                                                        <thead>
                                                            <tr ref="header-contents">
                                                                <th class="border-bottom-none text-break">
                                                                    <template v-if="asset.SKU">
                                                                        {{ asset.SKU }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="asset.DESCRIPTION">
                                                                        {{ asset.DESCRIPTION }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    <template v-if="asset.COUNTRY_OF_ORIGIN">
                                                                        {{ asset.COUNTRY_OF_ORIGIN }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>

                                                                <th class="border-bottom-none text-break">
                                                                    {{ asset.LICENSE_PLATE }}
                                                                </th>

                                                                <th class="border-bottom-none">
                                                                    {{ asset.STORAGE_TYPE }}
                                                                </th>

                                                                <th class="border-bottom-none text-break">
                                                                    <template v-if="asset.container">
                                                                        {{ asset.container.LICENSE_PLATE }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td colspan="6" class="p-0 border-radius-none border-top-td">
                                                                    <table class="w-100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="w-50 bgr-grey border-radius-none vertical-top">
                                                                                    <div class="row pt-4">
                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group mb-4 mb-lg-0">
                                                                                                <label class="label">
                                                                                                    Received grade
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.GRADE">
                                                                                                        {{ asset.GRADE }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group mb-0">
                                                                                                <label class="label">
                                                                                                    Disposition type
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.DISPOSITION_TYPE">
                                                                                                        {{ asset.DISPOSITION_TYPE | capitalize }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>

                                                                                <td class="w-50 border-radius-none vertical-top">
                                                                                    <div class="row pt-4">
                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group mb-4 mb-lg-0">
                                                                                                <label class="label">
                                                                                                    Revalued grade
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.REWORKED_GRADE">
                                                                                                        {{ asset.REWORKED_GRADE }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="d-flex justify-content-between">
                                                                                                <div class="form-group mb-0">
                                                                                                    <label class="label">
                                                                                                        Disposition resolution
                                                                                                    </label>

                                                                                                    <p>
                                                                                                        <template v-if="asset.REWORKED_GRADE && asset.DISPOSITION_RESOLUTION">
                                                                                                            {{ asset.DISPOSITION_RESOLUTION | capitalize }}
                                                                                                        </template>

                                                                                                        <template v-else>
                                                                                                            -
                                                                                                        </template>
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div class="btn--disposition" v-if="canDispositionAssets">
                                                                                                    <button type="button" class="btn btn-primary py-2" @click.prevent="assetRework()">
                                                                                                        <span class="icon-wrench"></span>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td class="w-50 bgr-grey border-radius-none vertical-top border-top-td pt-4">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-6">
                                                                                            <div class="form-group">
                                                                                                <label class="label">
                                                                                                    Account
                                                                                                </label>

                                                                                                <p>
                                                                                                    {{ asset.RETURNBIRD_ACCOUNT_NAME }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6">
                                                                                            <div class="form-group">
                                                                                                <label class="label">
                                                                                                    Channel
                                                                                                </label>

                                                                                                <p>
                                                                                                    {{ asset.PORTAL_NAME }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6">
                                                                                            <div class="form-group">
                                                                                                <label class="label">
                                                                                                    Return policy
                                                                                                </label>

                                                                                                <p>
                                                                                                    {{ asset.MERCHANT_SETUP_NAME }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6">
                                                                                            <div class="form-group">
                                                                                                <label class="label">
                                                                                                    Return reference
                                                                                                </label>

                                                                                                <p>
                                                                                                    {{ asset.RETURN_REFERENCE }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-12">
                                                                                            <div class="form-group">
                                                                                                <label class="label">
                                                                                                    Return reason
                                                                                                </label>

                                                                                                <p>
                                                                                                    <template v-if="asset.product && asset.product.RETURN_REASON">
                                                                                                        {{ asset.product.RETURN_REASON }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        -
                                                                                                    </template>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-12">
                                                                                            <CustomAttributesDisplay :customAttributes="productCustomAttributes"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>

                                                                                <td class="w-50 border-radius-none vertical-top border-top-td pt-4">
                                                                                    <template v-if="isLoadingCustomAttributes">
                                                                                        <div class="row">
                                                                                            <div class="col-12">
                                                                                                <p>
                                                                                                    Loading attributes...
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>

                                                                                    <template v-else>
                                                                                        <div class="row"></div>

                                                                                        <div class="row" v-if="customAttributes['receive'] && Object.keys(customAttributes['receive']).length > 0">
                                                                                            <div class="col-12">
                                                                                                <CustomAttributesDisplay :customAttributes="customAttributes['receive']"/>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row" v-if="(customAttributes['grade'] && Object.keys(customAttributes['grade']).length > 0) || (assetDispositionImages && assetDispositionImages['assetGrade'])">
                                                                                            <template v-if="customAttributes['grade'] && Object.keys(customAttributes['grade']).length > 0">
                                                                                                <div class="col-12 col-lg-6">
                                                                                                    <CustomAttributesDisplay :customAttributes="customAttributes['grade']"/>
                                                                                                </div>
                                                                                            </template>

                                                                                            <div class="col-12 col-lg-6">
                                                                                                <ImageGallery :images="assetDispositionImages['assetGrade']"
                                                                                                              idAttribute="ID"
                                                                                                              urlAttribute="URL"
                                                                                                              class="sm">
                                                                                                </ImageGallery>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row" v-if="(customAttributes['rework'] && Object.keys(customAttributes['rework']).length > 0) || (assetDispositionImages && assetDispositionImages['assetRework'])">
                                                                                            <template v-if="customAttributes['rework'] && Object.keys(customAttributes['rework']).length > 0">
                                                                                                <div class="col-12 col-lg-6">
                                                                                                    <CustomAttributesDisplay :customAttributes="customAttributes['rework']"/>
                                                                                                </div>
                                                                                            </template>

                                                                                            <div class="col-12 col-lg-6">
                                                                                                <ImageGallery :images="assetDispositionImages['assetRework']"
                                                                                                              idAttribute="ID"
                                                                                                              urlAttribute="URL"
                                                                                                              class="sm">
                                                                                                </ImageGallery>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row" v-if="(customAttributes['revalue'] && Object.keys(customAttributes['revalue']).length > 0) || (assetDispositionImages && assetDispositionImages['assetRevalue'])">
                                                                                            <template v-if="customAttributes['revalue'] && Object.keys(customAttributes['revalue']).length > 0">
                                                                                                <div class="col-12 col-lg-6">
                                                                                                    <CustomAttributesDisplay :customAttributes="customAttributes['revalue']"/>
                                                                                                </div>
                                                                                            </template>

                                                                                            <div class="col-12 col-lg-6">
                                                                                                <ImageGallery :images="assetDispositionImages['assetRevalue']"
                                                                                                              idAttribute="ID"
                                                                                                              urlAttribute="URL"
                                                                                                              class="sm">
                                                                                                </ImageGallery>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>

                                                                                    <div class="row">
                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group">
                                                                                                <label class="label">
                                                                                                    Vendor entity
                                                                                                </label>

                                                                                                <p>
                                                                                                    {{ asset.VENDOR_ENTITY_NAME ?? '-' }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-12 col-lg-6">
                                                                                            <div class="form-group">
                                                                                                <label class="label">
                                                                                                    Work order
                                                                                                </label>

                                                                                                <p>
                                                                                                    {{ asset.WORK_ORDER_REFERENCE ?? '-' }}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!-- scrollable-content -->
                    </template>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Back
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import ImageGallery from '@/components/_ImageGallery.vue';
import CustomAttributesDisplay from '@/components/_CustomAttributesDisplay.vue';

export default {
    name: 'AssetPage',
    components: {
        'HeaderBar': HeaderBar,
        'ImageGallery': ImageGallery,
        'CustomAttributesDisplay': CustomAttributesDisplay,
    },
    data() {
        return {
            errors: {},
            moduleAccess: {},
            canDispositionAssets: false,
            isLoadingAsset: false,
            isLoadingCustomAttributes: false,
            isLoadingAssetDispositionImages: false,
            selectedLocationId: null,
            containerId: this.$route.query.containerId,
            assetId: this.$route.query.id,
            asset: null,
            customAttributes: {},
            productCustomAttributes: null,
            assetDispositionImages: [],
        };
    },
    mounted() {
        this.$root.$on('loadedRoleModuleAccess', () => {
            this.moduleAccess = this.$store.state.roleAccess['role-module-access'][this.$store.state.users.currentUser.ROLE_ID];
            this.canDispositionAssets = typeof this.moduleAccess['asset-disposition'] !== 'undefined' && this.moduleAccess['asset-disposition'].ACCESS;
        });
    },
    created() {
        this.isLoadingAsset = true;

        this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
            if (data && data.value) {
                this.selectedLocationId = data.value;
            }

            this.getAsset();
            this.getCustomAttributes();
        });

        this.getAssetDispositionImages();
    },
    methods: {
        previous() {
            if (this.containerId) {
                this.$router.push({
                    name: 'ContainerAssetOverview',
                    query: {id: this.containerId}
                });
            } else {
                this.$router.push({
                    name: 'AssetSearch',
                });
            }
        },
        getAsset() {
            this.isLoadingAsset = true;

            this.$store.dispatch('assets/getAssetToView', this.assetId).then((data) => {
                this.isLoadingAsset = false;
                this.asset = data;

                if (this.asset) {
                    this.getProductCustomAttributes();
                }

                this.$store.commit('assets/clearAssetToView');

                this.$nextTick(() => {
                    this.fixHeaderWidths();
                });
            });
        },
        getCustomAttributes() {
            this.isLoadingCustomAttributes = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-custom-attribute-display-values');
            url.searchParams.append('assetId', this.assetId);

            this.$http.get(url.href).then((data) => {
                this.isLoadingCustomAttributes = false;

                if (data.data) {
                    for (const [displayOption, customAttributes] of Object.entries(data.data)) {
                        for (const [key, customAttribute] of Object.entries(customAttributes)) {
                            if (customAttribute.value) {
                                if (!this.customAttributes[displayOption]) {
                                    this.customAttributes[displayOption] = {};
                                }

                                this.customAttributes[displayOption][key] = customAttribute;
                            }
                        }
                    }
                }
            });
        },
        getProductCustomAttributes() {
            const url = new URL(this.$store.state.baseUrl + 'products/get-returnbird-custom-attributes');
            url.searchParams.append('productId', this.asset.PRODUCT_ID);

            this.$http.get(url.href).then((data) => {
                if (data.data) {
                    this.productCustomAttributes = {};

                    for (const [key, customAttribute] of Object.entries(data.data)) {
                        if (customAttribute.value) {
                            this.productCustomAttributes[key] = customAttribute;
                        }
                    }
                }
            });
        },
        getAssetDispositionImages() {
            this.isLoadingAssetDispositionImages = true;

            const url = new URL(this.$store.state.baseUrl + 'assets/get-asset-disposition-images');
            url.searchParams.set('assetId', this.assetId);
            url.searchParams.set('category', ['assetGrade', 'assetRework', 'assetRevalue']);

            this.$http.get(url.href).then((data) => {
                this.isLoadingAssetDispositionImages = false;

                if (data.data.code === 100) {
                    this.assetDispositionImages = data.data.data;
                } else if (data.data.code === 422) {
                    this.errors = {assetDispositionImages: data.data.errors};
                }
            });
        },
        assetRework() {
            this.$router.push({
                name: 'AssetRework',
                query: {
                    id: this.assetId,
                    containerId: this.containerId,
                    origin: 'assetDetail'
                }
            });
        },
        fixHeaderWidths() {
            if (!this.$refs['header-contents'] || !this.$refs['header-titles']) {
                return;
            }

            const headerContents = this.$refs['header-contents'].children;
            const headerTitles = this.$refs['header-titles'].children;
            let index = 0;

            for (const content of headerContents) {
                let width = content.clientWidth + 'px';

                content.style.width = width;
                headerTitles[index].style.width = width;

                index++;
            }
        },
    },
    filters: {
        capitalize: function (value) {
            if (!value) {
                return '';
            }

            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        datepickerFormatter: function (date) {
            let d = new Date(date);
            return d.getUTCFullYear() + '-' + ("0" + (d.getUTCMonth() + 1)).slice(-2) + '-' + ("0" + d.getUTCDate()).slice(-2);
        },
    },
}
</script>

<style scoped lang="scss">
    h5 {
        margin-bottom: 40px;
        font-weight: 600;
    }

    .table-second-level tbody tr td {
        padding-top: 0;
    }

    .table-second-level thead tr th {
        width: auto;
        min-width: 110px;
    }

    .btn--disposition {
        right: 0;
    }

    .scrollable-content {
        padding: 30px 0;
    }
</style>
