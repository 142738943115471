<template>
    <div>
        <HeaderBar title="Container overview"></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="scanContainer()">
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="form-group">
                                <input type="text" placeholder="Scan Container" class="form-control lg"
                                       ref="scanContainerInput" v-model="containerLicensePlate">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="containerLicensePlate" :errors="errors"/>
                                </div>

                                <div class="col-12" v-if="isSearchingContainer">
                                    Loading container {{ containerLicensePlate }}...
                                </div>
                            </div>

                            <div class="scan-manisfest-container">
                                <template v-if="isLoadingContainers">
                                    Loading containers...
                                </template>

                                <template v-else-if="containers[selectedLocationId].length === 0">
                                    No containers available.
                                </template>

                                <template v-else>
                                    <div class="row">
                                        <div class="col-md-4 pb-3">
                                            <div class="form-group pl-3">
                                                <label for="country" class="label">Filter</label>

                                                <select id="country" class="form-control md" v-model="selectedAssetCountFilter" @change="applyContainerFilters()">
                                                    <option value="full-to-empty">Full to empty</option>
                                                    <option value="empty-to-full">Empty to full</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6 col-lg-3" v-for="container in containers[selectedLocationId]" :key="container.ID">
                                            <div class="scan-manisfest-card">
                                                <router-link class="text-decoration-none" :to="{name: 'Container', query: {id: container.ID}}">
                                                    <div class="scan-manisfest-card-header" :class="container.IS_FULL == 1 ? 'red' : container.assets.length > 0 ? 'yellow' : 'green'">
                                                        <h4>
                                                            {{ container.LICENSE_PLATE }}
                                                        </h4>

                                                        <span class="badge">
                                                            {{ container.assets.length }}
                                                        </span>
                                                    </div>

                                                    <div class="scan-manisfest-card-body" ref="cardBody" :style="{'height': cardBodyHeight}">
                                                        <div class="py-2 border-bottom">
                                                            <span class="label">Account</span>

                                                            <span class="content">
                                                                <template v-if="container.RETURNBIRD_ACCOUNT_NAME">
                                                                    {{ container.RETURNBIRD_ACCOUNT_NAME | capitalize }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </span>
                                                        </div>

                                                        <div class="py-2 border-bottom">
                                                            <template v-if="storage === 'AFS'">
                                                                <span class="label">Disposition Resolution</span>

                                                                <span class="content">
                                                                    <template v-if="container.DISPOSITION_RESOLUTION">
                                                                        {{ container.DISPOSITION_RESOLUTION | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </span>
                                                            </template>

                                                            <template v-else>
                                                                <span class="label">Disposition Type</span>

                                                                <span class="content">
                                                                    <template v-if="container.DISPOSITION_TYPE">
                                                                        {{ container.DISPOSITION_TYPE | capitalize }}
                                                                    </template>

                                                                    <template v-else>
                                                                        -
                                                                    </template>
                                                                </span>
                                                            </template>
                                                        </div>

                                                        <div class="py-2 border-bottom">
                                                            <span class="label">Location</span>

                                                            <span class="content">
                                                                <template v-if="container.currentLocation.NAME">
                                                                    {{ container.currentLocation.NAME }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </span>
                                                        </div>

                                                        <div class="py-2 border-bottom">
                                                            <span class="label">Bin</span>

                                                            <span class="content">
                                                                <template v-if="container.BIN_LOCATION">
                                                                    {{ container.BIN_LOCATION }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </span>
                                                        </div>

                                                        <div class="py-2 border-bottom" v-if="container.WORK_ORDER_REFERENCE">
                                                            <span class="label">Work order</span>

                                                            <span class="content">
                                                                {{ container.WORK_ORDER_REFERENCE }}
                                                            </span>
                                                        </div>

                                                        <div class="py-2 border-bottom" v-if="container.SHIPMENT_REFERENCE">
                                                            <span class="label">Shipment</span>

                                                            <span class="content">
                                                                {{ container.SHIPMENT_REFERENCE }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="showCreateContainerModal = true">
                                    Create container
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>

        <CreateContainerPopup v-if="showCreateContainerModal"
                              @close="showCreateContainerModal = false"
                              @createdContainer="createdContainerEvent"
        />
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import CreateContainerPopup from '@/components/_CreateContainerPopup.vue';
import _ from 'lodash';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'CreateContainerPopup': CreateContainerPopup,
    },
    data() {
        return {
            errors: {},
            storage: this.$route.params.storage,
            isLoadingContainers: false,
            isSearchingContainer: false,
            selectedLocationId: null,
            containerLicensePlate: null,
            containers: {},
            selectedAssetCountFilter: 'full-to-empty',
            cardBodyHeight: null,
            showCreateContainerModal: false,
        };
    },
    mounted() {
        /* Focus on the "Scan container" input field on page load */
        this.$refs.scanContainerInput.focus();
    },
    created() {
        this.isLoadingContainers = true;

        this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
            if (data && data.value) {
                this.selectedLocationId = data.value;
            }

            this.getContainers();
        });
    },
    methods: {
        getContainers() {
            this.isLoadingContainers = true;

            let url = new URL(this.$store.state.baseUrl + 'containers/get-all');
            url.searchParams.append('storageType', this.storage);

            if (this.storage === 'AFD') {
                url.searchParams.append('storageTypeIncludeNull', true);
            }

            this.$http.get(url.href).then((data) => {
                this.isLoadingContainers = false;
                this.containers[this.selectedLocationId] = data.data;

                this.applyContainerFilters();

                this.$nextTick(() => {
                    if (this.$refs.cardBody) {
                        let scrollHeight = 0;

                        for (let i = 0; i < this.$refs.cardBody.length; i++) {
                            if (this.$refs.cardBody[i].scrollHeight > scrollHeight) {
                                scrollHeight = this.$refs.cardBody[i].scrollHeight;
                            }
                        }

                        this.cardBodyHeight = scrollHeight + 'px';
                    }
                });
            });
        },
        scanContainer() {
            this.errors = {};
            this.isSearchingContainer = true;

            let url = new URL(this.$store.state.baseUrl + 'containers/get-by-license-plate');
            url.searchParams.append('locationId', this.selectedLocationId);
            url.searchParams.append('licensePlate', this.containerLicensePlate);

            this.$http.get(url.href).then((data) => {
                this.isSearchingContainer = false;

                if (_.isEmpty(data.data) || !data.data.ID) {
                    this.errors = {containerLicensePlate: 'No container found with license plate ' + this.containerLicensePlate};
                } else if (data.data.STORAGE_TYPE !== this.storage) {
                    this.errors = {containerLicensePlate: 'Container "' + data.data.LICENSE_PLATE + '" is in the ' + data.data.STORAGE_TYPE + ' storage.'};
                } else {
                    this.$router.push({
                        name: 'Container',
                        query: {id: data.data.ID}
                    });
                }
            });
        },
        applyContainerFilters() {
            let order = this.selectedAssetCountFilter === 'full-to-empty' ? 'desc' : 'asc';

            this.containers[this.selectedLocationId] = _.orderBy(this.containers[this.selectedLocationId], [function (c) {
                return c.IS_FULL == 1;
            }, function (c) {
                return c.assets.length;
            }], [order, order]);
        },
        previous() {
            if (this.storage === 'AFD') {
                this.$router.push({
                    name: 'StorageAFD',
                });
            } else if (this.storage === 'AFS') {
                this.$router.push({
                    name: 'StorageAFS',
                });
            } else {
                this.$router.push({
                    name: 'Storage',
                });
            }
        },
        createdContainerEvent(container) {
            this.containers[this.selectedLocationId].push(container);
            this.showCreateContainerModal = false;
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) {
                return '';
            }

            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },
}
</script>

<style scoped>
    .scan-manisfest-card {
        cursor: pointer;
        user-select: none;
    }

    .scan-manisfest-card-body .content {
        color: #1d2640;
    }
</style>
