<template>
    <div>
        <HeaderBar title="Location overview"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="Location" activeItem="Overview"></SettingsMenu>

                <template v-if="!initLoaded">
                    Loading locations...
                </template>

                <template v-else>
                    <div class="col-10">
                        <div class="row col-12 mb-2">
                            <div class="col-md-2 font-weight-bold">
                                Name
                            </div>

                            <div class="col-md-2 font-weight-bold">
                                Address line 1
                            </div>

                            <div class="col-md-2 font-weight-bold">
                                Postal code
                            </div>

                            <div class="col-md-2 font-weight-bold">
                                City
                            </div>

                            <div class="col-md-2 font-weight-bold">
                                Country
                            </div>

                            <div class="col-md-1 font-weight-bold">
                                Tier
                            </div>

                            <div class="col-md-1 font-weight-bold">
                                View
                            </div>
                        </div>

                        <div class="col-12 row mb-2" v-for="(location, key) in $store.state.locations.locations" v-bind:key="key" v-bind:id="location.ID">
                            <template v-if="locationsBeingDeleted.indexOf(location.ID) != -1">
                                deleting location...
                            </template>

                            <template v-else>
                                <div class="col-md-2">
                                    {{ location.NAME }}
                                </div>

                                <div class="col-md-2">
                                    <template v-if="location.locationAddress">
                                        {{ location.locationAddress.ADDRESS_LINE_1 }}
                                    </template>

                                    <template v-else>
                                        -
                                    </template>
                                </div>

                                <div class="col-md-2">
                                    <template v-if="location.locationAddress">
                                        {{ location.locationAddress.POSTAL }}
                                    </template>

                                    <template v-else>
                                        -
                                    </template>
                                </div>

                                <div class="col-md-2">
                                    <template v-if="location.locationAddress">
                                        {{ location.locationAddress.CITY }}
                                    </template>

                                    <template v-else>
                                        -
                                    </template>
                                </div>

                                <div class="col-md-2">
                                    <template v-if="location.locationAddress && countryList">
                                        {{ countryList[location.locationAddress.COUNTRY] }}
                                    </template>

                                    <template v-else>
                                        -
                                    </template>
                                </div>

                                <div class="col-md-1">
                                    <template v-if="location.TIER">
                                        <span v-for="(tier, key) in location.TIER" :key="key">
                                            {{ tier }}<template v-if="key != (location.TIER.length - 1)">,</template>
                                        </span>
                                    </template>

                                    <template v-else>
                                        -
                                    </template>
                                </div>

                                <div class="col-md-1 text-primary pointer" v-on:click="viewLocation(location.ID)">
                                    View
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'SettingsMenu': SettingsMenu
        },
        data() {
            return {
                initLoaded: false,
                locationsBeingDeleted: [],
                countryList: null,
            };
        },
        created() {
            let locationsPromise = this.$store.dispatch('locations/getAll');
            let countryListPromise = this.$store.dispatch('masterData/getCountryList');

            Promise.all([locationsPromise, countryListPromise]).then((data) => {
                this.initLoaded = true;
                this.countryList = data[1];
            });

        },
        methods: {
            viewLocation(id) {
                this.$router.push({
                    name: 'LocationUpdate',
                    query: {locationId: id}
                });
            },
        }
    }
</script>

<style>

</style>
