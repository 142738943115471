<template>
    <div>
        <HeaderBar></HeaderBar>

        <main class="main">
            <form action="" @submit.prevent="registerProduct()">
                <div class="scrollable-area">
                    <div class="scrollable-area--header">
                        <div class="my-5" v-if="!manifestId"></div>

                        <div class="container" v-else>
                            <div class="col-12">
                                <div class="scrollable-area--subtitle">
                                    You're working on manifest
                                </div>

                                <div class="scrollable-area--title">
                                    {{ manifestCode }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="scrollable-content">
                        <div class="container">
                            <h3 class="section-title-sm mb-4">
                                Receive and check products for return {{ returnReference }}
                            </h3>

                            <template v-if="isSaving">
                                <div class="container mt-5">
                                    Completing return...
                                </div>
                            </template>

                            <template v-else>
                                <div class="vertical-sets">
                                    <div class="vertical-sets-left">
                                        <div class="form-group">
                                            <div class="label mb-3">
                                                Products received and checked
                                            </div>

                                            <template v-if="isLoadingProducts">
                                                Loading products...
                                            </template>

                                            <template v-else-if="productsOnReturn.length > 0">
                                                <div class="row" v-for="(product, key) in productsOnReturn" :key="key">
                                                    <template v-if="productsBeingDeleted && (productsBeingDeleted[product.ID] || productsBeingDeleted.indexOf(product.ID) != -1)">
                                                        <div class="col-12 mb-3">
                                                            Deleting product...
                                                        </div>
                                                    </template>

                                                    <template v-else>
                                                        <div class="col-10">
                                                            {{ product.MODEL_NUMBER }}
                                                        </div>

                                                        <div class="col-2">
                                                            <span class="action-btn mb-3" @click.prevent="confirmDeleteProduct(product)">
                                                                <i class="icon-bin"></i>
                                                                <span>Delete</span>
                                                            </span>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>

                                            <template v-else>
                                                There are currently no products on this return.
                                            </template>
                                        </div>
                                    </div>

                                    <div class="vertical-sets-right">
                                        <div class="form-group">
                                            <label class="label mb-3">
                                                Scan product to receive and check
                                            </label>

                                            <input type="text" class="form-control" v-model="productNumber" placeholder="Scan product number" :disabled="isSavingProduct" ref="productNumberInput">

                                            <div class="mt-3" v-if="isSavingProduct">
                                                Receiving product {{ productNumber }}...
                                            </div>

                                            <InputFieldError attribute="global" :errors="errors"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div><!-- container -->
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between">
                                <button type="button" class="btn btn-secondary" @click.prevent="previous()">
                                    Exit return
                                </button>

                                <button type="button" class="btn btn-primary" @click.prevent="showReceiveReturnConfirmationPopup = true" :disabled="isSaving">
                                    Complete
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->

                </div><!-- scrollable-area -->
            </form>

            <ConfirmationPopup v-if="showDeleteProductPopup"
                               :message="'Are you sure you want to delete product ' + productToDelete.MODEL_NUMBER + '?'"
                               @confirm="deleteProduct()"
                               @deny="showDeleteProductPopup = false"></ConfirmationPopup>

            <ConfirmationPopup v-if="showReceiveReturnConfirmationPopup"
                               :message="'Are you sure you want to finish receiving return ' + returnReference + '? This action cannot be reversed.'"
                               @confirm="next()"
                               @deny="closeReceiveReturnConfirmationPopup()"></ConfirmationPopup>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import _ from 'lodash';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                returnPk: this.$route.query.returnPk,
                returnReference: this.$route.query.returnReference,
                manifestId: this.$route.query.manifestId,
                manifestCode: this.$route.query.manifestCode,
                manifestIsReadyToShip: this.$route.query.readyToShip,
                errors: {},
                isLoadingProducts: false,
                isSaving: false,
                isSavingProduct: false,
                productNumber: null,
                productsOnReturn: [],
                showDeleteProductPopup: false,
                showReceiveReturnConfirmationPopup: false,
                productToDelete: null,
                productsBeingDeleted: [],
            };
        },
        created() {
            this.isLoadingProducts = true;

            let url = new URL(this.$store.state.baseUrl + 'products/get-products-on-unspecified-return');
            url.searchParams.append('returnPk', this.returnPk);

            this.$http.get(url.href).then((data) => {
                this.isLoadingProducts = false;

                this.productsOnReturn = data.data.data;
            });

            this.$nextTick(() => {
                this.$refs.productNumberInput.focus();
            });
        },
        methods: {
            previous() {
                if (this.manifestId) {
                    this.$router.push({
                        name: 'ReceiveToManifest',
                        query: {manifestId: this.manifestId}
                    });
                } else {
                    this.$router.push({
                        name: 'SortToManifest'
                    });
                }
            },
            next() {
                /* Sorted Manifest - redirect to the sort to manifest page. */
                if (!this.manifestId) {
                    this.$router.push({
                        name: 'SortToManifest',
                        query: {returnReference: this.returnReference, preload: true}
                    });

                    return true;
                }

                /* Unsorted Manifest - add the return to the manifest. */
                this.errors = {};
                this.isSaving = true;
                this.showReceiveReturnConfirmationPopup = false;

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/add-unspecified-return-to-manifest');
                let params = new URLSearchParams();
                params.append('ReturnManifest[RETURN_PK]', this.returnPk);
                params.append('ReturnManifest[MANIFEST_ID]', this.manifestId);
                params.append('ReturnManifest[MANIFEST_IS_READY_TO_SHIP]', this.manifestIsReadyToShip);

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    if (data.data.code === 422) {
                        this.errors = {global: data.data.errors};
                    } else {
                        this.$store.commit('manifests/addReturnToManifest', [this.manifestCode, this.returnReference]);

                        if (this.manifestIsReadyToShip && (this.manifestIsReadyToShip === true || this.manifestIsReadyToShip === 'true')) {
                            this.$store.commit('manifests/setManifestReadyToShip', this.manifestCode);

                            this.$router.push({
                                name: 'ReceiveToManifest'
                            });
                        } else {
                            this.$router.push({
                                name: 'ReceiveToManifest',
                                query: {manifestId: this.manifestId}
                            });
                        }
                    }
                });
            },
            registerProduct() {
                this.errors = {};
                this.isSavingProduct = true;

                let url = new URL(this.$store.state.baseUrl + 'products/register-product');
                let params = new URLSearchParams();
                params.append('Product[RETURN_PK]', this.returnPk);
                params.append('Product[PRODUCT_NUMBER]', this.productNumber);

                this.$http.post(url.href, params).then((data) => {
                    this.isSavingProduct = false;

                    if (data.data.code === 422) {
                        this.errors = {global: data.data.errors};
                    } else {
                        this.productsOnReturn.push(data.data.data);
                    }

                    this.productNumber = null;

                    this.$nextTick(() => {
                        this.$refs.productNumberInput.focus();
                    });
                });
            },
            confirmDeleteProduct(productToDelete) {
                this.productToDelete = productToDelete;
                this.showDeleteProductPopup = true;
            },
            deleteProduct() {
                this.showDeleteProductPopup = false;

                let url = new URL(this.$store.state.baseUrl + 'products/delete-product');
                let params = new URLSearchParams();
                params.append('Product[ID]', this.productToDelete.ID);

                this.productsBeingDeleted.push(this.productToDelete.ID.toString());

                this.$http.post(url.href, params).then((data) => {
                    if (data.data.code === 100) {
                        const key = _.findIndex(this.productsOnReturn, (product) => {
                            return product.ID === data.data.productId;
                        });

                        if (key !== -1) {
                            this.$delete(this.productsOnReturn, key);
                        }
                    }

                    let index = this.productsBeingDeleted.indexOf(this.productToDelete.ID);
                    this.productsBeingDeleted.splice(index, 1);
                    this.productToDelete = null;
                });

                this.$nextTick(() => {
                    this.$refs.productNumberInput.focus();
                });
            },
            closeReceiveReturnConfirmationPopup() {
                this.showReceiveReturnConfirmationPopup = false;

                this.$nextTick(() => {
                    this.$refs.productNumberInput.focus();
                });
            },
        },
    }
</script>

<style scoped>
    .section-title-sm {
        padding-left: 14px;
    }

    .scrollable-area--title {
        font-size: 18px;
        font-weight: 600;
    }

    .scrollable-area--subtitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(29, 38, 64, .44);
    }

    .scrollable-content {
        padding-top: 50px;
        height: calc(100vh - 230px);
    }

    .scrollable-area--header {
        padding-top: 50px;
    }
</style>
