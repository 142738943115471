import axios from '@/classes/Axios';

const userSettingsStore = {
    namespaced: true,
    state: {
        selectedLocation: null,
        operatorLocation: null,
    },
    mutations: {
        setSelectedLocation(state, location) {
            state.selectedLocation = location;
        },
        setOperatorLocation(state, location) {
            state.operatorLocation = location;
        },
        clear(state) {
            state.selectedLocation = null;
            state.operatorLocation = null;
        }
    },
    actions: {
        getSelectedLocation(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.selectedLocation) {
                    resolve(storeArgObj.state.selectedLocation);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'user-settings/get-selected-location');

                    axios.get(url.href).then((data) => {
                        let selectedLocation = null;

                        if (data.data.code === 100) {
                            selectedLocation = data.data.data.selectedLocation;
                        }

                        storeArgObj.commit('setSelectedLocation', selectedLocation);
                        resolve(selectedLocation);
                    });
                }
            });
        },
        getOperatorLocation(storeArgObj) {
            return new Promise((resolve) => {
                if (storeArgObj.state.operatorLocation) {
                    resolve(storeArgObj.state.operatorLocation);
                } else {
                    let url = new URL(storeArgObj.rootState.baseUrl + 'user-settings/get-operator-location');

                    axios.get(url.href).then((data) => {
                        let operatorLocation = null;

                        if (data.data.code === 100) {
                            operatorLocation = data.data.data;
                        }

                        storeArgObj.commit('setOperatorLocation', operatorLocation);
                        resolve(operatorLocation);
                    });
                }
            });
        }
    }
};

export default userSettingsStore;
