<template>
    <div class="login-wrapper">
        <div class="login-container">
            <div class="return-top-title">
                <span class="return-number">1</span>
                <span class="return-number">2</span>
                <span class="d-inline-block pl-2">Return</span>
            </div>

            <template v-if="status === 'unsent'">
                <div class="login-sub-title">
                    Forgot password
                </div>

                <form action="#">
                    <div class="form-group">
                        <label for="email" class="label">
                            Email address
                        </label>

                        <input id="email" type="email" v-model="user.email" class="form-control" placeholder="Enter your email">
                    </div>

                    <div class="form-group text-center">
                        <button type="submit" v-on:click.prevent="forgotPassword()" name="button" class="btn btn-primary btn-lg">
                            Forgot password
                        </button>
                    </div>
                </form>

            </template>

            <div v-else-if="status === 'sending'">
                Sending password reset email...
            </div>

            <div v-else-if="status === 'error'">
                <InputFieldError attribute="global" :errors="errors"/>
            </div>

            <div v-else>
                Password reset email has been sent to: {{ user.email }}.
            </div>

            <div class="form-group text-center">
                <router-link :to="{name: 'Login'}" class="forgot-passw">
                    <button type="submit" name="button" class="btn btn-secondary btn-lg">Back to login</button>
                </router-link>
            </div>
        </div><!-- login-container -->
    </div><!-- login-wrapper -->

</template>

<script>
    import InputFieldError from '@/components/_InputFieldError.vue';

    export default {
        components: {
            'InputFieldError': InputFieldError,
        },
        data() {
            return {
                errors: {global: ''},
                user: {
                    email: '',
                },
                status: 'unsent',
            }
        },
        created() {

        },
        methods: {
            forgotPassword() {
                this.status = 'sending';

                let url = new URL(this.$store.state.baseUrl + 'users/forgotpassword');
                let params = new URLSearchParams();
                params.append('user[EMAIL]', this.user.email);

                this.$http.post(url.href, params).then((data) => {
                    if(data.data.code === 422) {
                        this.status = 'error';
                        this.errors = {global: data.data.errors};
                    } else {
                        this.status = 'sent';
                    }
                });
            }
        }
    }
</script>
