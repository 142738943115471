<template>
    <div>
        <table class="table-third-level" v-if="workOrder.assets && Object.keys(workOrder.assets).length > 0">
            <thead>
                <tr>
                    <th class="w-auto">SKU</th>
                    <th class="w-auto">License plate</th>
                    <th class="w-auto">Storage type</th>
                    <th class="w-auto">Grade</th>
                    <th class="w-auto">Resolution</th>
                    <th class="w-auto">Revalued grade</th>
                    <th class="w-auto">Status</th>
                    <th class="w-auto">Actions</th>
                </tr>
            </thead>

            <tbody>
                <WorkOrderAssetListRow
                        v-for="(asset, key) in workOrder.assets"
                        :key="key"
                        :work-order="workOrder"
                        :row-item="asset"
                        :is-loading-vendor-resolution-options="isLoadingVendorResolutionOptions"
                        :vendor-resolution-options="vendorResolutionOptions"
                />
            </tbody>
        </table>

        <p v-else>
            No assets added to this work order.
        </p>
    </div>
</template>

<script>
    import WorkOrderAssetListRow from '@/components/WorkOrder/_WorkOrderAssetListRow.vue';

    export default {
        props: [
            'workOrder'
        ],
        components: {
            'WorkOrderAssetListRow': WorkOrderAssetListRow
        },
        data() {
            return {
                isLoadingVendorResolutionOptions: false,
                vendorResolutionOptions: [],
            }
        },
        created() {
            this.getVendorResolutions();
        },
        methods: {
            getVendorResolutions() {
                this.isLoadingVendorResolutionOptions = true;

                this.$store.dispatch('assets/getVendorResolutions').then((data) => {
                    this.isLoadingVendorResolutionOptions = false;
                    this.vendorResolutionOptions = data;
                });
            },
        },
    }
</script>


<style>

</style>
