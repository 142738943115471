<template>
    <div>
        <HeaderBar title="User overview"></HeaderBar>

        <main class="main">
            <div class="row col-12">
                <SettingsMenu activeCategory="Connection" activeItem="Overview"></SettingsMenu>

                <div v-if="isLoadingConnections">
                    Loading connections...
                </div>

                <div v-else-if="!connectionRequests || !connectionRequests.length">
                    There are no connections.
                </div>

                <template v-else>
                    <div class="col-10">
                        <div class="table-responsive mt-4">
                            <table class="w-75">
                                <tr>
                                    <th class="pb-3">Return Bird account name</th>
                                    <th class="pb-3">Connection status</th>
                                    <th class="pb-3"></th>
                                </tr>

                                <tr v-for="(connectionRequest, key) in connectionRequests" :key="key" :id="connectionRequest.ID">
                                    <td class="pb-2">
                                        {{ connectionRequest.RETURNBIRD_ACCOUNT_NAME}}
                                    </td>

                                    <td class="pb-2 status" :class="connectionRequest.STATUS">
                                        {{ connectionRequest.STATUS | capitalize }}
                                    </td>

                                    <td class="pb-2">
                                        <button class="btn btn-primary" @click="viewConnectionDetails(connectionRequest.ID)">
                                            View
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </template>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import SettingsMenu from '@/components/_SettingsMainMenu.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'SettingsMenu': SettingsMenu
        },
        data() {
            return {
                isLoadingConnections: false,
                connectionRequests: null,
            };
        },
        created() {
            this.getConnections();
        },
        methods: {
            getConnections() {
                this.isLoadingConnections = true;
                let url = new URL(this.$store.state.baseUrl + 'connection-requests/get-all');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingConnections = false;

                    if (data.status === 200) {
                        this.connectionRequests = data.data;
                    }
                });
            },
            viewConnectionDetails(id) {
                this.$router.push({
                    name: 'Connection',
                    query: {id: id}
                });
            },
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
    }
</script>

<style>
    .status.approved {
        color: #63e6b8;
    }

    .status.rejected {
        color: #e24b4a;
    }
</style>
